import { Component, Input } from '@angular/core';
import { ScrollToService } from '../../shared/services/scroll-to';
import { ButtonTraceService } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-pos-call-to-action',
    templateUrl: './pos-call-to-action.component.html',
    styleUrls: ['./pos-call-to-action.component.scss']
})
export class PosCallToActionComponent {
    @Input() public loadingImages: boolean;


    constructor(
        public buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
        ) {
    }

    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
    }
}
