import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-reseller-training',
    templateUrl: './reseller-training.component.html',
    styleUrls: ['./reseller-training.component.scss']
})
export class ResellerTrainingComponent {
    @Input() public loadingImages: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        public buttonTraceService: ButtonTraceService
    ) { }

    public goToUrl(url: string): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }
}
