import { Component } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';

@Component({
    selector: 'app-credit-first-screen',
    templateUrl: './credit-first-screen.component.html',
    styleUrls: ['./credit-first-screen.component.scss']
})
export class CreditFirstScreenComponent {


    constructor(
        private buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) {
    }

    public navigateToSection(className: string, event: Event): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 200);
        this.buttonTraceService.traceButton(event, 'scroll');
    }

}
