import { Component } from '@angular/core';
@Component({
    selector: 'app-feature-list',
    templateUrl: './feature-list.component.html',
    styleUrls: ['./feature-list.component.scss']
})
export class FeatureListComponent {

    public featureList = [
        {
            icon: "icon-sprite-battery",
            color: '',
            sprite_color: 'color-accent-primary',
            title: "Batería de larga duración",
            desc: "Batería de larga duración."
        },
        {
            icon: "icon-sprite-sim-card",
            color: '',
            sprite_color: 'color-accent-primary',
            title: "Conexión Wifi e internet.",
            desc:"Chip de internet 4G con gigas libre."
        },
        {
            icon: 'icon-sprite-shield',
            color: '',
            sprite_color: 'color-accent-primary',
            title: 'Certificación PCI DSS',
            desc: 'Protegemos tus datos y los de tus clientes.'
        },
        {
            icon: 'icon-sprite-credit-card2',
            color: '',
            sprite_color: 'color-accent-primary',
            title: 'Tecnología NFC',
            desc: 'Acepta tarjetas, con banda magnética, chip y sin contacto.'
        },
        {
            icon: 'icon-sprite-bookmark',
            color: '',
            sprite_color: 'color-accent-primary',
            title: 'Garantía y retracto.',
            desc: '30 días de devolución y retracto, 2 años de garantía.'
        }
    ];
}
