import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MetaService } from '@ngx-meta/core';
import { urlEnv } from '../../environments/environment';
import { DialogPlansComponent } from '../shared/dialogs/dialog-plans/dialog-plans.component';
import { DialogSeeProduct2Component } from '../shared/dialogs/dialog-see-product2/dialog-see-product2.component';
import { DialogSinatureComponent } from '../shared/dialogs/dialog-sinature/dialog-sinature.component';
import { isPlatformBrowser } from '@angular/common';
import { Card, IDeviceFeature, SelectOption, Tariff, TariffOption, dataOptions, dataType, templateOPtionsGeneral, templateOPtionsSemi, OPtionsDesk, IDiscounts } from './data-installment-payment';
import { CurrencyLocalePipe } from '../currency.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from '../shared/services/url.service';
import { ButtonTraceService } from '../../../../common/services/button-trace';
import { urlMapping } from '../shared/route-ids';
import { DialogWarnDesk } from '../shared/dialogs/dialog-warn-desk/dialog-warn-desk.component';
import { DialogMachineCard } from '../shared/dialogs/dialog-machine-card/dialog-machine-card.component';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime, throttleTime, tap } from 'rxjs/operators';

interface IProducts {
    [key: string]: {
        quantity: number;
        price?: string;
    }
}
@Component({
    selector: 'app-installment-payment',
    templateUrl: './installment-payment.component.html',
    styleUrls: ['./installment-payment.component.scss'],
})
export class InstallmentPaymentComponent implements OnInit, AfterViewInit {
    @ViewChild('previewContainer') previewContainer: ElementRef;
    private transformStyleSubject = new BehaviorSubject<string>("");
    private startX: number = 0;
    private startY: number = 0;
    private urlEnv = urlEnv;
    private promoCode: string = '';
    private pickChip: boolean = false;
    private pickPrice: boolean = false;
    private products: IProducts = {};
    public transformStyle$ = this.transformStyleSubject.asObservable();
    public currentStep: number = 0;
    public maxTerminals: number = 12;
    public terminalCounter: number = 0;
    public showFire: boolean = false;
    public showPre: boolean = false;
    public textPre: string = '';
    public fireText: string = '';
    public fireTextRegion: string;
    public textRM: string = '';
    public textRegion: string = '';
    public total = 0;
    public totalDesk = 0;
    public totalDiscounts: { totalDiscountedPrice: number, separatedDiscounts: Array<IDiscounts> };
    public installmentDesk = 0;
    public isTablet: boolean;
    public isSmall: boolean;
    public counterPayment = 1;
    public counterCodeReader = 1;
    public counterSiliconCase: number = 1;
    public counterGlassPlate: number = 1;
    public counterCodeLector: number = 1;
    public counterDesk;
    public newPreview: string;
    public url: string;
    public isBtnDisabled: boolean = true;
    public isDirect: boolean = false;
    public urlPlan: string = '';
    public data: Array<Tariff>;
    public deviceFeature: IDeviceFeature = {
        name: '',
        device: '',
        options: [],
        features: [],
        delivery: {
            fireText: undefined,
            showFire: false,
            textRM: '',
            textRegion: ''
        },
        previews: []
    };
    public dataType: string;
    public OPtionsDesk = OPtionsDesk;
    public activePanel = -1;
    private scrolling = false;
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.isTablet = window.innerWidth <= 992;
        this.isSmall = window.innerWidth <= 510;
        if (this.isSmall) {
            this.currentStep = 0;
            this.transformStyleSubject.next(`translateY(0px)`);
        }
    }

    constructor(
        private readonly _meta: MetaService,
        public dialog: MatDialog,
        @Inject(PLATFORM_ID) private platformId: Object,
        private _currencyPipe: CurrencyLocalePipe,
        private activatedRoute: ActivatedRoute,
        private urlService: UrlService,
        private router: Router,
        public buttonTraceService: ButtonTraceService,
        private cdRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
        if (this.url === 'directa') {
            this.isDirect = true;
            this.urlPlan = 'directa/';
        }

        this.activatedRoute.data.subscribe(data => {
            if (data?.name) this.dataType = data?.name;
        });
        if (isPlatformBrowser(this.platformId)) {
            this._setMeta();
            this.isTablet = window.innerWidth <= 992;
            this.isSmall = window.innerWidth <= 510;
        }
        this.getDataDevice();
        // Checking for isSelected inside dataOptions
        this.data.forEach((x, indexX) => {
            x.options.forEach((y, indexY) => {
                if (y.cards) {
                    y.cards.forEach((z, indexZ) => {
                        z.count = 1;
                        if (z.isSelected) {
                            this.selectCard(indexX, indexY, indexZ);
                        }
                    });
                }
            });
        });
    }

    @HostListener('touchstart', ['$event'])
    public onTouchStart(event: TouchEvent) {
        this.startX = event.touches[0].clientX;
        this.startY = event.touches[0].clientY;
    }
    @HostListener('touchmove', ['$event'])
    public onTouchMove(event: TouchEvent) {
        const deltaX = event.touches[0].clientX - this.startX;
        const deltaY = event.touches[0].clientY - this.startY;
        if (Math.abs(deltaY) > Math.abs(deltaX)) {
            event.preventDefault();
        }
    }
    @HostListener('wheel', ['$event'])
    public onWheel(event: any) {
        if (this.previewContainer.nativeElement.contains(event.target)) {
            event.preventDefault();
        }
    }

    public ngAfterViewInit(): void {
        if (this.previewContainer) {
            fromEvent(this.previewContainer.nativeElement, 'touchmove')
                .pipe(debounceTime(50))
                .subscribe((event: TouchEvent) => {
                    this.handleTouchMove(event);
                });
            fromEvent(this.previewContainer.nativeElement, 'wheel')
                .pipe(
                    tap((event: WheelEvent) => {
                        event.preventDefault();
                    }),
                    throttleTime(200, undefined, { leading: true, trailing: false }),
                    tap((event: WheelEvent) => {
                        this.scrollControl(event);
                    }),
                    debounceTime(100)
                )
                .subscribe();
        }
    }

    public ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    private handleTouchMove(event: TouchEvent) {
        if (this.previewContainer.nativeElement.contains(event.target)) {
            const deltaX = event.touches[0].clientX - this.startX;
            const deltaY = event.touches[0].clientY - this.startY;
            if (Math.abs(deltaY) > Math.abs(deltaX)) {
                this.scrollControl({ deltaY: -deltaY } as WheelEvent);
            }
        }
    }

    public openPanel(index): void {
        setTimeout(() => {
            this.activePanel = index;
        }, 0);
    }

    public getDataDevice(): void {
        switch (true) {
            case (this.dataType === 'mini' && this.urlService.getValue('comision') === 'true'):
                this.data = templateOPtionsGeneral;
                this.data[0].options[0].cards[0].id = dataOptions[this.dataType].commission.id;
                this.data[0].options[0].cards[0].price = dataOptions[this.dataType].commission.price;
                this.data[0].options[1].cards[0].id = dataOptions[this.dataType].commission.installmentId;
                this.data[0].options[1].cards[0].title = dataOptions[this.dataType].commission.installmentText;
                this.data[0].options[1].cards[0].price = dataOptions[this.dataType].commission.installmentPrice;
                break
            case (
                this.dataType === 'pro-semi-nueva' ||
                this.dataType === 'mini-semi-nueva' ||
                this.dataType === 'mini-s-semi-nueva' ||
                this.dataType === 'se-semi-nueva' ||
                this.dataType === 'desk-semi-nueva'
            ):
                this.data = templateOPtionsSemi;
                this.data[0].options[0].cards[0].id = dataOptions[this.dataType].id;
                this.data[0].options[0].cards[0].price = dataOptions[this.dataType].price;
                break
            case (this.dataType === 'desk'):
                this.data = OPtionsDesk;
                this.data[0].options[1].counterCards[0].old_price = dataOptions[this.dataType].old_price;
                break;
            default:
                this.data = templateOPtionsGeneral;
                this.data[0].options[0].cards[0].id = dataOptions[this.dataType].id;
                this.data[0].options[0].cards[0].price = dataOptions[this.dataType].price;
                this.data[0].options[0].cards[0].old_price = dataOptions[this.dataType].old_price;
                this.data[0].options[1].cards[0].id = dataOptions[this.dataType].installmentId;
                this.data[0].options[1].cards[0].title = dataOptions[this.dataType].installmentText;
                this.data[0].options[1].cards[0].price = dataOptions[this.dataType].installmentPrice;
                this.data[0].options[1].cards[0].old_price = dataOptions[this.dataType].old_installmentPrice;
                break;
        }
        this.data = dataOptions[this.dataType].options ? this.data.concat(dataOptions[this.dataType].options) : this.data;
        this.deviceFeature.name = dataType[this.dataType].name;
        this.deviceFeature.device = dataType[this.dataType].device;
        this.deviceFeature.options = dataType[this.dataType].options;
        this.deviceFeature.features = dataType[this.dataType].features;
        this.deviceFeature.previews = dataType[this.dataType].previews;

        this.fireText = dataType[this.dataType].delivery.fireText;
        if (dataType[this.dataType].delivery.fireTextRegion) {
            this.fireTextRegion = dataType[this.dataType].delivery.fireTextRegion;
        }
        this.textRM = dataType[this.dataType].delivery.textRM;
        this.textRegion = dataType[this.dataType].delivery.textRegion;
        this.showFire = dataType[this.dataType].delivery.showFire;
        this.showPre  = dataType[this.dataType].delivery.showPre;
        this.textPre = dataType[this.dataType].delivery.textPre;
    }

    public selectCard(
        indexOptions: number,
        indexCategory: number,
        indexCard: number
    ): void {
        const currentOptions = this.data[indexOptions];
        const currentCategory = currentOptions.options[indexCategory];
        switch (currentCategory.title) {
            case 'Plan':
            case 'Firma electrónica':
            case 'Lector de código de barras':
            case 'Lámina protectora de pantalla':
            case 'Funda de silicona':
                currentCategory.cards.forEach((card) => (card.isSelected = false));
                currentCategory.cards[indexCard].isSelected = true;
                break;
            case 'Individual':
            case 'Packs':
                currentCategory.counterCards.forEach((card) => (card.isSelected = false));
                break;
            case 'Escáner profesional integrado':
                currentCategory.cards.forEach((card) => (card.isSelected = false));
                currentCategory.cards[indexCard].isSelected = true;
                if (currentCategory.cards[indexCard].title === 'No, gracias la quiero sin escáner') {
                    this.data[1].options[0].cards[0].id = dataOptions[this.dataType].id;
                    this.data[1].options[1].cards[0].id = dataOptions[this.dataType].installmentId;
                } else {
                    this.data[1].options[0].cards[0].id = dataOptions['mini-s'].id;
                    this.data[1].options[1].cards[0].id = dataOptions['mini-s'].installmentId;
                }
                break;
            default:
                const newAarr = currentOptions.options.filter(
                    (option) => option.title !== 'Plan'
                );
                newAarr.forEach((option) => {
                    option.cards.forEach((card) => (card.isSelected = false));
                });
                currentCategory.cards[indexCard].isSelected = true;
                break;
        }
        this.checkTotal();
    }

    public selectColumnsCard(arr: Array<{ isSelected: unknown }>, index: number): void {
        arr.forEach((item, i) => item.isSelected = i === index);
        this.checkTotal();
    }

    public changeCounter(action: string, type: string): void {
        let installment = "";
        let checkQuota = false;
        let handlingMachines = false;
        if (this.data.filter(x => x.title === 'OPCIONES DE PAGO')[0].options.length > 1) {
            installment = this.data.filter(x => x.title === 'OPCIONES DE PAGO')[0].options[1].cards[0].title.split('+')[1];
            checkQuota = true;
        }
        const actionValue = action === 'inc' ? 1 : -1;
        switch (type) {
            case "Desk":
                this.counterPayment = this.counterPayment + actionValue;
                this.data[0].options[1].counterCards.find(x => x.title === type).count += actionValue;
                this.data.filter(x => x.title === 'OPCIONES DE PAGO')[0].options[0].cards[0].count = this.counterPayment;
                if (checkQuota) {
                    this.data.filter(x => x.title === 'OPCIONES DE PAGO')[0].options[1].cards[0].count = this.counterPayment;
                }
                handlingMachines=true;
                break;
            case 'payment':
                this.counterPayment = this.counterPayment + actionValue;
                this.data.filter(x => x.title === 'OPCIONES DE PAGO')[0].options[0].cards[0].count = this.counterPayment;
                if (checkQuota) {
                    this.data.filter(x => x.title === 'OPCIONES DE PAGO')[0].options[1].cards[0].count = this.counterPayment;
                    this.data.filter(x => x.title === 'OPCIONES DE PAGO')[0].options[1].cards[0].title = `Pie de $${this._currencyPipe.transform(
                        String(this.data.filter(x => x.title === 'OPCIONES DE PAGO')[0].options[1].cards[0].price * this.counterPayment))} + ${installment}`;
                }
                break;
            case 'codeReader':
                this.counterCodeReader = this.counterCodeReader + actionValue;
                break;
            case 'siliconCase':
                this.counterSiliconCase = this.counterSiliconCase + actionValue;
                this.data[4].options[0].cards[1].count = this.counterSiliconCase;
                break;
            case 'LEC':
                this.counterCodeLector = this.counterCodeLector + actionValue;
                if(this.dataType === 'desk'){
                    this.data[2].options[1].cards[1].count = this.counterCodeLector;
                }else{
                    this.data[1].options[1].cards[1].count = this.counterCodeLector;
                }
                break;
            case 'glassPlate':
                this.counterGlassPlate = this.counterGlassPlate + actionValue;
                this.data[2].options[1].cards[1].count = this.counterGlassPlate;
                break;
            case "Desk + SE":
            case "Desk + Mini S":
            case "Desk + Mini":
            case "Desk + PRO 2":
                this.data[0].options[0].counterCards.find(x => x.title === type).count += actionValue;
                handlingMachines = true;
                break;
        }
        this.terminalCounter = this.counterPayment;
        if (this.dataType === 'desk') {
            if (this.data[0].options[0].counterCards[0]?.count > 0) {
                this.terminalCounter += (this.data[0].options[0].counterCards[0].count * 2);
            }
            if (this.data[0].options[0].counterCards[1]?.count > 0) {
                this.terminalCounter += (this.data[0].options[0].counterCards[1].count * 2);
            }
            if (this.data[0].options[0].counterCards[2]?.count > 0) {
                this.terminalCounter += (this.data[0].options[0].counterCards[2].count * 2);
            }
            if (this.terminalCounter >= this.maxTerminals && handlingMachines) {
                this.dialog.open(DialogWarnDesk, {
                    data: {
                        case: 'warnMaxTerminals',
                    },
                    width: '400px',
                });
            }
        }
        this.checkTotal(installment);
    }

    public getCurrentCounter(option: TariffOption): number {
        switch (option.title) {
            case 'Desk':
            case 'Al contado':
            case 'En cuotas':
                return this.counterPayment;
            case 'Funda de silicona':
                return this.counterSiliconCase;
            case 'Lector de código de barras':
                return this.counterCodeLector;
            case 'Lámina protectora de pantalla':
                return this.counterGlassPlate;
            case 'Desk + PRO 2':
                return this.data[0].options[0].counterCards[0].count;
            case 'Desk + SE':
                return this.data[0].options[0].counterCards[1].count;
            case 'Desk + Mini S':
                return this.data[0].options[0].counterCards[2].count;
            default:
                return this.counterCodeReader;
        }
    }

    public getDisableConditions(card: any): boolean {
        const isDeskType = this.dataType === 'desk';
        const isProSemiNueva = this.dataType === 'pro-semi-nueva';
        const isOutOfStock = card.out_of_stock;
        const isDeskTitle = card.title === 'Desk';
        const maxAllowedTerminals = isDeskTitle ? this.maxTerminals : this.maxTerminals - 1;
        const exceedsCounterLimit = !isDeskType && (this.getCurrentCounter(card) >= (isProSemiNueva ? 2 : 10));
        const exceedsTerminalLimit = isDeskTitle ? this.terminalCounter >= this.maxTerminals : this.terminalCounter >= maxAllowedTerminals;
        return exceedsCounterLimit || isOutOfStock || exceedsTerminalLimit;
    }

    public openModal(text: string, _card?: Card): void {
        switch (text) {
            case 'Plan':
                this.openModalPlans();
                break;
            case 'Firma electrónica':
                this.openModalSinature();
                break;
            case 'Lector de código':
                this.openDialogSeeProduct();
                break;
        }
    }

    public openModalPlans(): void {
        this.dialog.open(DialogPlansComponent, {
            panelClass: 'dialogPlans',
            width: '816px',
        });
    }

    public openModalSinature(): void {
        this.dialog.open(DialogSinatureComponent, {
            panelClass: 'dialogSignature',
            width: '816px',
        });
    }

    public openDialogSeeProduct(event?: Event): void {
        if(event){
            event.stopPropagation();
        }
        this.dialog.open(DialogSeeProduct2Component, {
            width: '659px',
        });
    }

    public isShowMore(title: string): boolean {
        if (
            title === 'Plan' ||
            title === 'Firma electrónica' ||
            title === 'Lector de código'
        ) {
            return true;
        }
        return false;
    }

    public selectOption(card: Card, option: SelectOption): void {
        card.id = option.productId;
        card.title = option.title;
        card.price = option.price;
        card.sup_value = option.sup_value;
        this.checkTotal();
    }

    private _setMeta(): void {
        switch (this.dataType) {
            case "mini":
                if (this.urlService.getValue('comision') === 'true') {
                    this._meta.setTitle(
                        'TUU Mini, un POS portátil para recibir pagos donde sea'
                    );
                    this._meta.setTag(
                        'description',
                        'La máquina POS económica y de bolsillo que funciona de forma autónoma, sin necesidad de conectarla a un celular. Recibe pagos con tarjetas desde $65* + IVA por transacción.'
                    );
                }
                else {
                    this._meta.setTitle(
                        'TUU Mini, un POS portátil para recibir pagos donde sea'
                    );
                    this._meta.setTag(
                        'description',
                        'La máquina POS económica y de bolsillo que funciona de forma autónoma, sin necesidad de conectarla a un celular. Recibe pagos con tarjetas desde $65* + IVA por transacción.'
                    );
                }
                break;
            case "mini-s":
                this._meta.setTitle(
                    'TUU Mini S, POS para recibir pagos con escáner integrado'
                );
                this._meta.setTag(
                    'description',
                    'El terminal POS con escáner profesional integrado que funciona de forma autónoma, sin necesidad de conectar a un celular. Recibe pagos con tarjetas desde $65* + IVA por transacción.'
                );
                break;
            case "pro":
                this._meta.setTitle(
                    'TUU Pro, el POS más completo para recibir pagos con tarjetas'
                );
                this._meta.setTag(
                    'description',
                    'La máquina POS todo-en-uno para recibir pagos con tarjetas con comisión desde $65* + IVA por transacción. Integra emisión de boleta electrónica e impresora térmica.'
                );
                break;
            case "se":
                this._meta.setTitle(
                    'TUU SE, el POS más completo para recibir pagos con tarjetas'
                );
                this._meta.setTag(
                    'description',
                    'La máquina POS todo-en-uno para recibir pagos con tarjetas con comisión desde $65* + IVA por transacción. Integra emisión de boleta electrónica e impresora térmica.'
                );
                break;
            //case "pro-semi-nueva":
            //break;
            default:
                this._meta.setTitle(
                    'Máquina para pagar con tarjeta y emitir boleta electrónica'
                );
                this._meta.setTag(
                    'description',
                    'Compra en cuotas TUU máquina para recibir pagos con tarjetas. Olvídate de arriendos y aprovecha comisiones flexibles con abono desde un día hábil'
                );
                break;
        }
        this._meta.setTag(
            'keywords',
            'Máquina para pagar con tarjeta, máquina para cobrar con tarjeta, máquina para recibir pagos con tarjeta, dispositivo para pagar con tarjeta, POS, terminal POS, máquina POS, sistema de pago con tarjeta, dispositivo para pagar con tarjeta, Boleta electrónica, máquina boleta electrónica, emitir boleta, máquina para imprimir boleta, sistema boleta electrónica, Punto de venta, sistema de venta, software punto de venta,POS, control de inventario, gestión de inventario, control de stock.'
        );
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag(
            'og:image',
            this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-home.png'
        );
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag(
            'og:image:alt',
            'Máquina para pagar con tarjeta y emitir boleta electrónica'
        );
    }

    private checkTotal(installment?: string): void {
        let checkCounter = false;
        this.isBtnDisabled = true;
        this.total = 0;
        this.totalDesk = 0;
        this.installmentDesk = 0;
        this.totalDiscounts = { totalDiscountedPrice: 0, separatedDiscounts: [] };
        this.data.forEach((x) => {
            x.options.forEach((y) => {
                if (y.cards) {
                    y.cards.forEach((z) => {
                        if (z.isSelected && !!z.price) {
                            if (y.title === 'En cuotas' || y.title === 'Al contado') {
                                this.pickPrice = true;
                            }
                            if (this.dataType !== 'Desk' || (this.dataType === 'Desk' && y.counterCards.find(x => x.title === 'Desk').count > 0)) {
                                this.total += z.price * (z.count ?? 1);
                            }
                        }
                        if (this.dataType === 'desk' && y.title === 'Al contado') {
                            if (z.type !== "Installment") {
                                this.totalDesk += z.price * (z.count ?? 1);
                            }
                        }
                    });
                }
                if (y.columnCards) {
                    y.columnCards.forEach((z) => {
                        if (z.isSelected) {
                            if (z.title == 'Entel' || z.title == 'Movistar') this.pickChip = true;
                        }
                    })
                }
                if (y.counterCards) {
                    y.counterCards.forEach((z) => {
                        if (z.count > 0) {
                            checkCounter = true;
                            const isSelected = this.data[1].options[1]?.cards[0].isSelected ?? false; //si se selecciono pago en cuotas
                            if (z.title !== 'Desk') {
                                this.total += (isSelected ? z.price / 3 : z.price) * z.count;
                                this.totalDesk += z.price * z.count;
                            }
                            if (z.price && z.count > 0) {
                                this.totalDiscounts.separatedDiscounts.push({
                                    title: z.title,
                                    price: (isSelected ? z.old_price / 3 : z.old_price) * z.count,
                                    discounted_price: (isSelected ? z.price / 3 : z.price) * z.count,
                                    discount: parseFloat((isSelected ? z.discount / 3 : z.discount)?.toFixed(1))
                                });
                            }
                        }
                    });
                }
            });
        });
        //Suma todo los precios dentro de totalDIscounts.seeparatedDiscounts que contengan la variable de discount, y luego restale esa misma sumatoria de los discounted_price
        this.totalDiscounts.totalDiscountedPrice = 0;
        this.totalDiscounts.separatedDiscounts.forEach(discount => {
            if (discount.discount) {
                this.totalDiscounts.totalDiscountedPrice += discount.price - discount.discounted_price;
            }
        });
        this.installmentDesk = this.totalDesk / 3;
        if (this.dataType === 'desk' && installment) {
            this.data.filter(x => x.title === 'OPCIONES DE PAGO')[0].options[1].cards[0].title = `Pie de $${this._currencyPipe.transform(
                this.installmentDesk)} + ${installment}`;
        }
        if (this.pickPrice && this.pickChip && ((this.dataType === 'desk' && checkCounter) || this.dataType !== 'desk')) this.isBtnDisabled = false;
        if (this.deviceFeature.name == "Mini") this.isBtnDisabled = true;
    }

    public onSubmitInteraction(): void {
        if (this.dataType === 'desk') {
            if (this.data[0].options[0].counterCards[0].count == 0 &&
                this.data[0].options[0].counterCards[1].count == 0 &&
                this.data[0].options[0].counterCards[2].count == 0 &&
                this.counterPayment >= 1) {
                const dialog = this.dialog.open(DialogWarnDesk, {
                    data: {
                        case: 'warnIndividualDesk',
                    },
                    width: '520px',
                });
                dialog.afterClosed().subscribe({
                    next: (result) => {
                        if (result === 'forward') {
                            this.onSubmit();
                        }
                    }
                });
            }
            else {
                this.onSubmit();
            }
        } else {
            this.onSubmit();
        }
    }

    private fillIntoProduct(element: { id: string, count?: number, price?: string } | any): void {
        const array_ids = element.id?.split(",") ?? element.productId.split(",");
        const eligibleIds = new Set(["DESK", "T1MINI", "T1MINIS", "P2SE"]);
        const quotes = this.data[1]?.options[1]?.cards[0]?.isSelected;
        for (let id of array_ids) {
            if (id === "" || element.title === "Desk") continue;

            if (//Se aplica pago en cuotas
                quotes && 
                eligibleIds.has(id)
            ) {
                id += "3"; 
            } else if(quotes && id === 'PRO2'){
                id += "_3";
            }

            if (this.products[id]) {
                if (element.count) {
                    this.products[id].quantity = this.products[id].quantity + element.count;
                }
                if (element.price) {
                    this.products[id].price = element.price;
                }
            } else {
                this.products[id] = {
                    quantity: element.count ? element.count : 0,
                    price: element.price ? element.price : 0
                }
            }
        }
    }

    public onSubmit(): void {
        let subproduct = '';
        let qty = "";
        this.products = {};
        this.data.forEach((x) => {
            x.options.forEach((y) => {
                if (y.cards) {
                    y.cards.forEach((z) => {
                        if (z.isSelected) {
                            switch (y.title) {
                                case 'En cuotas':
                                case 'Al contado':
                                    this.fillIntoProduct(z);
                                    break;
                                case 'Rollos de papel térmico':
                                case 'Firma electrónica':
                                    if (z.title.includes('No, gracias') === false) {
                                        z.count = 1;
                                        this.fillIntoProduct(z);
                                    }
                                    break;
                                case 'Lector de código de barras':
                                    if(z.title.includes('No, gracias')===false){
                                        z.count = this.counterCodeLector;
                                        this.fillIntoProduct(z);
                                    }
                                default:
                                    break;
                            }
                            if (z.title == 'Funda de silicona') {
                                z.count = this.counterSiliconCase;
                                this.fillIntoProduct(z);
                            }
                        }
                    });
                }
                if (y.columnCards) {
                    y.columnCards.forEach((z) => {
                        if (z.isSelected) {
                            this.fillIntoProduct(z);
                        }
                    })
                }
                if (y.counterCards) {
                    y.counterCards.forEach((z) => {
                        if (z.count > 0) {
                            this.fillIntoProduct(z);
                        }
                    })
                }
            });
        });
        let chipQuantity = 0;
        let key = '';
        switch (this.dataType) {
            case 'desk':
                key = this.products.DESK ? 'DESK' : this.products.DESK3 ? 'DESK3' : null;
                if(key === 'DESK'){
                    chipQuantity = this.products.DESK.quantity +
                        (this.products.P2SE?.quantity ?? 0) +
                        (this.products.T1MINIS?.quantity ?? 0) +
                        (this.products.T1MINI?.quantity ?? 0) + 
                        (this.products.PRO2?.quantity ?? 0);
                }else{
                    chipQuantity = this.products.DESK3.quantity +
                        (this.products.P2SE3?.quantity ?? 0) +
                        (this.products.T1MINIS3?.quantity ?? 0) +
                        (this.products.T1MINI3?.quantity ?? 0) +
                        (this.products.PRO2_3?.quantity ?? 0);
                }
                break;
            case "se":
                key = this.products.P2SE ? 'P2SE' : this.products.P2SE3 ? 'P2SE3' : null;
                if (key) {
                    chipQuantity = this.products[key].quantity;
                }
                break;
            case 'mini':
                key = this.products.T1MINI ? 'T1MINI' : this.products.T1MINI3 ? 'T1MINI3' : null;
                if (key) {
                    chipQuantity = this.products[key].quantity;
                }
                break;
            case 'mini-s':
                key = this.products.T1MINIS ? 'T1MINIS' : this.products.T1MINIS3 ? 'T1MINIS3' : null;
                if (key) {
                    chipQuantity = this.products[key].quantity;
                }
                break;
            case 'pro-2':
                key = this.products.PRO2 ? 'PRO2' : this.products.PRO2_3 ? 'PRO2_3' : null;
                if (key) {
                    chipQuantity = this.products[key].quantity;
                }
                break;
            case 'se-semi-nueva':
                key = this.products.SESEMI ? 'SESEMI' : null;
                if (key) {
                    chipQuantity = this.products[key].quantity;
                }
                break;
            case 'desk-semi-nueva':
                key = this.products.DESKSEMI ? 'DESKSEMI' : null;
                if (key) {
                    chipQuantity = this.products[key].quantity;
                }
            default:
                break;
        }
        (this.products.CGE || this.products.CGM).quantity = chipQuantity;

        for (let key in this.products) {
            subproduct += `${key},`;
            qty += `${this.products[key].quantity},`;
        }

        subproduct = subproduct.replace(/,\s*$/, "");
        qty = qty.replace(/,\s*$/, "");

        if (isPlatformBrowser(this.platformId)) {
            this.usePromoCode();
            if (this.promoCode !== '' && this.promoCode !== null && this.promoCode !== undefined) {
                this.reInterpretPromoCode();
            }
        }

        // const url = `${this.urlCart}subproduct=${subproduct}&qtyproduct=${qty}`
        // this.goToUrl(url);

        const urlProduct = this.urlService.getValue('subproduct');
        const purchaseOrigin = this.urlService.getValue('purchaseOrigin');
        const videoask = this.urlService.getValue('videoask');
        const opciones = this.urlService.getValue('opciones');
        const maquina_r = this.urlService.getValue('maquina_r');
        if (!!urlProduct) {
            var url: URL = this.urlService.addParams(new URL(this.urlEnv.TUU_CART_URL), [
                { name: 'subproduct', value: `${urlProduct},${subproduct}` },
                { name: 'qtyproduct', value: `1,${qty}` }
            ])
            if (urlProduct === 'TUUP') {
                url = this.urlService.addParams(url, [{ name: 'period_opt', value: '1' }])
            }
            if (!!purchaseOrigin) {
                url = this.urlService.addParams(url, [{ name: 'purchaseOrigin', value: purchaseOrigin }])
            }
            if (!!this.promoCode) {
                url = this.urlService.addParams(url, [{ name: 'pr_code', value: this.promoCode }])
            }
            if (!!videoask) {
                url = this.urlService.addParams(url, [{ name: 'videoask', value: videoask }])
            }
            if (!!opciones) {
                url = this.urlService.addParams(url, [{ name: 'opciones', value: opciones }])
            }
            if (!!maquina_r) {
                url = this.urlService.addParams(url, [{ name: 'maquina_r', value: maquina_r }])
            }
            this.goToUrl(decodeURIComponent(url.href));
        } else {
            if (!!this.promoCode) {
                this.router.navigate([this.urlPlan + 'seleccion-plan'], {
                    queryParams: {
                        subproduct: subproduct,
                        qtyproduct: qty,
                        pr_code: this.promoCode
                    },
                    queryParamsHandling: 'merge'
                });
            } else {
                this.router.navigate([this.urlPlan + 'seleccion-plan'], {
                    queryParams: {
                        subproduct: subproduct,
                        qtyproduct: qty
                    },
                    queryParamsHandling: 'merge'
                });
            }
        }
    }

    private usePromoCode(): void {
        this.promoCode = sessionStorage.getItem('prCode');
        sessionStorage.removeItem('prCode');
    }

    private reInterpretPromoCode(): void {
        let promoArray;
        if (this.promoCode.includes(',')) {
            promoArray = this.promoCode.split(',');
            this.promoCode = '';
            switch (this.dataType) {
                case 'mini':
                    for (let code of promoArray) {
                        if (code.toUpperCase().includes('MINI')) {
                            this.promoCode = code;
                            break;
                        }
                    }
                    break;
                case 'mini-s':
                    for (let code of promoArray) {
                        if (code.toUpperCase().includes('MINIS') || code.toUpperCase().includes('MINI-S')) {
                            this.promoCode = code;
                            break;
                        }
                    }
                    break;
                case 'pro':
                    for (let code of promoArray) {
                        if (code.toUpperCase().includes('PRO')) {
                            this.promoCode = code;
                            break;
                        }
                    }
                    break;
                case 'se':
                    for (let code of promoArray) {
                        if (code.toUpperCase().includes('SE')) {
                            this.promoCode = code;
                            break;
                        }
                    }
                    break;
                case 'pro-semi-nueva':
                    for (let code of promoArray) {
                        if (code.toUpperCase().includes('PRO-SEMI-NUEV') ||
                            code.toUpperCase().includes('PRO SEMI NUEV')) {
                            this.promoCode = code;
                            break;
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }

    private goToUrl(url: string): void {
        window.open(url, '_self');
    }

    public checkDetails(card: any): void {
        if (this.dataType === 'desk' && card.id.split(',')[1]!='T1MINI') {
            this.dialog.open(DialogMachineCard, {
                data: card,
                width: '659px'
            });
        }
    }

    public goTo(event?: Event): void {
        if (event) {
            event.preventDefault();
        }
        this.router.navigate(['/contactar-con-ventas']);
    }

    public scrollControl(event: WheelEvent) {
        //DeltaY Negative is up, Positive is down
        if (event.deltaY < 0) {
            if (this.currentStep > 0) {
                this.currentStep--;
                this.moveTo(this.deviceFeature.previews[this.currentStep].route);
            }
        } else {
            if (this.currentStep < this.deviceFeature.previews.length - 1) {
                this.currentStep++;
                this.moveTo(this.deviceFeature.previews[this.currentStep].route);
            }
        }
    }

    public moveTo(preview): void {
        const step = preview.slice(-1);
        let stepWidth = 368;
        if (this.isSmall) {
            stepWidth = 245;
        }
        this.currentStep = parseInt(step) - 1;
        this.transformStyleSubject.next(`translateY(${-(this.currentStep * stepWidth)}px)`);
    }
}