export const DATA_PRICE_ASSOCIATION = {
    "mini": {
        price: 59900,
        old_price: 79900,
        show_new_price: true,
        installmentPrice: 19967,
        label: {
            text: 'Agotado',
            class: 'no_stock'
        }
    },
    "mini-s": {
        price: 79900,
        old_price: 79900,
        show_new_price: false,
        installmentPrice: 26633,
        label: {
            text: 'Agotado',
            class: 'no_stock'
        }
    },
    "se": {
        price: 64900,
        old_price: 99900,
        show_new_price: true,
        installmentPrice: 21633,
        old_installmentPrice: 33300
    },
    // "pro": {
    //     price: 99900,
    //     old_price: 99900,
    //     show_new_price: false,
    //     installmentPrice: 33300,
    // },
    "pro-semi-nueva": {
        price: 59940,
        old_price: 59940,
        show_new_price: false,
        installmentPrice: 59940,
    },
    "se-semi-nueva": {
        price: 47940,
        old_price: 47940,
        show_new_price: false,
        installmentPrice: 10
    },
    "mini-semi-nueva": {
        price: 17940,
        old_price: 17940,
        show_new_price: false,
        installmentPrice: 20
    },
    "mini-s-semi-nueva": {
        price: 29940,
        old_price: 29940,
        show_new_price: false,
        installmentPrice: 30
    },
    "desk":{
        price: 159900,
        old_price: 189900,
        show_new_price: true,
        installmentPrice: 53300
    },
    "desk-semi-nueva":{
        price: 113940,
        old_price: 113940,
        show_new_price: false
    },
    "pro2":{
        price: 39900,
        old_price: 69900,
        show_new_price: true,
        installmentPrice: 13300,
        old_installmentPrice: 23300
    }
}

export const DATA_CARD_PRICE = [
    /* {
        device: 'device-pro-2',
        preview: "preview-pro",
        features: [
            {
                icon: 'smartphone-vertical',
                name: 'Diseño curvo de nueva generación'
            },
            {
                icon: 'printerV2_sanitized',
                name: 'Impresora térmica integrada',
            }
        ],
        title: 'PRO 2',
        price: DATA_PRICE_ASSOCIATION['pro2'].price,
        old_price: DATA_PRICE_ASSOCIATION['pro2'].old_price,
        show_new_price: DATA_PRICE_ASSOCIATION['pro2'].show_new_price,
        installmentPrice: DATA_PRICE_ASSOCIATION['pro2'].installmentPrice,
        feature_card: 'preventa',
        label:"25%OFF",
        offer:false,
        button: {
            id: 'comprar-pro-2',
            text: 'Comprar',
            path: '/comprar-pro-2'
        },
        list: [
            {
                icon: 'smartphone-vertical',
                text: '<b>Nueva máquina de pagos</b> con moderno diseño y pantalla más grande.'
            },
            {
                icon: 'icon-sprite-credit',
                text: '<b>Acepta pagos con tarjetas</b> de todo tipo y efectivo.',
            },
            {
                icon: "icon-sprite-file-blank",
                text: "<b>Emite boletas o facturas electrónicas</b> para todas tus ventas. Imprime o envía por correo los comprobantes.",
            },
            {
                icon: "icon-sprite-pie-chart-analysis",
                text: '',
                quotes:true
            },
            {
                icon: 'icon-sprite-circle-dollar',
                text: '',
                immediate:true
            }
        ]
    },
    {
        device: 'device-desk',
        preview: "preview-demonstartion-desk",
        features: [
            {
                icon: 'icon-sprite-point-of-sales',
                name: 'Estación de punto de venta',
            },
            {
                icon: 'printerV2_sanitized',
                name: 'Impresora térmica integrada',
            },
        ],
        title: 'Desk',
        price: DATA_PRICE_ASSOCIATION['desk'].price,
        old_price: DATA_PRICE_ASSOCIATION['desk'].old_price,
        show_new_price: DATA_PRICE_ASSOCIATION['desk'].show_new_price,
        installmentPrice: DATA_PRICE_ASSOCIATION['desk'].installmentPrice,
        feature_card: 'preventa',
        button: {
            id: 'comprar-desk',
            text: 'Comprar',
            path: '/comprar-desk'
        },
        list: [
            {
                icon: 'icon-sprite-layout-fluid-new',
                text:'',
                catalog:true
            },
            {
                icon: 'icon-sprite-pistol',
                text: 'Escanea tus productos y agiliza tus ventas complementando con el <b>lector de códigos de barra.</b>',
            },
            {
                icon: 'icon-sprite-credit',
                text: 'Integración rápida con máquinas de pago TUU para <b>aceptar pagos con tarjeta.</b>',
            },
            {
                icon: "icon-sprite-file-blank",
                text: "<b>Emite boletas o facturas electrónicas</b> para todas tus ventas. Imprime o envía por correo los comprobantes.",
            },
            {
                icon: "icon-sprite-desktop-screen",
                text: '<b>Doble pantalla HD:</b> Principal táctil de 10,1" y secundaria para resumen de venta 4,3".'
            }
        ]
    },
    {
        device: 'device-p2-mini-s',
        preview: "preview-sunmi-mini-s",
        features: [
            {
                icon: 'smartphone-vertical',
                name: 'Tamaño más pequeño y portable'
            },
            {
                icon: 'icon-sprite-code-scan',
                name: 'Escáner profesional integrado'
            }
        ],
        title: 'Mini S',
        price: DATA_PRICE_ASSOCIATION['mini-s'].price,
        old_price: DATA_PRICE_ASSOCIATION['mini-s'].old_price,
        show_new_price: DATA_PRICE_ASSOCIATION['mini-s'].show_new_price,
        installmentPrice: DATA_PRICE_ASSOCIATION['mini-s'].installmentPrice,
        label:"25%OFF",
        button: {
            id: 'comprar-mini-s',
            text: 'Comprar',
            path: '/comprar-mini-s',
            isDisabled: false
        },
        list: [
            {
                icon: 'icon-sprite-code-scan',
                text: 'Ideal para <b>vender escaneando tus productos</b> y recibiendo tus pagos en la misma máquina.'
            },
            {
                icon: 'icon-sprite-credit',
                text: '<b>Acepta pagos con tarjetas</b> de todo tipo y efectivo.',
            },
            {
                icon: "icon-sprite-file-blank",
                text: "<b>Emite boletas o facturas electrónicas</b> para todas tus ventas. Imprime o envía por correo los comprobantes.",
            },
            {
                icon: "icon-sprite-pie-chart-analysis",
                text: '',
                quotes:true
            },
            {
                icon: 'icon-sprite-circle-dollar',
                text: '',
                immediate:true
            }
        ]
    },
    {
        device: 'device-sunmi-se',
        preview: "terminal-demonstartion-se",
        features: [
            {
                icon: 'icon-sprite-calculator',
                name: 'Modelo clásico de máquina de pagos'
            },
            {
                icon: 'printerV2_sanitized',
                name: 'Impresora térmica integrada',
            }
        ],
        title: 'SE',
        price: DATA_PRICE_ASSOCIATION['se'].price,
        old_price: DATA_PRICE_ASSOCIATION['se'].old_price,
        show_new_price: DATA_PRICE_ASSOCIATION['se'].show_new_price,
        installmentPrice: DATA_PRICE_ASSOCIATION['se'].installmentPrice,
        label:"25%OFF",
        offer:false,
        button: {
            id: 'comprar-se',
            text: 'Comprar',
            path: '/comprar-se'
        },
        list: [
            {
                icon: 'icon-sprite-calculator',
                text: '<b>La máquina de pagos clásica</b> de TUU con impresora integrada.'
            },
            {
                icon: 'icon-sprite-credit',
                text: '<b>Acepta pagos con tarjetas</b> de todo tipo y efectivo.',
            },
            {
                icon: "icon-sprite-file-blank",
                text: "<b>Emite boletas o facturas electrónicas</b> para todas tus ventas. Imprime o envía por correo los comprobantes.",
            },
            {
                icon: "icon-sprite-pie-chart-analysis",
                text: '',
                quotes:true
            },
            {
                icon: 'icon-sprite-circle-dollar',
                text: '',
                immediate:true
            }
        ]
    },*/
    {
        device: 'device-sunmi-se',
        features: [
            {
                icon: 'icon-roll-tuu',
                name: 'Impresora<br>térmica',
                width: 35,
                height:36
            },
            {
                icon: 'icon-chip-tuu',
                name: 'Chip de <br>Internet',
                width: 36,
                height:27
            }
        ],
        title: 'SE',
        price: DATA_PRICE_ASSOCIATION['se'].price,
        old_price: DATA_PRICE_ASSOCIATION['se'].old_price,
        show_new_price: DATA_PRICE_ASSOCIATION['se'].show_new_price,
        installmentPrice: DATA_PRICE_ASSOCIATION['se'].installmentPrice,
        label:"OFERTA",
        offer:true,
        button: {
            id: 'comprar-se',
            text: 'Comprar',
            path: '/comprar-se'
        },
        list: [
            {
                icon: 'icon-sprite-card-atm',
                text: '<b>Acepta tarjetas</b> con banda</br> magnética, chip y sin contacto.'
            },
            {
                icon: 'icon-sprite-file',
                text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
            },
            {
                icon: 'icon-sprite-attach-money',
                text: 'Recibe pagos con efectivo y <b>emite boleta o factura electrónica</b>.',
                /*tooltip: {
                    text: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
                }*/
            },
            {
                icon: 'icon-sprite-coins-stacked',
                text: 'Solicita <b>Abono Inmediato</b> para recibir el dinero de tus ventas en 15 minutos.',
                link: {
                    path: '/abono-inmediato',
                    id: 'abono-inmediato'
                }
            },
            {
                icon: 'icon-sprite-pie-chart-new',
                text: 'Acepta pagos en cuotas y paga <br><b>0% de comisión</b>.',
                link: {
                    path: 'cuotas-tuu',
                    id: 'cuotas-tuu'
                }
            },
            /*{
                icon: 'icon-sprite-printer',
                text: '<b>Impresora</b> térmica integrada.'
            },
            {
                icon: 'icon-sprite-sim-card',
                text: '<b>Chip de Internet</b> con gigas libres.'
            },
            {
                icon: 'icon-sprite-battery',
                text: '<b>Batería</b> de larga duración.'
            }, {
                icon: 'icon-sprite-android-phone-slash',
                text: '<b>No necesitas</b> un celular.'
            }, {
                icon: 'icon-sprite-camera',
                text: 'Cámara de <b>0,3 megapixeles</b>'
            }, {
                icon: 'icon-sprite-screen',
                text: '<b>Pantalla táctil</b> de 5”',
                tooltip: {
                    text: 'Pantalla Táctil 5" FW+, Resolución: 960x480px',
                    style: 'w-196'
                }
            }*/
        ]
    }
    /* {
        device: 'device-p2-mini',
        preview: "preview-sunmi-mini",
        features: [
            {
                icon: 'icon-sprite-sim-card-new',
                name: 'Chip de <br/>Internet con gigas libres'
            },
            {
                icon: 'icon-sprite-android-phone-slash-new',
                name: 'No necesitas un<br/> celular'
            }
        ],
        title: 'Mini',
        price: DATA_PRICE_ASSOCIATION['mini'].price,
        old_price: DATA_PRICE_ASSOCIATION['mini'].old_price,
        show_new_price: DATA_PRICE_ASSOCIATION['mini'].show_new_price,
        installmentPrice: DATA_PRICE_ASSOCIATION['mini'].installmentPrice,
        label: DATA_PRICE_ASSOCIATION['mini'].label,
        out_stock: true,
        discount: Math.round((DATA_PRICE_ASSOCIATION['mini'].old_price - DATA_PRICE_ASSOCIATION['mini'].price) * 100 / DATA_PRICE_ASSOCIATION['mini'].old_price),
        feature_card: 'más económico',
        button: {
            id: 'comprar-mini',
            text: 'Comprar',
            path: '/comprar-mini',
            isDisabled:true
        },
        list: [
            {
                icon: 'icon-sprite-credit-card-new',
                text: '<b>Acepta tarjetas</b> con banda</br> magnética, chip y sin contacto.'
            },
            {
                icon: 'icon-sprite-doc-new',
                text: '<b>Recibe pago con tarjetas</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
            },
            {
                icon: 'icon-sprite-attach-money-new',
                text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.',
            },
            {
                icon: "icon-sprite-coins-stacked",
                text: "<b>Abono inmediato</b> para recibir el<br/>dinero de tus ventas en 15 minutos.",
                link: {
                    path: '/abono-inmediato',
                    id: 'abono-inmediato'
                }
            },
            {
                icon: "icon-sprite-pie-chart-new",
                text: "<b>Acepta pagos en cuotas</b> y paga 0% de comisión.",
                link: {
                    path: "commission",
                    id: "",//[ ] ID de tracking pendiente
                    isSection: true
                }
            },
            {
                icon: 'icon-sprite-screen-new',
                text: '<b>Pantalla HD</b> táctil de 5”',
                tooltip: {
                    text: "Pantalla IPS Táctil, Capacitiva, multipuntos táctiles de 5” y Resolución HD de 1280x720."
                }
            },
            {
                icon: 'icon-sprite-battery-new',
                text: '<b>Batería</b> de larga duración.'
            },
        ]
    } */
]