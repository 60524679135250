import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-country',
    styleUrls: ['./dialog-warn-desk.component.scss'],
    templateUrl: './dialog-warn-desk.component.html'
})
export class DialogWarnDesk implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<DialogWarnDesk>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
    }
    public closeDialog(action: string) {
        this.dialogRef.close(action);
    }
}
