import { Component, EventEmitter, Output, Input } from '@angular/core';
import { ButtonTraceService } from '../../../../../../../../common/services/button-trace';
import { Router } from '@angular/router';
@Component({
    selector: 'app-dropdown-menu-products',
    templateUrl: './dropdown-menu-products.component.html',
    styleUrls: ['./dropdown-menu-products.component.scss']
})
export class DropdownMenuProductsComponent {
    @Output() public hideMenu = new EventEmitter<boolean>();
    @Input() public url;
    
    constructor(
        public buttonTraceService: ButtonTraceService,
        private router: Router
    ) { }

    closeMenu(): void {
        this.hideMenu.emit(false);
    }

    goTo(url: string, event: Event): void {
        event.preventDefault();
        this.router.navigate([url]);
    }

}
