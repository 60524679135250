import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-credit-reviews',
    templateUrl: './credit-reviews.component.html',
    styleUrls: ['./credit-reviews.component.scss']
})
export class CreditReviewsComponent implements OnInit, AfterViewInit {
    @ViewChild('reviews', { static: true }) reviewsTrigger: ElementRef;
    @Input() public loadingImages: boolean;

    public reviewsData: Array<{text: string; name: string; position: string}> = [
        {
            text: 'Ha sido una buena forma de financiamiento, a nosotros nos ha ayudado harto porque la facilidad de obtención es super rápido, no es como con otras empresas que igual es engorroso el sistema.',
            name: 'Miguel Matías Villablanca',
            position: 'Servicios Generales Palva Spa'
        },
        {
            text: 'Yo como emprendedora le digo a las pymes que tomen este crédito sin miedo, es una ayuda que no te dan los bancos o te ponen muchos peros. Mi negocio está creciendo.',
            name: 'Juliana Torres',
            position: 'Papelería Juli'
        },
        {
            text: 'Gracias a este crédito hemos podido expandir nuestra oferta de productos y mejorar nuestras instalaciones, lo que ha resultado en un aumento significativo de nuestras ventas.',
            name: 'Nahuel Zamorano',
            position: 'Pizzería nápoles'
        },
        {
            text: 'TUU crédito me dio la oportunidad de poder crecer. Mi negocio era pequeño, ahora tengo dos sucursales y me va super bien la verdad.',
            name: 'Andreina Soto',
            position: 'Dueña, Andy Pets.'
        },
        {
            text: 'Gracias a Dios ha resultado bien, a como tenía el negocio mi papá, no es por nada, ahora está bastante grande comparado a como era antes y una de las cosas que ha ayudado, son los créditos con ustedes.',
            name: 'Jaime villanueva',
            position: 'Propietario, Almacén Dorys'
        },

    ];

    public reviewsBlock;
    public cards;
    public startAnimation = false;
    public timer = null;
    public count = 0;

    ngOnInit(): void {
        this.reviewsBlock = this.reviewsTrigger.nativeElement;
        this.cards = <HTMLCollectionOf<HTMLElement>>this.reviewsBlock.getElementsByClassName('card-ts');
    }

    ngAfterViewInit(): void {
        this.initAnimation();
    }

    public initAnimation(): void {
        if (window.innerWidth > 1200) {
            this.reviewsBlock.classList.remove('animate');
            this.cards[0].style.transform = 'translate3d(42%, 55%, 0px) rotateZ(-5deg)';
            this.cards[1].style.transform = 'translate3d(-10%, 10%, 0px) rotateZ(-8deg)';
            this.cards[2].style.transform = 'translate3d(-47%, 18%, 0px) rotateZ(9deg)';
            this.cards[3].style.transform = 'translate3d(60%, 3%, 0px)';
            this.cards[4].style.transform = 'translate3d(-16%, -34%, 0px) rotateZ(15deg)';
        }
    }

    public playAnimation(): void {
        if (!(('ontouchstart' in window) || (navigator.maxTouchPoints > 0))) {
            this.reviewsBlock.classList.add('animate');
            this.cards[0].style.transform = 'translate3d(0%, 0%, 0px) rotateZ(0)';
            this.cards[1].style.transform = 'translate3d(0%, 0%, 0px) rotateZ(0)';
            this.cards[2].style.transform = 'translate3d(0%, 0%, 0px) rotateZ(0)';
            this.cards[3].style.transform = 'translate3d(0%, 0%, 0px) rotateZ(0)';
            this.cards[4].style.transform = 'translate3d(0%, 0%, 0px) rotateZ(0)';
        }
    }
}
