import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

import { IPricingCard } from './pricing-card/pricing-card.component';
import { DATA_CARD_PRICE } from './data-price';
import { urlMapping } from '../route-ids';
import { Router } from '@angular/router';

@Component({
    selector: 'app-section-price',
    templateUrl: './section-price.component.html',
    styleUrls: ['./section-price.component.scss']
})
export class SectionPriceComponent implements OnInit {
    @Input() public loadingImages: boolean;
    @Input() public dataCard: any;
    @Input() public onlyBoleta: boolean = false;
    @Input() public heading?: { title: string; subtitle: string; };
    @Input() public isDirect: boolean;
    @Input() public idTestAB?: string = '';
    isPurchaseActive = true;

    public cardsTab1: Array<IPricingCard> = DATA_CARD_PRICE;

    public cardTab2: IPricingCard = {
        device: 'device-p2-mini',
        features: [
            {
                icon: 'icon-chip-tuu',
                name: 'Chip de <br>Internet'
            }
        ],
        title: 'Mini',
        price: 39900,
        button: {
            id: 'TUU-mini-H-precios-compra-comision',
            text: 'Comprar',
            path: '/comprar-mini',
            queryParams: true
        },
        list: [
            {
                icon: 'icon-sprite-card-atm',
                text: '<b>Acepta tarjetas</b> con banda</br> magnética, chip y sin contacto.'
            },
            {
                icon: 'icon-sprite-file',
                text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
            },
            {
                icon: 'icon-sprite-sim-card',
                text: '<b>Chip de internet</b> con gigas libres.'
            },
            {
                icon: 'icon-sprite-battery',
                text: '<b>Batería</b> de larga duración.'
            }
        ]
    }

    public dataCommissions = [
        {
            days: 1,
            cards: [
                {
                    commission: '1,49',
                    saving: '0,20'
                },
                {
                    commission: '0,79',
                    extra: 65,
                    before: '1,99'

                },
            ]
        },
        {
            days: 2,
            cards: [
                {
                    commission: '1,47',
                    saving: '0,22'
                },
                {   
                    commission: '0,77',
                    saving: '0,02',
                    extra: 65,
                    before: '1,96'

                },
            ]
        },
        {
            days: 5,
            cards: [
                {
                    //before:'1,71',
                    commission: '1,41',
                    saving: '0,28'
                },
                {
                    commission: '0,71',
                    saving: '0,08',
                    extra: 65,
                    before: '1,87'

                }
            ]
        },
        {
            days: 10,
            cards: [
                {
                    commission: '1,29',
                    saving: '0,38'
                },
                {
                    commission: '0,59',
                    saving: '0,2',
                    extra: 65,
                    before: '1,61'

                }
            ]
        },
        {
            days: 15,
            cards: [
                {
                    commission: '1,17',
                    saving: '0,59'
                },
                {
                    commission: '0,47',
                    saving: '0,32',
                    extra: 65,
                    before: '1,40'

                }
            ]
        },
        {
            days: 25,
            cards: [
                {
                    commission: '0,94',
                    saving: '1,01'
                },
                {
                    commission: '0',
                    saving: '0,79',
                    extra: 65,
                    before: '0,98'

                }
            ]
        }
    ];

    public url: string;
    public tourView: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private _router: Router
    ) { }

    public ngOnInit(): void {
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
    }

    public scrollTop(): void {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

}
