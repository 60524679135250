import { Component } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';
import { Router } from '@angular/router';

@Component({
    selector: 'app-start-accepting-payments',
    templateUrl: './start-accepting-payments.component.html',
    styleUrls: ['./start-accepting-payments.component.scss'],
})
export class StartAcceptingPaymentsComponent {

    constructor(
        private router: Router,
        public buttonTraceService: ButtonTraceService,
        public scrollToService: ScrollToService
    ) {
    }

    public showPosHome(): void {
        this.router.navigate(['/']).then(() => {
            window.scroll(0, 0);
            setTimeout(() => {
                this.scrollToService.scrollToSection("sectionPrice");
            }, 500);
        });
        this.scrollToService.scrollToSection("sectionPrice");
    }
}
