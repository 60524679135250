import { Component } from '@angular/core';
import { ScrollToService } from '../../shared/services/scroll-to';

@Component({
    selector: 'app-quotas-first-screen',
    templateUrl: './quotas-first-screen.component.html',
    styleUrls: ['./quotas-first-screen.component.scss']
})
export class QuotasFirstScreenComponent {

    constructor(
        private scrollToService: ScrollToService
    ) {
    }

    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
    }

}
