import { Component } from '@angular/core';
import { ScrollToService } from '../../shared/services/scroll-to';
@Component({
    selector: 'app-how-works-parking',
    templateUrl: './how-works-parking.component.html',
    styleUrls: ['./how-works-parking.component.scss']
})
export class HowWorksParkingComponent {

    public list = [
        {
            icon: 'icon-sprite-credit-card-search',
            text: 'Ingresa el número de patente y selecciona el tipo de vehículo: auto, moto o camión.'
        },
        {
            icon: 'icon-sprite-paint-brush',
            text: 'Registra información adicional como marca o color.'
        },
        {
            icon: 'icon-sprite-file-check',
            text: 'Imprime el ticket para su seguimiento.'
        },
        {
            icon: 'icon-sprite-qrcode-scan',
            text: 'Escanéa el voucher a la salida del vehículo, acepta el pago y ¡Listo!'
        }
    ];

    constructor(
        public scrollToService: ScrollToService
    ) {
    }
    
}
