import { Component, OnInit } from '@angular/core';
import {  urlEnv } from '../../../environments/environment';
import { DataService } from '../../shared/services/data.service';

interface Option {
    id: number;
    icon?: string;
    preview?: Object;
    title: string;
    price: number;
    actionText?: string;
    tooltip?: string;
    count: number;
    maxCount: number;
    payment: string;
    isChecked: boolean;
    isDisabled: boolean;
}
interface PlanOptions {
    id: number;
    title?: string;
    note?: string;
    options: Array<Option>;
}
@Component({
    selector: 'app-supplies-section',
    templateUrl: './supplies-section.component.html',
    styleUrls: ['./supplies-section.component.scss']
})
export class SuppliesSectionComponent implements OnInit {

    public  urlEnv =  urlEnv;


    public optionsArr: Array<PlanOptions> = [
        // {
        //     id: 1,
        //     note: 'Despacho GRATUITO',
        //     options: [
        //         {
        //             id: 1,
        //             preview: {
        //                 image: 'pos',
        //                 list: [
        //                     {
        //                         text: '<b>Acepta tarjetas</b> con banda magnética, chip y Contactless®.'
        //                     },
        //                     {
        //                         text: '<b>Impresora térmica</b> integrada de alta velocidad.'
        //                     },
        //                     {
        //                         text: '<b>Conexión Wifi</b> y soporte Bluetooth.'
        //                     },
        //                     {
        //                         text: 'Batería de <b>12hrs de duración.</b>'
        //                     },
        //                     {
        //                         text: '+ Chip <b>Gigas libres.</b>'
        //                     },
        //                     {
        //                         text: '5 Rollos de papel térmico <b>Gratis</b>'
        //                     }
        //                 ],
        //             },
        //             title: 'TUU máquina POS',
        //             price: 79900,
        //             actionText: 'Ver Terminal',
        //             count: 0,
        //             maxCount: 20,
        //             payment: 'Pago único + IVA',
        //             isChecked: false,
        //             isDisabled: false
        //         }
        //     ]
        // },
        // {
        //     id: 2,
        //     title: 'Hardware',
        //     note: 'Despacho GRATUITO',
        //     options: [
        //         {
        //             id: 1,
        //             icon: 'icon-sprite-pistol',
        //             title: 'Pistola código de barra Bluetooth (Sin Stock)',
        //             price: 50000,
        //             actionText: 'Ver Pistola',
        //             count: 0,
        //             maxCount: 20,
        //             payment: 'Pago único + IVA',
        //             isChecked: false,
        //             isDisabled: true
        //         },
        //         {
        //             id: 2,
        //             icon: 'icon-sprite-print',
        //             title: 'Impresora térmica bluetooth 58mm',
        //             price: 40000,
        //             actionText: 'Ver Impresora',
        //             count: 0,
        //             maxCount: 20,
        //             payment: 'Pago único + IVA',
        //             isChecked: false,
        //             isDisabled: false
        //         }
        //     ]
        // },
        {
            id: 3,
            title: 'Rollos',
            note: 'Despacho GRATUITO',
            options: [
                {
                    id: 1,
                    icon: 'icon-sprite-rolls2',
                    title: 'Rollos de papel térmico',
                    price: 8500,
                    count: 0,
                    maxCount: 20,
                    payment: 'Pago único + IVA',
                    isChecked: false,
                    isDisabled: false
                },
                {
                    id: 2,
                    icon: 'icon-sprite-rolls',
                    title: 'Rollos de papel térmico',
                    price: 19900,
                    count: 0,
                    maxCount: 20,
                    payment: 'Pago único + IVA',
                    isChecked: false,
                    isDisabled: false
                }, {
                    id: 3,
                    icon: 'icon-sprite-rolls',
                    title: 'Rollos de papel térmico',
                    price: 37900,
                    count: 0,
                    maxCount: 20,
                    payment: 'Pago único + IVA',
                    isChecked: false,
                    isDisabled: false
                }
                // , {
                //     id: 4,
                //     icon: 'icon-sprite-rolls',
                //     title: 'Rollos de papel térmico',
                //     price: 37900,
                //     count: 0,
                //     maxCount: 20,
                //     payment: 'Pago único + IVA',
                //     isChecked: false,
                //     isDisabled: false
                // }, {
                //     id: 5,
                //     icon: 'icon-sprite-rolls',
                //     title: 'Rollos de papel térmico (Sin stock)',
                //     price: 66000,
                //     count: 0,
                //     maxCount: 20,
                //     payment: 'Pago único + IVA',
                //     isChecked: false,
                //     isDisabled: true
                // }
            ]
        },
        {
            id: 4,
            title: 'Accesorios',
            note: 'DESPACHO GRATUITO',
            options:[
                {
                    id: 1,
                    icon : 'icon-sprite-shield',
                    title: 'Funda de silicona para SE',
                    price : 8000,
                    count: 0,
                    maxCount: 20,
                    isChecked: false,
                    isDisabled: false,
                    payment: 'Pago único + IVA',
                },
                {
                    id: 2,
                    icon : 'icon-sprite-screen',
                    title: 'Lámina protectora de pantalla (Sin Stock)',
                    price : 4000,
                    count: 0,
                    maxCount: 20,
                    isChecked: false,
                    isDisabled: true,
                    payment: 'Pago único + IVA',
                }
            ]
        },
        {
            id: 5,
            title: 'Firma electrónica',
            options: [
                {
                    id: 1,
                    icon: 'icon-sprite-feather',
                    title: 'Firma electrónica simple por 1 año',
                    price: 11790,
                    actionText: '¿Qué es la firma electrónica?',
                    tooltip: 'La Firma Electrónica es lo mismo que el Certificado Digital, son dos formas distintas de llamar a los datos asociados a un documento electrónico que permiten asegurar su integridad e identificar al firmante',
                    count: 0,
                    maxCount: 20,
                    payment: 'Pago único + IVA',
                    isChecked: false,
                    isDisabled: false
                }, {
                    id: 2,
                    icon: 'icon-sprite-feather',
                    title: 'Firma electrónica simple por 2 años',
                    price: 16790,
                    count: 0,
                    maxCount: 20,
                    payment: 'Pago único + IVA',
                    isChecked: false,
                    isDisabled: false
                }, {
                    id: 3,
                    icon: 'icon-sprite-feather',
                    title: 'Firma electrónica simple por 3 años',
                    price: 19790,
                    count: 0,
                    maxCount: 20,
                    payment: 'Pago único + IVA',
                    isChecked: false,
                    isDisabled: false
                }
            ]
        }
    ];

    public currentTariff = 0;

    public startPath = `${this.urlEnv.TUU_CART_URL}subproduct=`;
    public qtyQueryParams = '&qtyproduct=';
    public tariffQueryParams = '';
    private countSelection = 0;

    public showImage1 = false;

    constructor(
        private dataService: DataService
    ) { }

    ngOnInit(): void {
        // this.dataService.isHardwareSelected$.subscribe(ans => {
        //     this.checkRolls(ans);
        // });
    }

    public checkRolls(answer: boolean) {
        if (!answer) {
            this.countSelection++;
            this.optionsArr[1].options[0].isDisabled = answer; // *This option is 20 Rollos
        } else {
            this.countSelection--;
        }
        if (this.countSelection === 0) {
            this.optionsArr[1].options[0].isDisabled = answer; // *This option is 20 Rollos
            this.optionsArr[1].options[0].count = 0;
            this.optionsArr[1].options[0].isChecked = false;
        }
    }

    public getCurrentUrl(): string {
        let url: string;
        let subproductsUrl = '';
        let flag = true;

        // start get subproducts
        const subProductsString = this.tariffQueryParams + this.getOptionsGueryParams();

        const subproducts = (subProductsString).split(',');
        if (subproducts[0] === '') {
            subproducts.shift();
        }
        subproducts.forEach(element => {
            if (element === subproducts[0]) {
                subproductsUrl = element;
            } else if (subproductsUrl !== '') {
                subproductsUrl = subproductsUrl + ',' + element;
            }
        });

        url = this.startPath + subproductsUrl + this.qtyQueryParams;
        // end get subproducts

        this.optionsArr.forEach(obj => {
            const checkedCards = obj.options.filter(option => option.isChecked === true);
            if (checkedCards) {
                checkedCards.forEach(checkedCard => {
                    if (this.tariffQueryParams === '' && checkedCard.title === 'TUU máquina POS') {
                        flag = false;
                        url = url + checkedCard.count + ',' + checkedCard.count;
                    } else if (this.tariffQueryParams === '' && flag) {
                        flag = false;
                        url = url + checkedCard.count;
                    } else if (checkedCard.title === 'TUU máquina POS') {
                        url = url + ',' + checkedCard.count + ',' + checkedCard.count;
                    } else if (checkedCard.count > 0) {
                        url = url + ',' + checkedCard.count;
                    }
                });
            }
        });

        return url;
    }

    public getOptionsGueryParams(): string {
        let optionsQueryParams = '';
        this.optionsArr.forEach(obj => {
            const checkedCards = obj.options.filter(option => option.isChecked === true);

            if (checkedCards) {
                checkedCards.forEach(checkedCard => {
                    if (checkedCard.count > 0) {
                        if (checkedCard.title === 'TUU máquina POS') {
                            optionsQueryParams = optionsQueryParams + ',Terminal,CG';
                        }
                        if (checkedCard.title === 'Pistola código de barra Bluetooth') {
                            optionsQueryParams = optionsQueryParams + ',L';
                        }
                        if (checkedCard.title === 'Impresora térmica bluetooth 58mm') {
                            optionsQueryParams = optionsQueryParams + ',I';
                        }
                        if (checkedCard.title === 'Rollos de papel térmico' && checkedCard.id === 1) {
                            optionsQueryParams = optionsQueryParams + ',20RO';
                        }
                        if (checkedCard.title === 'Rollos de papel térmico' && checkedCard.id === 2) {
                            optionsQueryParams = optionsQueryParams + ',50RO';
                        }
                        if (checkedCard.title === 'Rollos de papel térmico' && checkedCard.id === 3) {
                            optionsQueryParams = optionsQueryParams + ',100RO';
                        }
                        // if (checkedCard.title === 'Rollos de papel térmico' && checkedCard.id === 4) {
                        //     optionsQueryParams = optionsQueryParams + ',120RO';
                        // }
                        if (checkedCard.title === 'Rollos de papel térmico' && checkedCard.id === 5) {
                            optionsQueryParams = optionsQueryParams + ',RO';
                        }
                        if(checkedCard.title === 'Funda de silicona para SE') {
                            optionsQueryParams = optionsQueryParams + ',FUNDAP2SE'
                        }
                        if(checkedCard.title === 'Lámina protectora de pantalla') {
                            optionsQueryParams = optionsQueryParams + ',LAMINA'
                        }
                        if (checkedCard.title === 'Firma electrónica simple por 1 año') {
                            optionsQueryParams = optionsQueryParams + ',F1';
                        }
                        if (checkedCard.title === 'Firma electrónica simple por 2 años') {
                            optionsQueryParams = optionsQueryParams + ',F2';
                        }
                        if (checkedCard.title === 'Firma electrónica simple por 3 años') {
                            optionsQueryParams = optionsQueryParams + ',F3';
                        }
                    }
                });
            }
        });
        return optionsQueryParams;
    }

    public getTotalPrice(): number {
        let price = 0;
        this.optionsArr.forEach(obj => {
            obj.options.forEach(item => {
                if (item.isChecked && item.count > 0) {
                    price = price + (item.price * item.count);
                }
            });
        });
        return price;
    }

    public getTotalAnnually(): number {
        let priceAnnually = 0;
        this.optionsArr.forEach(obj => {
            obj.options.forEach(item => {
                if (item.isChecked && item.count > 0 && !item.payment.includes('Pago único + IVA')) {
                    priceAnnually = priceAnnually + (item.price * item.count);
                }
            });
        });
        return priceAnnually;
    }
}
