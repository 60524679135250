import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';

import { TestABService } from '../shared/services/testAB.service';

@Component({
    selector: 'app-boleta-electronica',
    templateUrl: './boleta-electronica.component.html',
    styleUrls: ['./boleta-electronica.component.scss']
})
export class BoletaElectronicaComponent implements OnInit {
    public urlEnv = urlEnv;

    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    public idTestAB: string;
    public isDefault: boolean;
    public isTestABReady: boolean = false;

    public dataPriceSection = {
        buttons: [
            {
                id: 'TUU-B-precios-compra-simple'
            },
            {
                id: 'TUU-B-precios-compra-con-complementos'
            }
        ]
    };

    public itemsFAQ = [
        {
            title: '¿Hay un límite en la cantidad de documentos a emitir?',
            text: 'No existen límites en la cantidad de emisión de documentos.'
        }, {
            title: '¿Cuánto tiempo tarda la activación de boletas?',
            text: 'La activación depende de la situación de tu negocio con el SII, que, de ser la mejor, puede tardar hasta 2 días hábiles, en casos contrarios hasta 15 días hábiles. Pero siempre estamos sujetos al tiempo de respuesta del SII.'
        }, {
            title: '¿Puedo emitir boletas afectas y exentas?',
            text: 'Por supuesto que puedes emitir boletas tanto afectas como exentas, solo dependerá de tu categoría como contribuyente del SII y que tengas habilitados estos documentos.'
        }, {
            title: '¿Puedo conectar vía wifi mi dispositivo?',
            text: 'Si, el dispositivo se puede conectar a internet vía wifi o a través de un chip con internet móvil.'
        }, {
            title: '¿Qué máquinas TUU pueden emitir boletas y facturas electrónicas?',
            text: 'Todos los modelos de máquinas TUU están habilitados para emitir boletas y facturas electrónicas'
        }, {
            title: '¿Qué es TUU+?',
            text: 'Es una licencia avanzada de nuestro servicio que permite emitir documentos de forma masiva, integrarse con otros sistemas vía API, entre otras funcionalidades. Puedes usarla tanto para emisión online o directo desde TUU POS. Los clientes que usen TUU sólo para emitir documentos tributarios deberán contratar este plan.'
        }
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService,
        private seoService: SEOService,
        private testABService: TestABService
    ) {
        testABService.getTestAB().subscribe({
            next: result => {
                this.idTestAB = result;
                this.isDefault = testABService.checkIfDef();
                this.isTestABReady = true;
            }
        });
     }

    ngOnInit(): void {
        this._setMeta();
        this.seoService.updateCanonicalUrl('boleta-electronica/');

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('Emite boletas electrónicas ilimitadas para tu negocio con TUU.');
        this._meta.setTag('description', 'Emite boleta electrónica, sin límite de documentos al mes. Recibe además, en el mismo POS, pagos con tarjetas de crédito y débito, crea catálogo de productos y controla tu inventario.');
        this._meta.setTag('keywords', 'boleta electrónica sii, boleta digital, boleta online, máquina boleta electrónica, sistema boleta electrónica.');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-boleta-electronica.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Emite boletas electrónicas ilimitadas para tu negocio con TUU.');
    }

}
