import { Component, Input} from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';
@Component({
    selector: 'app-section-call-to-buy',
    templateUrl: './section-call-to-buy.component.html',
    styleUrls: ['./section-call-to-buy.component.scss']
})
export class SectionCallToBuyComponent {
    @Input() public loadingImages: boolean;

    public list = [
        {
            text: 'No pagas <b>costos de mantención.</b>'
        },
        {
            text: 'No pagas <b>costos de arriendo.</b>'
        },
        {
            text: 'No pagas <b>mensualidad de servicio.</b>'
        },
        {
            text: 'No haces <b>contratos largos.</b>'
        },
        {
            text: 'No necesitas <b>una gran inversión</b>'
        }
    ];

    constructor(
        private buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) {
    }

    public navigateToSection(className: string, event: Event): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
        this.buttonTraceService.traceButton(event, 'scroll');
    }
}
