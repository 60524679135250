import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { StripComponent } from "./promotions/strip/strip.component";
import { DialogPromoComponent } from "./promotions/dialog-promo/dialog-promo.component";
import { CustomMaterialModule } from "./material.module";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { ScrollprogressDirective } from './shared/directives/scrollprogress.directive';
import { AnimationButtonDirective } from './shared/directives/animation-button.directive';

@NgModule({
    imports: [
        CommonModule,
        IconSpriteModule.forRoot({
            path: 'assets/images/sprites/sprite.svg'
        }),
        CustomMaterialModule
    ],
    declarations: [
        StripComponent,
        DialogPromoComponent,
        ScrollprogressDirective,
        AnimationButtonDirective
    ],
    exports: [
        StripComponent,
        DialogPromoComponent,
        CustomMaterialModule,
        ScrollprogressDirective,
        AnimationButtonDirective
    ]
})

export class WebsiteCommonModule{}