import { Component } from '@angular/core';
import { ScrollToService } from '../../shared/services/scroll-to';

@Component({
    selector: 'app-banner-cyber-day',
    templateUrl: './banner-cyber-day.component.html',
    styleUrls: ['./banner-cyber-day.component.scss']
})
export class BannerCyberDayComponent {

    public value = 'TUUCYBER';

    constructor(
        public scrollToService: ScrollToService
    ) {
    }


    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
    }

}
