import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollToService {
  constructor() {
  }

  scrollToSection(className: string, speed: number = 300, offsetTop: number = null) {
    const targetSection = document.getElementsByClassName(className)[0] as HTMLElement;

    if (!targetSection) {
      return;
    }

    const targetBounds = targetSection.getBoundingClientRect();
    const isTargetInViewport = targetBounds.top > 56 && targetBounds.bottom < window.innerHeight;

    if (!(isTargetInViewport && targetBounds.top <= window.innerHeight / 4)) {
      this.scrollTo(targetSection.offsetTop, speed, offsetTop);
    }
  }

  private scrollTo(to: number, duration: number, offsetTop: number) {
    const start = window.pageYOffset || document.documentElement.scrollTop || 0;
    const headerHeight = 56;
    const change = offsetTop === null ? to - start - headerHeight : offsetTop;
    let currentTime = 0;
    const increment = 20;

    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      currentTime += increment;
      window.scrollTo(0, easeInOutQuad(currentTime, start, change, duration));

      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }
}