import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { VERSION } from '../environments/environment';

import { registerLocaleData } from '@angular/common';
import localeCL from '@angular/common/locales/es-419';
registerLocaleData(localeCL);

import { MetaModule, MetaService } from '@ngx-meta/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { DeferLoadModule } from '@trademe/ng-defer-load';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// shared
import { HeaderComponent } from './shared/header/header.component';
import { MobileMenuComponent } from './shared/header/mobile-menu/mobile-menu.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ButtonWhatsapp } from './shared/button-whatsapp/button-whatsapp';

// Pages and sections
import { HomePageComponent } from './home-page/home-page.component';
// import { SectionBenefitsComponent } from './home-page/section-benefits/section-benefits.component';
import { SectionWarrantyComponent } from './home-page/section-warranty/section-warranty.component';
import { SectionCallToBuyComponent } from './home-page/section-call-to-buy/section-call-to-buy.component';
// import { SectionPaymentTabsComponent } from './home-page/section-payment-tabs/section-payment-tabs.component';
import { SectionHowItWorksComponent } from './home-page/section-how-it-works/section-how-it-works.component';
import { SectionFaqComponent } from './shared/section-faq/section-faq.component';
import { SectionSendEmailComponent } from './home-page/section-send-email/section-send-email.component';
import { SectionSalesComponent } from './home-page/section-sales/section-sales.component';
import { SectionSupportComponent } from './shared/section-support/section-support.component';
import { SectionSalesOverComponent } from './home-page/section-sales-over/section-sales-over.component';
import { SectionOffersFreeComponent } from './home-page/section-offers-free/section-offers-free.component';
import { BannerCyberDayComponent } from './home-page/banner-cyber-day/banner-cyber-day.component';
import { HomeFirstScreenComponent } from './home-page/home-first-screen/home-first-screen.component';

/** NOTE: the components below are not used in the project,
 * but are not yet removed (temporarily, in case need to be return them)
*/
// import { SectionComparisonsPosComponent } from './home-page/section-comparisons-pos/section-comparisons-pos.component';
// import { SectionAdditionsComponent } from './home-page/section-additions/section-additions.component';
// import { SmBusinessComponent } from './home-page/sm-business/sm-business.component';
/** End */

// support
import { ContactSupportComponent } from './contact-support/contact-support.component';

// contacts pages
import { ContactsComponent } from './contacts/contacts.component';
import { ContactWrapperComponent } from './contact-wrapper/contact-wrapper.component';
//import { ContactCompanyWrapperComponent } from './contact-company-wrapper/contact-company-wrapper.component';

// error
import { ErrorPageComponent } from './error-page/error-page.component';

// shared
import { SectionPriceComponent } from './shared/section-price/section-price.component';
import { PricingCardComponent } from './shared/section-price/pricing-card/pricing-card.component';
import { FeatureListComponent } from './shared/section-price/feature-list/feature-list.component';
import { CommissionComponent } from './shared/section-price/commission/commission.component';
import { CommissionFormComponent } from './shared/section-price/commission-form/commission-form.component';
import { SectionSubscriptionsComponent } from './shared/section-subscriptions/section-subscriptions.component';
import { PosMachineCapabilitiesComponent } from './shared/section-price/pos-machine-capabilities/pos-machine-capabilities.component';
import { ModalVideoComponent } from './shared/modal-video/modal-video.component';

// dialogs
import { DialogCustomMenuComponent } from './shared/dialogs/dialog-custom-menu/dialog-custom-menu.component';
import { DialogSeeProductComponent } from './shared/dialogs/dialog-see-product/dialog-see-product.component';
import { DialogSeeProduct2Component } from './shared/dialogs/dialog-see-product2/dialog-see-product2.component';
import { DialogCodeReaderComponent } from './shared/dialogs/dialog-code-reader/dialog-code-reader.component';
import { DialogSeeProduct3Component } from './shared/dialogs/dialog-see-product3/dialog-see-product3.component';
import { DialogInfoComponent } from './shared/dialogs/dialog-info/dialog-info.component';
import { DialogCouponsComponent } from './shared/dialogs/dialog-coupons/dialog-coupons.component';
import { DialogInstallmentPeriodComponent } from './shared/dialogs/dialog-installment-period/dialog-installment-period.component';
import { DialogPlansComponent } from './shared/dialogs/dialog-plans/dialog-plans.component';
import { DialogSinatureComponent } from './shared/dialogs/dialog-sinature/dialog-sinature.component';
import { DialogComissionComponent } from './shared/dialogs/dialog-comission/dialog-comission.component';

// pipe
import { CurrencyLocalePipe } from './currency.pipe';

// directives
import { MaskDirective } from './shared/input-mask';

// boleta-electronica
import { BoletaElectronicaComponent } from './boleta-electronica/boleta-electronica.component';
import { BoletaFirstScreenComponent } from './boleta-electronica/boleta-first-screen/boleta-first-screen.component';
import { SectionBenefitsBoletaComponent } from './boleta-electronica/section-benefits-boleta/section-benefits-boleta.component';
import { BoletaCallToActionComponent } from './boleta-electronica/boleta-call-to-action/boleta-call-to-action.component';
import { BoletaInfoSupportComponent } from './boleta-electronica/boleta-info-support/boleta-info-support.component';
import { SectionFreeEBillingComponent } from './boleta-electronica/section-free-e-billing/section-free-e-billing.component';
import { SectionComparisonsTerminalComponent } from './boleta-electronica/section-comparisons-terminal/section-comparisons-terminal.component';
import { SectionSIICertificationComponent } from './boleta-electronica/section-sii-certification/section-sii-certification.component';

// price-page
import { PricePageComponent } from './price-page/price-page.component';
import { PriceFirstScreenComponent } from './price-page/price-first-screen/price-first-screen.component';
import { SectionPlansComponent } from './price-page/section-plans/section-plans.component';
import { PlanCardsComponent } from './price-page/section-plans/plan-cards/plan-cards.component';
import { PlanListComponent } from './price-page/section-plans/plan-list/plan-list.component';
import { CardTotalComponent } from './shared/section-price/card-total/card-total.component';

// supplies-page
import { SuppliesPageComponent } from './supplies-page/supplies-page.component';
import { SuppliesFirstScreenComponent } from './supplies-page/supplies-first-screen/supplies-first-screen.component';
import { SuppliesSectionComponent } from './supplies-page/supplies-section/supplies-section.component';
import { SuppliesCardOptionsComponent } from './supplies-page/supplies-section/supplies-options/supplies-card-options.component';

// payment-page
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { PaymentFirstScreenComponent } from './payment-page/payment-first-screen/payment-first-screen.component';
import { PaymentLogosComponent } from './payment-page/payment-logos/payment-logos.component';
import { SectionPaymentMethodsComponent } from './payment-page/section-payment-methods/section-payment-methods.component';
import { PaymentCallToActionComponent } from './payment-page/payment-call-to-action/payment-call-to-action.component';
import { SectionPaymentSystemComponent } from './payment-page/section-payment-system/section-payment-system.component';
import { SectionCertificationComponent } from './payment-page/section-certification/section-certification.component';
import { SectionAboutPaymentComponent } from './payment-page/section-about-payment/section-about-payment.component';
import { SectionBuyTodayComponent } from './payment-page/section-buy-today/section-buy-today.component';

// catalog-page
import { CatalogPageComponent } from './catalog-page/catalog-page.component';
import { CatalogFirstScreenComponent } from './catalog-page/catalog-first-screen/catalog-first-screen.component';
import { CatalogCallToActionComponent } from './catalog-page/catalog-call-to-action/catalog-call-to-action.component';
import { SectionCatalogBenefitsComponent } from './catalog-page/section-catalog-benefits/section-catalog-benefits.component';
import { SectionCatalogComponent } from './catalog-page/section-catalog/section-catalog.component';
import { SectionTuuScannerComponent } from './catalog-page/section-tuu-scanner/section-tuu-scanner.component';
import { SectionSalesProcessComponent } from './catalog-page/section-sales-process/section-sales-process.component';

// inventory-page
import { InventoryPageComponent } from './inventory-page/inventory-page.component';
import { InventoryCallToActionComponent } from './inventory-page/inventory-call-to-action/inventory-call-to-action.component';
import { InventoryFirstScreenComponent } from './inventory-page/inventory-first-screen/inventory-first-screen.component';
import { SectionInventoryBenefitsComponent } from './inventory-page/section-inventory-benefits/section-inventory-benefits.component';
import { SectionTuuInventoryComponent } from './inventory-page/section-tuu-inventory/section-tuu-inventory.component';
import { SectionAdjustmentInventoryComponent } from './inventory-page/section-adjustment-inventory/section-adjustment-inventory.component';
import { SectionOrderReceptionComponent } from './inventory-page/section-order-reception/section-order-reception.component';

// pos-page
import { PosPageComponent } from './pos-page/pos-page.component';
import { PosSectionFirstScreenComponent } from './pos-page/pos-section-first-screen/pos-section-first-screen.component';
import { PosSectionBenefitsComponent } from './pos-page/pos-section-benefits/pos-section-benefits.component';
import { PosCallToActionComponent } from './pos-page/pos-call-to-action/pos-call-to-action.component';
import { PosTuuVsOthersComponent } from './pos-page/pos-tuu-vs-others/pos-tuu-vs-others.component';
import { PosManagementComponent } from './pos-page/pos-management/pos-management.component';

// partner-price-page
import { PartnerPricePageComponent } from './partner-price-page/partner-price-page.component';
import { PartnersPriceFirstScreenComponent } from './partner-price-page/partners-price-first-screen/partners-price-first-screen.component';
import { PartnersPlansComponent } from './partner-price-page/partners-plans/partners-plans.component';
import { PlansTableComponent } from './partner-price-page/partners-plans/plans-table/plans-table.component';

// codigo-amigo
import { FriendCodeComponent } from './friend-code/friend-code.component';
import { AllForMyFriendComponent } from './friend-code/all-for-my-friend/all-for-my-friend.component';
import { HalfAndHalfComponent } from './friend-code/half-and-half/half-and-half.component';
import { ErrorDialogComponent } from './friend-code/error-dialog/error-dialog.component';

// installment-payment
import { InstallmentPaymentComponent } from './installment-payment/installment-payment.component';
import { DialogWarnDesk } from './shared/dialogs/dialog-warn-desk/dialog-warn-desk.component';
import { DialogMachineCard } from './shared/dialogs/dialog-machine-card/dialog-machine-card.component';
import { SimComponent } from './sim/sim.component';

// resellers page
import { ResellersComponent } from './resellers/resellers.component';
import { FirstScreenResellersComponent } from './resellers/first-screen-resellers/first-screen-resellers.component';
import { ResellerOptionsComponent } from './resellers/reseller-options/reseller-options.component';
import { ResellerTrainingComponent } from './resellers/reseller-training/reseller-training.component';
import { StepsToResellerComponent } from './resellers/steps-to-reseller/steps-to-reseller.component';
import { SoftwareIntegrationComponent } from './resellers/software-integration/software-integration.component';

// Seguimiento page
import { SeguimientoPageComponent } from './seguimiento-page/seguimiento-page.component';
import { SeguimientoCertificacionPageComponent } from './seguimiento-page/seguimiento-certificacion/seguimiento-certificacion.component';
import { SeguimientoDespachoPageComponent } from './seguimiento-page/seguimiento-despacho/seguimiento-despacho.component';
import { SeguimientoGarantiaPageComponent } from './seguimiento-page/seguimiento-garantia/seguimiento-garantia.component';
import { SeguimientoReclamosPageComponent } from './seguimiento-page/seguimiento-reclamos/seguimiento-reclamos.component';
import { SeguimientoErrorPageComponent } from './seguimiento-page/seguimiento-error/seguimiento-error.component';
import { SeguimientoHesPageComponent } from './seguimiento-page/seguimiento-hes/seguimiento-hes.components';

// Quotas page
import { QuotasComponent } from './quotas/quotas.component';
import { QuotasFirstScreenComponent } from './quotas/quotas-first-screen/quotas-first-screen.component';
import { SectionMoreConvenientComponent } from './quotas/section-more-convenient/section-more-convenient.component';
import { SectionStatisticsComponent } from './quotas/section-statistics/section-statistics.component';
import { OfferForClientsComponent } from './quotas/offer-for-clients/offer-for-clients.component';
import { BenefitsInstallmentsComponent } from './quotas/benefits-installments/benefits-installments.component';
import { QuotasFaqComponent } from './quotas/quotas-faq/quotas-faq.component';
import { BenefitsForClientsComponent } from './quotas/benefits-for-clients/benefits-for-clients.component';
import { SliderQuotasComponent } from './quotas/benefits-for-clients/slider-quotas/slider-quotas.component';
import { QuotasLogosComponent } from './quotas/quotas-logos/quotas-logos.component';

// promotions
import { DialogPromoWrapperComponent } from './promotions/dialog-promo/dialog-promo.component';
import { StripWrapperComponent } from './promotions/strip/strip.component';
import { StripComponentTUU } from './promotions/strip/strip-tuu/strip.component';
import { DialogPromoTUUComponent } from './promotions/dialog-promo/dialog-promo.component-tuu.ts/dialog-promo-tuu.component';
import { WebsiteCommonModule } from '../../../common/common.module';
import { CustomMaterialModule } from '../../../common/material.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

// Plan & Products page
import { PlanPageComponent } from './plan-page/plan-page.component';
import { CardPlanComponent } from './plan-page/card-plan/card-plan.component';
//import { DialogInstructionComponent } from './plan-page/dialog-instruction/dialog-instruction.component';
import { ProductPageComponent } from './product-page/product-page.component';

// Parking page
import { ParkingComponent } from './parking/parking.component';
import { FirstScreenParkingComponent } from './parking/first-screen-parking/first-screen-parking.component';
import { ParkingBenefitsComponent } from './parking/parking-benefits/parking-benefits.component';
import { HowWorksParkingComponent } from './parking/how-works-parking/how-works-parking.component';
import { ParkingAdvantagesComponent } from './parking/parking-advantages/parking-advantages.component';
import { ParkingFaqComponent } from './parking/parking-faq/parking-faq.component';

// Reservation page
import { ReservationPageComponent } from './reservation-page/reservation-page.component';
import { ReservationFirstScreenComponent } from './reservation-page/reservation-first-screen/reservation-first-screen.component';
import { SectionReservationFaqComponent } from './reservation-page/section-reservation-faq/section-reservation-faq.component';
import { ReservationBenefitsComponent } from './reservation-page/reservation-benefits/reservation-benefits.component';
import { ReserveForEveryoneComponent } from './reservation-page/reserve-for-everyone/reserve-for-everyone.component';
import { PlansAndPricesComponent } from './reservation-page/plans-and-prices/plans-and-prices.component';
import { SectionReviewsComponent } from './shared/section-reviews/section-reviews.component';

// plans-reserva page
import { ReservationPlansPageComponent } from './reservation-plans-page/reservation-plans-page.component';
import { SectionReservationPlansComponent } from './reservation-plans-page/section-reservation-plans/section-reservation-plans.component';
import { ReservationPlansComponent } from './reservation-plans-page/section-reservation-plans/reservation-plans/reservation-plans.component';
import { SectionTryReservationComponent } from './reservation-plans-page/section-try-reservation/section-try-reservation.component';

//PopUp
import { LeavingPopupWrapperComponent } from '../../../common/leaving-popup/leaving-popup-wrapper/leaving-popup-wrapper.component';
import { PopUpComponent } from '../../../common/leaving-popup/leaving-popup.component.TUU/leaving-popup.component.TUU';
import { PopupReservaReleaseComponent } from './shared/dialogs/popup-reserva-release/popup-reserva-release.component';
import { PopUpPromo } from './shared/dialogs/popup-promo/popup-promo.component';

import { PopUpGeneralWrapper } from './pop-up-offer/wrapper/pop-up-offer.wrapper.component';
import { PopUpExterno } from './pop-up-offer/dialog/pop-up.component.TUU';

//New Home Page
//import { HomeFirstScreenVideoComponent } from './home-page/home-first-screen-video/home-first-screen.component';

//AppView
//import { SectionDemoViewComponent } from './home-page/section-demo-view/section-demo-view.component';
import { SectionTourPagoComponent } from './shared/tours/section-tour-pago/section-tour-pago.component';
import { SectionTourBoletaComponent } from './shared/tours/section-tour-boleta/section-tour-boleta.component';
import { SectionTourCatalogoComponent } from './shared/tours/section-tour-catalogo/section-tour-catalogo.component';
import { SectionTourInventarioComponent } from './shared/tours/section-tour-inventario/section-tour-inventario.component';
import { SectionTourPosComponent } from './shared/tours/section-tour-pos/section-tour-pos.component';

import { NewsFeedComponent } from './shared/news-feed/news-feed.component';

// new partner-page
import { PartnersPageComponent } from './partners-page/partners-page.component';
import { PartnersFirstScreenComponent } from './partners-page/partners-first-screen/partners-first-screen.component';
import { PartnerLogosComponent } from './partners-page/partner-logos/partner-logos.component';
import { SectionModelsComponent } from './partners-page/section-models/section-models.component';
import { SectionIncomeModeComponent } from './partners-page/section-income-mode/section-income-mode.component';
import { PartnersBenefitsComponent } from './partners-page/partners-benefits/partners-benefits.component';
import { SectionFaqPartnersComponent } from './partners-page/section-faq-partners/section-faq-partners.component';
import { SectionSupportPartnersComponent } from './partners-page/section-support-partners/section-support-partners.component';
import { SectionGeographyComponent } from './partners-page/section-geography/section-geography.component';
import { SectionAboutPartnershipComponent } from './partners-page/section-about-partnership/section-about-partnership.component';

// Experimento cuestionario web
//import { SectionQuestionaryViewComponent } from './home-page/section-questionary/section-questionary-view.component';

// SnackBar
import { SnackBar } from './shared/snack-bars/snack-bar.component';
// credit page
import { CreditPageComponent } from './credit-page/credit-page.component';
import { CreditFirstScreenComponent } from './credit-page/credit-first-screen/credit-first-screen.component';
import { CreditBenefitsComponent } from './credit-page/credit-benefits/credit-benefits.component';
import { HowToApplyComponent } from './credit-page/how-to-apply/how-to-apply.component';
import { HowToApplyMobileComponent } from './credit-page/how-to-apply-mobile/how-to-apply-mobile.component';
import { CreditInstructionsComponent } from './credit-page/credit-instructions/credit-instructions.component';
import { CreditSimulatorComponent } from './credit-page/credit-simulator/credit-simulator.component';
import { AllInOneComponent } from './shared/all-in-one/all-in-one.component';
import { StartAcceptingPaymentsComponent } from './credit-page/start-accepting-payments/start-accepting-payments.component';
import { CreditFAQComponent } from './credit-page/credit-FAQ/credit-FAQ.component';
import { CreditReviewsComponent } from './credit-page/credit-reviews/credit-reviews.component';
import { NewFooterComponent } from './shared/new-footer/new-footer.component';
//Redirect Page
import { RedirectPageComponent } from './redirect-page/redirect-page.component';
// Inmediate payment page
import { InmediatePaymentPageComponent } from './immediate-payment-page/inmediate-payment-page.component';
import { InmediatePaymentFirstScreenComponent } from './immediate-payment-page/inmediate-payment-first-screen/inmediate-payment-first-screen.component';
import { SectionHowToRequestComponent } from './immediate-payment-page/section-how-to-request/section-how-to-request.component';
import { SectionHowToRequestMobileComponent } from './immediate-payment-page/section-how-to-request-mobile/section-how-to-request-mobile.component';
import { SectionCostAndRestrictionsComponent } from './immediate-payment-page/section-costs-and-restrictions/section-costs-and-restrictions.component';
import { SectionInmediatePaymentFaqComponent } from './immediate-payment-page/section-inmediate-payment-faq/section-inmediate-payment-faq.component';
// DropDown Menu
import { DropdownMenuProductsComponent } from './shared/header/dropdown-menu/menus/products/dropdown-menu-products.component';
import { DropdownMenuFunctionsComponent } from './shared/header/dropdown-menu/menus/functions/dropdown-menu-functions.component';
import { DropdownMenuProgramsComponent } from './shared/header/dropdown-menu/menus/programs/dropdown-menu-programs.component';
// Mobile ChildMenu
import { MobileMenuSupportComponent } from './shared/header/mobile-menu/mobile-menu-support/mobile-menu-support.component';
import { MobileMenuChildComponent } from './shared/header/mobile-menu/mobile-menu-child/mobile-menu-child.component';
import { MobileMenuFunctionsComponent } from './shared/header/mobile-menu/mobile-menu-functions/mobile-menu-functions.component';
import { MobileMenuProgramsComponent } from './shared/header/mobile-menu/mobile-menu-programs/mobile-menu-programs.component';
//Error
import { ErrorLinkExpiredComponent } from './error-link-expired/error-link-expired.component';
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        MobileMenuComponent,
        FooterComponent,
        // contacts pages
        ContactsComponent,
        ContactWrapperComponent,
        //ContactCompanyWrapperComponent,
        ContactSupportComponent,
        // home page
        HomePageComponent,
        // SectionBenefitsComponent,
        SectionWarrantyComponent,
        // SectionComparisonsPosComponent,
        // SectionAdditionsComponent,
        // SmBusinessComponent,
        SectionCallToBuyComponent,
        // SectionPaymentTabsComponent,
        SectionHowItWorksComponent,
        SectionFaqComponent,
        SectionSendEmailComponent,
        ErrorPageComponent,
        SectionSalesComponent,
        SectionSupportComponent,
        SectionSalesOverComponent,
        SectionOffersFreeComponent,
        BannerCyberDayComponent,
        HomeFirstScreenComponent,
        DialogWarnDesk,
        DialogMachineCard,
        // shared
        SectionPriceComponent,
        CommissionComponent,
        CommissionFormComponent,
        PricingCardComponent,
        PosMachineCapabilitiesComponent,
        FeatureListComponent,
        SectionSubscriptionsComponent,
        ButtonWhatsapp,
        ModalVideoComponent,
        // dialogs
        DialogCustomMenuComponent,
        DialogSeeProductComponent,
        DialogSeeProduct2Component,
        DialogCodeReaderComponent,
        DialogSeeProduct3Component,
        DialogInfoComponent,
        DialogCouponsComponent,
        DialogInstallmentPeriodComponent,
        DialogPlansComponent,
        DialogSinatureComponent,
        DialogComissionComponent,
        // pipe
        CurrencyLocalePipe,
        // directives
        MaskDirective,
        // boleta-electronica
        BoletaElectronicaComponent,
        BoletaFirstScreenComponent,
        SectionBenefitsBoletaComponent,
        BoletaCallToActionComponent,
        BoletaInfoSupportComponent,
        SectionFreeEBillingComponent,
        SectionComparisonsTerminalComponent,
        SectionSIICertificationComponent,
        // price-page
        PricePageComponent,
        PriceFirstScreenComponent,
        SectionPlansComponent,
        PlanCardsComponent,
        PlanListComponent,
        CardTotalComponent,
        // supplies-page
        SuppliesPageComponent,
        SuppliesFirstScreenComponent,
        SuppliesSectionComponent,
        SuppliesCardOptionsComponent,
        // payment-page
        PaymentPageComponent,
        PaymentFirstScreenComponent,
        PaymentLogosComponent,
        SectionPaymentMethodsComponent,
        PaymentCallToActionComponent,
        SectionPaymentSystemComponent,
        SectionCertificationComponent,
        SectionAboutPaymentComponent,
        SectionBuyTodayComponent,
        // catalog-page
        CatalogPageComponent,
        CatalogFirstScreenComponent,
        CatalogCallToActionComponent,
        SectionCatalogBenefitsComponent,
        SectionCatalogComponent,
        SectionTuuScannerComponent,
        SectionSalesProcessComponent,
        // inventory-page
        InventoryPageComponent,
        InventoryCallToActionComponent,
        InventoryFirstScreenComponent,
        SectionInventoryBenefitsComponent,
        SectionTuuInventoryComponent,
        SectionAdjustmentInventoryComponent,
        SectionOrderReceptionComponent,
        // pos-page
        PosPageComponent,
        PosSectionFirstScreenComponent,
        PosSectionBenefitsComponent,
        PosCallToActionComponent,
        PosTuuVsOthersComponent,
        PosManagementComponent,
        // partner-price-page
        PartnerPricePageComponent,
        PartnersPriceFirstScreenComponent,
        PartnersPlansComponent,
        PlansTableComponent,
        // codigo-amigo
        FriendCodeComponent,
        AllForMyFriendComponent,
        HalfAndHalfComponent,
        ErrorDialogComponent,
        InstallmentPaymentComponent,
        SimComponent,
        // resellers page
        ResellersComponent,
        FirstScreenResellersComponent,
        ResellerOptionsComponent,
        ResellerTrainingComponent,
        StepsToResellerComponent,
        SoftwareIntegrationComponent,
        // Seguimiento
        SeguimientoPageComponent,
        SeguimientoCertificacionPageComponent,
        SeguimientoDespachoPageComponent,
        SeguimientoGarantiaPageComponent,
        SeguimientoReclamosPageComponent,
        SeguimientoErrorPageComponent,
        SeguimientoHesPageComponent,
        //Promos
        DialogPromoWrapperComponent,
        StripWrapperComponent,
        StripComponentTUU,
        DialogPromoTUUComponent,
        // Quotas page
        QuotasComponent,
        QuotasFirstScreenComponent,
        SectionMoreConvenientComponent,
        SectionStatisticsComponent,
        OfferForClientsComponent,
        BenefitsInstallmentsComponent,
        QuotasFaqComponent,
        BenefitsForClientsComponent,
        SliderQuotasComponent,
        QuotasLogosComponent,
        // Plan & Products page
        PlanPageComponent,
        CardPlanComponent,
        //DialogInstructionComponent,
        ProductPageComponent,
        // Parking page
        ParkingComponent,
        FirstScreenParkingComponent,
        ParkingBenefitsComponent,
        HowWorksParkingComponent,
        ParkingAdvantagesComponent,
        ParkingFaqComponent,
        //PopUp
        LeavingPopupWrapperComponent,
        PopUpComponent,
        PopUpGeneralWrapper,
        PopUpExterno,
        PopupReservaReleaseComponent,
        PopUpPromo,
        //New Home Page
        //HomeFirstScreenVideoComponent,
        //Demo View
        //SectionDemoViewComponent,
        SectionTourPagoComponent,
        SectionTourBoletaComponent,
        SectionTourCatalogoComponent,
        SectionTourInventarioComponent,
        SectionTourPosComponent,
        // Reservation page
        ReservationPageComponent,
        ReservationFirstScreenComponent,
        SectionReservationFaqComponent,
        ReservationBenefitsComponent,
        ReserveForEveryoneComponent,
        PlansAndPricesComponent,
        SectionReviewsComponent,
        // plans-reserva page
        ReservationPlansPageComponent,
        SectionReservationPlansComponent,
        ReservationPlansComponent,
        SectionTryReservationComponent,
        NewsFeedComponent,
        // new partner-page
        PartnersPageComponent,
        PartnersFirstScreenComponent,
        PartnerLogosComponent,
        SectionModelsComponent,
        SectionIncomeModeComponent,
        PartnersBenefitsComponent,
        SectionFaqPartnersComponent,
        SectionSupportPartnersComponent,
        SectionGeographyComponent,
        SectionAboutPartnershipComponent,
        // Experimento cuestionario web
        //SectionQuestionaryViewComponent,
        //SnackBar
        SnackBar,
        //Redirect Page
        RedirectPageComponent,
        // credit page
        CreditPageComponent,
        CreditFirstScreenComponent,
        CreditBenefitsComponent,
        HowToApplyComponent,
        HowToApplyMobileComponent,
        CreditInstructionsComponent,
        CreditSimulatorComponent,
        AllInOneComponent,
        StartAcceptingPaymentsComponent,
        CreditFAQComponent,
        CreditReviewsComponent,
        NewFooterComponent,
        // Inmediate payment page
        InmediatePaymentPageComponent,
        InmediatePaymentFirstScreenComponent,
        SectionHowToRequestComponent,
        SectionHowToRequestMobileComponent,
        SectionCostAndRestrictionsComponent,
        SectionInmediatePaymentFaqComponent,
        // DropDown Menu
        DropdownMenuProductsComponent,
        DropdownMenuFunctionsComponent,
        DropdownMenuProgramsComponent,
        // Mobile ChildMenu
        MobileMenuSupportComponent,
        MobileMenuChildComponent,
        MobileMenuFunctionsComponent,
        MobileMenuProgramsComponent,
        //Error
        ErrorLinkExpiredComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'TUU' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MetaModule.forRoot(),
        NgxCaptchaModule,
        CustomMaterialModule,
        DeferLoadModule,
        IconSpriteModule.forRoot({
            path: `/assets/images/sprites/sprite.svg?v=${VERSION.SPRITES_VERSION}`
        }),
        TransferHttpCacheModule,
        WebsiteCommonModule,
    ],
    providers: [
        MetaService,
        CurrencyLocalePipe,
    ],
    bootstrap: [AppComponent],
    exports: [
        MaskDirective,
    ]
})
export class AppModule { }
