import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

interface ISliderProps {
    parent: HTMLElement;
    slideMove: number;
    slideActive: number;
    itemWidth: number;
    startPosition: number;
    gap: number;
}

@Component({
    selector: 'app-how-to-apply',
    templateUrl: './how-to-apply.component.html',
    styleUrls: ['./how-to-apply.component.scss']
})
export class HowToApplyComponent implements AfterViewInit {
    @ViewChild('trigger') public trigger: ElementRef;
    @ViewChild('slider') public slider!: ElementRef;

    public currentTab = 0;
    public sliderArr = ['initial', 'your-offer', 'ready'];
    public section: HTMLElement;
    public sliderProps!: ISliderProps;

    private _container: HTMLElement | null = null;
    private _timer;
    private _visibleItems = 0;
    private _newPosition = 0;
    private _counterEnabled = false;
    private imagesLoadedCount = 0;
    private totalImages = this.sliderArr.length;
    private resizeTimeout;
    private previousWidth: number = window.innerWidth;

    @HostListener('window:scroll')
    onScroll(): void {
        if (isPlatformBrowser(this.platformId)) {
            this._initCounter();
        }
    }

    @HostListener('window:resize')
    onResize() {
        if (isPlatformBrowser(this.platformId)) {
            const currentWidth = window.innerWidth;

            // Comparar el ancho actual con el anterior
            if (currentWidth === this.previousWidth) {
                return; // No hacer nada si el ancho no ha cambiado
            }

            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(() => {
                this.currentTab = 0;
                this.initSlider();
                this._moveSlider(this.currentTab);
            }, 200);

            // Actualizar el ancho anterior
            this.previousWidth = currentWidth;
        }
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private cdRef: ChangeDetectorRef,
    ) { }

    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
        this.section = this.trigger.nativeElement as HTMLElement;

        if (isPlatformBrowser(this.platformId)) {
            this._initCounter();
        }
    }

    public onImageLoad(): void {
        this.imagesLoadedCount++;
        if (this.imagesLoadedCount === this.totalImages) {
            this.initSlider();
        }
    }

    public initSlider(): void {
        const windowWidth = window.innerWidth;

        this.sliderProps = {
            parent: this.slider.nativeElement,
            slideMove: 1,
            slideActive: 0,
            itemWidth: 0,
            startPosition: 0,
            gap: windowWidth > 993 ? 50 : (windowWidth > 671 ? 30 : 14),
        };

        this._container = this.sliderProps.parent.children[0] as HTMLElement;

        if (!this._container) {
            console.error("El contenedor principal no pudo ser encontrado.");
            return;
        }

        this._imagesLoaded().then(() => {
            this._removeClones();
            requestAnimationFrame(() => {
                this._calculateSliderDimensions();
            });
        });
    }

    private _calculateSliderDimensions(): void {
        if (!this._container) {
            //console.error("El contenedor no está inicializado.");
            return;
        }

        this.sliderProps.itemWidth = this._container.children[0].getBoundingClientRect().width;
        this._visibleItems = this.sliderProps.parent.getBoundingClientRect().width / (this.sliderProps.itemWidth + this.sliderProps.gap);
        this.sliderProps.slideActive = Math.ceil(this._visibleItems);

        this._addClone();

        this.sliderProps.startPosition = this.sliderProps.slideActive * (this.sliderProps.itemWidth + this.sliderProps.gap);
        this._container.children[this.sliderProps.slideActive]?.classList.add('active');

        this._container.style.columnGap = this.sliderProps.gap + 'px';
        this._container.style.width =
            (this.sliderProps.itemWidth * this._container.children.length) +
            (this.sliderProps.gap * (this._container.children.length - 1)) + 'px';
        this._container.style.transform = `translateX(-${this.sliderProps.startPosition}px)`;
        this._container.style.left = ((this.sliderProps.parent.getBoundingClientRect().width - this.sliderProps.itemWidth) / 2) + 'px';
    }

    private _imagesLoaded(): Promise<void> {
        if (!this._container) return Promise.resolve();

        const images = this._container.querySelectorAll('img');
        const promises = Array.from(images).map((img: HTMLImageElement) => {
            return new Promise<void>((resolve) => {
                if (img.complete) {
                    resolve();
                } else {
                    img.onload = () => resolve();
                    img.onerror = () => resolve();
                }
            });
        });
        return Promise.all(promises).then(() => {});
    }

    public selectedTab(index: number): void {
        this._clearCounter();
        this._moveSlider(index);
    }

    private _addClone(): void {
        if (!this._container) {
            //console.error("El contenedor no está inicializado.");
            return;
        }

        const originalItems = Array.from(this._container.children).filter(child => {
            return !child.classList.contains('cloneStart') && !child.classList.contains('cloneEnd');
        }) as HTMLElement[];

        const totalItems = originalItems.length;

        for (let i = 0; i < this.sliderProps.slideActive; i++) {
            const cloneToEnd = originalItems[i % totalItems].cloneNode(true) as HTMLElement;
            cloneToEnd.classList.add('cloneEnd');
            this._container.appendChild(cloneToEnd);

            const index = (totalItems - 1 - i + totalItems) % totalItems;
            const cloneToStart = originalItems[index].cloneNode(true) as HTMLElement;
            cloneToStart.classList.add('cloneStart');
            this._container.insertBefore(cloneToStart, this._container.firstChild);
        }
    }

    private _removeClones(): void {
        if (!this._container) {
            //console.error("El contenedor no está inicializado.");
            return;
        }

        const clones = this._container.querySelectorAll('.cloneStart, .cloneEnd');
        clones.forEach(clone => clone.remove());
    }

    public _moveSlider(activeIndex: number): void {
        if (!this._container) {
            //console.error("El contenedor no está inicializado.");
            return;
        }

        const previousActiveSlide = this._container.querySelector('.slider__item.active');
        if (previousActiveSlide) {
            previousActiveSlide.classList.remove('active');
        }

        this.currentTab = activeIndex;
        this.sliderProps.slideActive = Math.ceil(this._visibleItems) + activeIndex;

        this._newPosition = this.sliderProps.startPosition + ((this.sliderProps.itemWidth + this.sliderProps.gap) * activeIndex);

        const activeSlide = this._container.children[this.sliderProps.slideActive];
        if (activeSlide) {
            activeSlide.classList.add('active');
        } else {
            console.error(`El slide activo no existe en el índice ${this.sliderProps.slideActive}.`);
        }

        this._container.style.transform = `translateX(-${this._newPosition}px)`;
        this._container.style.transitionDuration = '.4s';
    }

    private _initCounter(): void {
        const sectionBounding = this.section.getBoundingClientRect();

        if ((sectionBounding.top <= window.innerHeight && sectionBounding.bottom >= 0)) {
            if (this._counterEnabled) {
                return;
            }
            this._startCounter();
        } else {
            this._clearCounter();
        }
    }

    private _startCounter(): void {
        this._timer = setInterval(() => {
            if (this.currentTab < this.sliderArr.length - 1) {
                this.currentTab++;
                this._moveSlider(this.currentTab);
            } else {
                setTimeout(() => {
                    this.currentTab = 0;
                    this._moveSlider(this.currentTab);
                }, 100);
            }
        }, 2000);
        this._counterEnabled = true;
    }

    private _clearCounter(): void {
        this._counterEnabled = false;
        clearInterval(this._timer);
    }
}
