import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { BoletaElectronicaComponent } from './boleta-electronica/boleta-electronica.component';
import { ContactWrapperComponent } from './contact-wrapper/contact-wrapper.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PricePageComponent } from './price-page/price-page.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { CatalogPageComponent } from './catalog-page/catalog-page.component';
import { InventoryPageComponent } from './inventory-page/inventory-page.component';
import { SuppliesPageComponent } from './supplies-page/supplies-page.component';
import { PosPageComponent } from './pos-page/pos-page.component';
import { PartnerPricePageComponent } from './partner-price-page/partner-price-page.component';
// import { FriendCodeComponent } from './friend-code/friend-code.component';
// import { AllForMyFriendComponent } from './friend-code/all-for-my-friend/all-for-my-friend.component';
// import { HalfAndHalfComponent } from './friend-code/half-and-half/half-and-half.component';
import { InstallmentPaymentComponent } from './installment-payment/installment-payment.component';
import { SimComponent } from './sim/sim.component';
import { ResellersComponent } from './resellers/resellers.component';
import { SeguimientoPageComponent } from './seguimiento-page/seguimiento-page.component';
import { QuotasComponent } from './quotas/quotas.component';
import { PlanPageComponent } from './plan-page/plan-page.component';
import { ProductPageComponent } from './product-page/product-page.component';
// import { ParkingComponent } from './parking/parking.component';
import { ReservationPageComponent } from './reservation-page/reservation-page.component';
import { ReservationPlansPageComponent } from './reservation-plans-page/reservation-plans-page.component';
import { PartnersPageComponent } from './partners-page/partners-page.component';
import { CreditPageComponent } from './credit-page/credit-page.component';
import { RedirectPageComponent } from './redirect-page/redirect-page.component';
import { InmediatePaymentPageComponent } from './immediate-payment-page/inmediate-payment-page.component';
import { ErrorLinkExpiredComponent } from './error-link-expired/error-link-expired.component';

const routes: Routes = [
    {
        path: '',
        component: HomePageComponent,
    }, {
        path: 'directa/.',
        component: HomePageComponent,
    }, {
        path: 'directa',
        redirectTo: 'directa/.',
        pathMatch: 'full'
    }, {
        path: 'caja-qr/.',
        redirectTo: '',
    }, {
        path: 'caja-qr',
        redirectTo: 'caja-qr/.',
        pathMatch: 'full'
    }, {
        path: 'contactar-con-ventas/.',
        component: ContactWrapperComponent,
    }, {
        path: 'contactar-con-ventas',
        redirectTo: 'contactar-con-ventas/.',
        pathMatch: 'full'
    }, {
        path: 'boleta-electronica/.',
        component: BoletaElectronicaComponent,
    }, {
        path: 'boleta-electronica',
        redirectTo: 'boleta-electronica/.',
        pathMatch: 'full'
    }, {
        path: 'contactar-atencion-cliente/.',
        component: ContactSupportComponent,
    }, {
        path: 'contactar-atencion-cliente',
        redirectTo: 'contactar-atencion-cliente/.',
        pathMatch: 'full'
    },{
        path: 'contactar-con-soporte',
        redirectTo: 'contactar-atencion-cliente/.',
        pathMatch: 'full'
    }, {
        path: 'contactar-con-soporte/.',
        redirectTo: 'contactar-atencion-cliente/.',
        pathMatch: 'full'
    },{
        path: 'precios/.',
        component: PricePageComponent,
    }, {
        path: 'precios',
        redirectTo: 'precios/.',
        pathMatch: 'full'
    }, {
        path: 'rollos-e-insumos/.',
        component: SuppliesPageComponent,
    }, {
        path: 'rollos-e-insumos',
        redirectTo: 'rollos-e-insumos/.',
        pathMatch: 'full'
    }, {
        path: 'pago/.',
        component: PaymentPageComponent,
    }, {
        path: 'pago',
        redirectTo: 'pago/.',
        pathMatch: 'full'
    }, {
        path: 'catalogo/.',
        component: CatalogPageComponent,
    }, {
        path: 'catalogo',
        redirectTo: 'catalogo/.',
        pathMatch: 'full'
    }, {
        path: 'inventario/.',
        component: InventoryPageComponent,
    }, {
        path: 'inventario',
        redirectTo: 'inventario/.',
        pathMatch: 'full'
    }, {
        path: 'punto-de-venta/.',
        component: PosPageComponent,
    }, {
        path: 'punto-de-venta',
        redirectTo: 'punto-de-venta/.',
        pathMatch: 'full'
    }, {
        path: 'partner/.',
        component: PartnersPageComponent,
    }, {
        path: 'partner',
        redirectTo: 'partner/.',
        pathMatch: 'full'
    }, {
        path: 'partner/precios/.',
        component: PartnerPricePageComponent,
    }, {
        path: 'partner/precios',
        redirectTo: 'partner/precios/.',
        pathMatch: 'full'
    }, {
        //     path: 'comprar-pro/.',
        //     component: InstallmentPaymentComponent,
        //     data: { name: "pro" },
        // }, {
        //     path: 'comprar-pro',
        //     redirectTo: 'comprar-pro/.',
        //     pathMatch: 'full'
        // }, {
        path: 'comprar-se/.',
        component: InstallmentPaymentComponent,
        data: { name: "se" },
    }, {
        path: 'comprar-se',
        redirectTo: 'comprar-se/.',
        pathMatch: 'full'
    }, {
        path: 'directa/comprar-se/.',
        component: InstallmentPaymentComponent,
        data: { name: "se" }
    }, {
        path: 'directa/comprar-se',
        redirectTo: 'directa/comprar-se/.',
        pathMatch: 'full'
    }, {
        path: 'comprar-desk/.',
        component: InstallmentPaymentComponent,
        data: { name: "desk" }
    }, {
        path: 'comprar-desk',
        redirectTo: 'comprar-desk/.',
        pathMatch: 'full'
    }, {
        path: 'comprar-pro-2/.',
        component: InstallmentPaymentComponent,
        data: { name: "pro-2" }
    }, {
        path: 'comprar-pro-2',
        redirectTo: 'comprar-pro-2/.',
        pathMatch: 'full'
    }, {
        path: 'comprar-mini',
        redirectTo: 'comprar-mini/.',
        pathMatch: 'full'
    }, {
        path: 'comprar-mini/.',
        component: InstallmentPaymentComponent,
        data: { name: "mini" },
    }, {
        path: 'directa/comprar-mini/.',
        component: InstallmentPaymentComponent,
        data: { name: "mini" },
    }, {
        path: 'directa/comprar-mini',
        redirectTo: 'directa/comprar-mini-s/.',
        pathMatch: 'full'
    }, {
        path: 'comprar-mini-s/.',
        component: InstallmentPaymentComponent,
        data: { name: "mini-s" },
    }, {
        path: 'comprar-mini-s',
        redirectTo: 'comprar-mini-s/.',
        pathMatch: 'full'
    }, {
        path: 'directa/comprar-mini-s/.',
        component: InstallmentPaymentComponent,
        data: { name: "mini-s" },
    }, {
        path: 'directa/comprar-mini-s',
        redirectTo: 'directa/comprar-mini-s/.',
        pathMatch: 'full'
    }, {
        path: 'directa/comprar-desk/.',
        component: InstallmentPaymentComponent,
        data: { name: "desk" },
    }, {
        path: 'directa/comprar-desk',
        redirectTo: 'directa/comprar-desk/.',
        pathMatch: 'full'
    }, {
        path: 'comprar-pro-semi/.',
        component: InstallmentPaymentComponent,
        data: { name: "pro-semi-nueva" },
    }, {
        path: 'comprar-pro-semi',
        redirectTo: 'comprar-pro-semi/.',
        pathMatch: 'full'
    }, {
        path: 'comprar-se-semi/.',
        component: InstallmentPaymentComponent,
        data: { name: "se-semi-nueva" },
    }, {
        path: 'comprar-se-semi',
        redirectTo: 'comprar-se-semi/.',
        pathMatch: 'full'
    }, {
        path: 'comprar-mini-semi/.',
        component: InstallmentPaymentComponent,
        data: { name: "mini-semi-nueva" },
    }, {
        path: 'comprar-mini-semi',
        redirectTo: 'comprar-mini-semi/.',
        pathMatch: 'full'
    }, {
        path: 'comprar-mini-s-semi/.',
        component: InstallmentPaymentComponent,
        data: { name: "mini-s-semi-nueva" },
    }, {
        path: 'comprar-mini-s-semi',
        redirectTo: 'comprar-mini-s-semi/.',
        pathMatch: 'full'
    }, {
        path: 'comprar-desk-semi/.',
        component: InstallmentPaymentComponent,
        data: { name: "desk-semi-nueva" },
    }, {
        path: 'comprar-desk-semi',
        redirectTo: 'comprar-desk-semi/.',
        pathMatch: 'full'
    },//Friend code is no longer used
    /*
    {
        // Friend Code is not for website
        path: 'codigo-amigo/.',
        component: FriendCodeComponent,
    }, {
        path: 'codigo-amigo',
        redirectTo: 'codigo-amigo/.',
        pathMatch: 'full'
    }, {
        path: 'codigo-amigo/todo-para-mi-amigo/.',
        component: AllForMyFriendComponent
    }, {
        path: 'codigo-amigo/todo-para-mi-amigo',
        redirectTo: 'codigo-amigo/todo-para-mi-amigo/.',
        pathMatch: 'full'
    }, {
        path: 'codigo-amigo/mitad-y-mitad/.',
        component: HalfAndHalfComponent
    }, {
        path: 'codigo-amigo/mitad-y-mitad',
        redirectTo: 'codigo-amigo/mitad-y-mitad/.',
        pathMatch: 'full'
    }, {
        path: 'codigo-amigo/salir/.',
        component: FriendCodeComponent
    }, {
        path: 'codigo-amigo/salir',
        redirectTo: 'codigo-amigo/salir/.',
        pathMatch: 'full'
    },
    */
    {
        path: 'sim/estado/.',
        component: SimComponent
    }, {
        path: 'sim/estado',
        redirectTo: 'sim/estado/.',
        pathMatch: 'full'
    }, {
        path: 'revendedores/.',
        component: ResellersComponent,
    }, {
        path: 'revendedores',
        redirectTo: 'revendedores/.',
        pathMatch: 'full'
    }, {
        path: 'seguimiento/.',
        component: SeguimientoPageComponent,
    }, {
        path: 'seguimiento',
        redirectTo: 'seguimiento/.',
        pathMatch: 'full'
    }, {
        path: 'cuotas-tuu/.',
        component: QuotasComponent,
    }, {
        path: 'cuotas-tuu',
        redirectTo: 'cuotas-tuu/.',
        pathMatch: 'full'
    }, {
        path: 'seleccion-plan',
        redirectTo: 'seleccion-plan/.',
        pathMatch: 'full'
    }, {
        path: 'seleccion-plan/.',
        component: PlanPageComponent,
    }, {
        path: 'directa/seleccion-plan/.',
        component: PlanPageComponent
    }, {
        path: 'directa/seleccion-plan',
        redirectTo: 'directa/seleccion-plan/.',
        pathMatch: 'full'
    }, {
        path: 'seleccion-producto',
        redirectTo: 'seleccion-producto/.',
        pathMatch: 'full'
    }, {
        path: 'seleccion-producto/.',
        component: ProductPageComponent,
    }, {
        path: 'contactar-venta-empresa/.',
        redirectTo: '/',
        pathMatch: 'full'
    }, {
        path: 'parking',
        redirectTo: '/',
        pathMatch: 'full'
        // }, {
        //     path: 'parking/.',
        //     component: ParkingComponent
    },
    {
        path: 'tuu-reserva/.',
        component: ReservationPageComponent
    },
    {
        path: 'tuu-reserva',
        redirectTo: 'tuu-reserva/.',
        pathMatch: 'full'
    },
    {
        path: 'tuu-reserva/planes/.',
        component: ReservationPlansPageComponent
    },
    {
        path: 'tuu-reserva/planes',
        redirectTo: 'tuu-reserva/planes/.',
        pathMatch: 'full'
    },
    {
        path: 'credito/.',
        component: CreditPageComponent
    },
    {
        path: 'credito',
        redirectTo: 'credito/.',
        pathMatch: 'full'
    },
    {
        path: 'abono-inmediato/.',
        component: InmediatePaymentPageComponent
    },
    {
        path: 'abono-inmediato',
        redirectTo: 'abono-inmediato/.',
        pathMatch: 'full'
    },
    {
        path: 're-direccion',
        component: RedirectPageComponent
    },
    {
        path: 'link-expired/.',
        component: ErrorLinkExpiredComponent
    },
    {
        path: 'link-expired',
        redirectTo: 'link-expired/.',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: ErrorPageComponent
    }
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
