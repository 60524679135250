import { Component, Inject, Input, OnChanges, PLATFORM_ID, Renderer2, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogComissionComponent } from '../../dialogs/dialog-comission/dialog-comission.component';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ButtonTraceService } from '../../../../../../common/services/button-trace';
import { urlMapping } from './../../../shared/route-ids';


@Component({
    selector: 'app-commission',
    templateUrl: './commission.component.html',
    styleUrls: ['./commission.component.scss']
})
export class CommissionComponent implements OnChanges {
    @Input() public loadingImages: boolean;
    @Input() public data: any;
    @Input() public currentPeriod: number = 0;
    @Input() isDirect:boolean;
    public url: string;
    public linkId: string;

    public dataCommissions = [
        {
            days: 1,
            cards: [
                {
                    commission: '1,49',
                    saving: '0,20'
                },
                {
                    commission: '0,79',
                    extra: 65,
                    before: '1,99'

                },
            ]
        },
        {
            days: 2,
            cards: [
                {
                    commission: '1,47',
                    saving: '0,22'
                },
                {   
                    commission: '0,77',
                    saving: '0,02',
                    extra: 65,
                    before: '1,96'

                },
            ]
        },
        {
            days: 5,
            cards: [
                {
                    //before:'1,71',
                    commission: '1,41',
                    saving: '0,28'
                },
                {
                    commission: '0,71',
                    saving: '0,08',
                    extra: 65,
                    before: '1,87'

                }
            ]
        },
        {
            days: 10,
            cards: [
                {
                    commission: '1,29',
                    saving: '0,38'
                },
                {
                    commission: '0,59',
                    saving: '0,2',
                    extra: 65,
                    before: '1,61'

                }
            ]
        },
        {
            days: 15,
            cards: [
                {
                    commission: '1,17',
                    saving: '0,59'
                },
                {
                    commission: '0,47',
                    saving: '0,32',
                    extra: 65,
                    before: '1,40'

                }
            ]
        },
        {
            days: 25,
            cards: [
                {
                    commission: '0,94',
                    saving: '1,01'
                },
                {
                    commission: '0',
                    saving: '0,79',
                    extra: 65,
                    before: '0,98'

                }
            ]
        }
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private renderer: Renderer2,
        public dialog: MatDialog,
        private router: Router,
        private buttonTraceService: ButtonTraceService
    ) { this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            const urlTree = this.router.parseUrl(this.router.url);
            const primarySegment = urlTree.root.children['primary'];
            const urlSegment = primarySegment?.segments[0]?.path || 'home';
            this.url = urlMapping[urlSegment] || urlSegment;
            this.linkId = 't-'+this.url+'-comision-';

            if (this.url === 'directa') {
                this.isDirect = true;
            }

        }});
     }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.dataCommissions = this.data;
        };
    }

    openModal(): void {
        this.dialog.open(DialogComissionComponent, {
            panelClass: 'dialogComission',
            width: '466px',
        });
    }

    public openTooltip(): void {
        this.renderer.setStyle(document.getElementById('hiringPlus'), 'visibility', 'visible');
        this.renderer.setStyle(document.getElementById('hiringPlus'), 'opacity', '1');
        this.renderer.setStyle(document.getElementById('hiringPlus'), 'z-index', '11');
        this.renderer.setStyle(document.getElementById('hiringPlus'), 'transform', 'scale(1)');
    }

    public closeTooltip(timeout: number): void {
        setTimeout(() => {
            this.renderer.removeStyle(document.getElementById('hiringPlus'), 'visibility');
            this.renderer.removeStyle(document.getElementById('hiringPlus'), 'opacity');
            this.renderer.removeStyle(document.getElementById('hiringPlus'), 'z-index');
            this.renderer.removeStyle(document.getElementById('hiringPlus'), 'transform');
        }, timeout);
    }

    public scrollTop(): void {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                window.scroll(0, 0);
            }, 200);
        }
    }

    public navigateTo(path: string, event: Event): void {
        this.buttonTraceService.traceButton(event, 'navigate');
        this.router.navigate([path]);
        this.scrollTop();
    }
}
