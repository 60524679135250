import { Component, Input } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';
import { Router } from '@angular/router';

@Component({
    selector: 'app-all-in-one',
    templateUrl: './all-in-one.component.html',
    styleUrls: ['./all-in-one.component.scss']
})
export class AllInOneComponent {
    @Input() public loadingImages: boolean;
    @Input() public source: string;

    constructor(
        private router: Router,
        public buttonTraceService: ButtonTraceService,
        public scrollToService: ScrollToService
    ) {
    }

    public showPosHome(): void {
        this.router.navigate(['/']).then(() => {
            window.scroll(0, 0);
            setTimeout(() => {
                this.scrollToService.scrollToSection("sectionPrice");
            }, 500);
        });
        this.scrollToService.scrollToSection("sectionPrice");
    }
}
