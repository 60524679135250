import { Component } from '@angular/core';
import { ButtonTraceService } from '../../../../../../common/services/button-trace';
import { urlEnv } from '../../../../environments/environment';

@Component({
    selector: 'app-dialog-coupons',
    templateUrl: './dialog-coupons.component.html',
    styleUrls: ['./dialog-coupons.component.scss']
})
export class DialogCouponsComponent {
    public urlEnv = urlEnv;

    public couponsData = [
        // {
        //     title: '0% de comisión',
        //     subtitle: 'Por 6 meses',
        //     tooltip: 'O hasta $10.000.000 aceptando pago con tarjetas.',
        //     desc: 'Recupera el 100% de la compra del terminal al recibir pagos con tarjeta con comisión 0%.',
        //     btnText: 'UTILIZAR CUPÓN',
        //     code: 'CEROCOMISION',
        //     href: 'https://www.tuu.cl/cart/contact?subproduct=TUU,Terminal,CG&qtyproduct=1,1,1&pr_code=CEROCOMISION',
        //     btnID: 'TUU-H-coupon-CEROCOMISION'
        // }, {
        //     title: '$50.000',
        //     subtitle: 'de descuento',
        //     desc: 'Compra la máquina TUU por </br> <b>$129.900 antes <span class="custom-line-through">$179.900</span>.</b>',
        //     btnText: 'UTILIZAR CUPÓN',
        //     code: 'TUU50',
        //     href: 'https://www.tuu.cl/cart/contact?subproduct=TUU,Terminal,CG&qtyproduct=1,1,1&pr_code=TUU50',
        //     btnID: 'TUU-H-coupon-TUU50'
        // },
        {
            title: 'Pruébalo por luca',
            subtitle: 'Por 30 días',
            tooltip: 'Configuración inmediata para que aceptes pagos con tarjetas.',
            desc: 'Prueba la máquina TUU por 30 días sin costos ni compromisos, con envío gratis a todo Chile.',
            btnText: 'UTILIZAR CUPÓN',
            code: 'TUUPRUEBA',
            href: `${this.urlEnv.TUU_CART_URL}subproduct=TUU,SE,CG&qtyproduct=1,1,1&pr_code=TUUPRUEBA`,
            btnID: 'TUU-H-coupon-TUUPRUEBA'
        }
    ];

    constructor(
        private buttonTraceService: ButtonTraceService
    ) { }

    public goToUrl(url: string, event: Event): void {
        this.buttonTraceService.traceButton(event, 'navigate');
        window.open(url, '_blank')
    }
}
