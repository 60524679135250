import { Component } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';
import { Router } from '@angular/router';
@Component({
    selector: 'app-reservation-first-screen',
    templateUrl: './reservation-first-screen.component.html',
    styleUrls: ['./reservation-first-screen.component.scss']
})
export class ReservationFirstScreenComponent {

    constructor(
        public buttonTraceService: ButtonTraceService,
        public scrollToService: ScrollToService,
        private router: Router
    ) {
    }

    public goTo(path: string): void { 
        this.router.navigate([path]);
    }
}
