import { Component, Input } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-section-sii-certification',
    templateUrl: './section-sii-certification.component.html',
    styleUrls: ['./section-sii-certification.component.scss']
})
export class SectionSIICertificationComponent{
    @Input() public loadingImages: boolean;

    constructor(
        private buttonTraceService: ButtonTraceService
    ) { }

    goToUrl(url: string, event: Event): void {
        window.open(url, '_blank');
        this.buttonTraceService.traceButton(event, 'navigate');
    }

}
