import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../services/scroll-to';
import { urlMapping } from '../route-ids';

@Component({
    selector: 'app-news-feed',
    templateUrl: './news-feed.component.html',
    styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent implements OnInit  {

    public url: string;

    constructor(
        private router: Router,
        private buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) { }

    ngOnInit(): void { 
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const urlTree = this.router.parseUrl(this.router.url);
                const primarySegment = urlTree.root.children['primary'];
                const urlSegment = primarySegment?.segments[0]?.path || 'home';
                this.url = urlMapping[urlSegment] || urlSegment;
            }
        });
    }

    items = new Array(8).fill(0);

    redireccionarAMaquinas(event: Event) {
        // Verificar si 'sectionPrice' existe en la página actual
        const section = document.getElementsByClassName('sectionPrice');
        if (section.length > 0) {
            // Si existe, desplazarse hacia esa sección
            this.buttonTraceService.traceButton(event, "scroll");
            this.scrollToService.scrollToSection("sectionPrice");
        } else {
            // Si no existe, navegar al Home y luego desplazarse
            this.buttonTraceService.traceButton(event, "navigate");
            this.router.navigate(['/']).then(() => {
                setTimeout(() => {
                    this.scrollToService.scrollToSection("sectionPrice");
                }, 500);
            });
        }
    }
}