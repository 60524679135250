import { Component } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';

@Component({
    selector: 'app-catalog-first-screen',
    templateUrl: './catalog-first-screen.component.html',
    styleUrls: ['./catalog-first-screen.component.scss']
})
export class CatalogFirstScreenComponent {

    constructor(
        public buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) {
    }

    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
    }

}
