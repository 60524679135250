import { Component, ElementRef } from '@angular/core';

import { ScrollToClass } from '../../shared/scrollTo.class';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-credit-first-screen',
    templateUrl: './credit-first-screen.component.html',
    styleUrls: ['./credit-first-screen.component.scss']
})
export class CreditFirstScreenComponent extends ScrollToClass {


    constructor(
        private _el: ElementRef
    ) {
        super(_el);
    }

    public navigateToSection(className: string, event: Event): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
        traceButton2(event, 'scroll');
    }

}
