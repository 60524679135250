import { Component, Inject, Input, OnChanges, PLATFORM_ID, Renderer2, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogComissionComponent } from '../../dialogs/dialog-comission/dialog-comission.component';
import { Router } from '@angular/router';
import { ScrollToClass } from '../../scrollTo.class';
import { isPlatformBrowser } from '@angular/common';


@Component({
    selector: 'app-commission',
    templateUrl: './commission.component.html',
    styleUrls: ['./commission.component.scss']
})
export class CommissionComponent implements OnChanges {
    @Input() public loadingImages: boolean;
    @Input() public data: any;
    @Input() public currentPeriod: number = 0;
    @Input() isDirect:boolean;

    public dataCommissions = [
        {
            days: 1,
            cards: [
                {
                    commission: '1,99'
                },
                {
                    commission: '1,79',
                    saving: '0,20'
                }
            ]
        },
        {
            days: 2,
            cards: [
                {
                    //before: '1,96',
                    commission: '1,96',
                    saving: '0,03'

                },
                {
                    commission: '1,77',
                    saving: '0,22'
                }
            ]
        },
        {
            days: 5,
            cards: [
                {
                    //before:'1,87',
                    commission: '1,87',
                    saving: '0,12'

                },
                {
                    //before:'1,71',
                    commission: '1,71',
                    saving: '0,28'
                }
            ]
        },
        {
            days: 10,
            cards: [
                {
                    commission: '1,61',
                    saving: '0,38'

                },
                {
                    commission: '1,61',
                    saving: '0,38'
                }
            ]
        },
        {
            days: 15,
            cards: [
                {
                    commission: '1,40',
                    saving: '0,59'

                },
                {
                    commission: '1,40',
                    saving: '0,59'
                }
            ]
        },
        {
            days: 25,
            cards: [
                {
                    commission: '0,98',
                    saving: '1,01'

                },
                {
                    commission: '0,98',
                    saving: '1,01'
                }
            ]
        }
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private renderer: Renderer2,
        public dialog: MatDialog,
        private router: Router
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.dataCommissions = this.data;
        };
    }

    openModal(): void {
        this.dialog.open(DialogComissionComponent, {
            panelClass: 'dialogComission',
            width: '466px',
        });
    }

    public openTooltip(): void {
        this.renderer.setStyle(document.getElementById('hiringPlus'), 'visibility', 'visible');
        this.renderer.setStyle(document.getElementById('hiringPlus'), 'opacity', '1');
        this.renderer.setStyle(document.getElementById('hiringPlus'), 'z-index', '11');
        this.renderer.setStyle(document.getElementById('hiringPlus'), 'transform', 'scale(1)');
    }

    public closeTooltip(timeout: number): void {
        setTimeout(() => {
            this.renderer.removeStyle(document.getElementById('hiringPlus'), 'visibility');
            this.renderer.removeStyle(document.getElementById('hiringPlus'), 'opacity');
            this.renderer.removeStyle(document.getElementById('hiringPlus'), 'z-index');
            this.renderer.removeStyle(document.getElementById('hiringPlus'), 'transform');
        }, timeout);
    }

    public navigateTo(event: Event) {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                window.scroll(0, 0);
            }, 200);
        }
        event.preventDefault();
        this.router.navigate(['precios/.']);
    }
}
