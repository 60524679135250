import { Component } from '@angular/core';
import { ScrollToService } from '../../shared/services/scroll-to';
@Component({
    selector: 'app-first-screen-parking',
    templateUrl: './first-screen-parking.component.html',
    styleUrls: ['./first-screen-parking.component.scss']
})
export class FirstScreenParkingComponent {

    constructor(
        public scrollToService: ScrollToService
    ) {
    }

}
