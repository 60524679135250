import { Component, OnInit, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { traceButton2 } from '../../../../../../common/services/button-trace';
import { ScrollToClass } from '../../../shared/scrollTo.class';
import { urlMapping } from '../../route-ids';

@Component({
    selector: 'app-popup-promo',
    templateUrl: './comision/popup-promo-comision.component.html',
    styleUrls: ['./comision/popup-promo-comision.component.scss']
})
export class PopUpPromo extends ScrollToClass implements OnInit  {

    public url: string;

    ngOnInit(): void { }

    constructor(
        private _el: ElementRef,
        private router: Router,
        private dialogRef: MatDialogRef<PopUpPromo>
    ) { 
        super(_el); 
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
    }

    redireccionarAMaquinas(event: Event) {
        traceButton2(event, "scroll");
        this.router.navigate(['/']).then(() => {
            setTimeout(() => {
                this.scrollToSection("sectionPrice");
            }, 500);
        });
        this.scrollToSection("sectionPrice");
        this.dialogRef.close();
    }
}
