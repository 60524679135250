import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NEW_DATA_FOOTER } from '../../footer-data';
import { urlEnv } from '../../../environments/environment';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { urlMapping } from '../route-ids';

@Component({
    selector: 'app-new-footer',
    templateUrl: './new-footer.component.html',
    styleUrls: ['./new-footer.component.scss']
})
export class NewFooterComponent implements OnInit {
    public urlEnv = urlEnv;

    public dataFooter = NEW_DATA_FOOTER({ pagePrefix: String(), env: Object()});
    public currentPage: string;

    constructor(
        private _router: Router
    ) {
    }

    ngOnInit() {
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.currentPage = urlMapping[urlSegment] || urlSegment;
        this.dataFooter = NEW_DATA_FOOTER({ pagePrefix: 't-' + this.currentPage + '-', env: this.urlEnv })
    }

    public track(event: Event): void {
        traceButton2(event, 'navigate');
    }
}
