import { IModalData } from '../../../common/interfaces/promotions.interface';
import { DialogPromoWrapperComponent } from './promotions/dialog-promo/dialog-promo.component';

import { AfterViewInit, Component, ElementRef, Inject, Output, PLATFORM_ID, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from '@angular/router';
import { ScrollToClass } from './shared/scrollTo.class';
import { isPlatformBrowser } from '@angular/common';
import { Contact } from '../../../common/Contact';
import { CONTACTS } from './contacts.constants';

import { API } from '../environments/environment';
import { PopupReservaReleaseComponent } from './shared/dialogs/popup-reserva-release/popup-reserva-release.component';
import { PopUpPromo } from './shared/dialogs/popup-promo/popup-promo.component';
import { SnackBar } from './shared/snack-bars/snack-bar.component';
import { urlMapping } from './shared/route-ids';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent extends ScrollToClass{
    @Output() ContactList: Contact[] = CONTACTS.filter(
        (c) => c.country_code === 'cl'
    );
    public enabledLeavingPopup: boolean = true;
    public enabledReservaPopup: boolean = false; // Dejar en false cuando ya no esté activa la campaña promocional.
    public newsFeed: boolean = false; // Dejar en false cuando no se necesite mostrar el feed de noticias.

    public API_POPUP = API.API_POPUP;
    public reservaDialogOpened: boolean = false;
    public isDirect: boolean = false;
    public url: string;
    public leavingDialogOpened: boolean = false;
    siteKey = '6LeFGJMUAAAAAEDLJLme8uOEGMOTwsjYB6v-gZe7'; //TODO: Importado de contacts.component.ts
    title = 'tuu';
    //TODO: Cinta promocional
    public stripPromotion: boolean = false;
    //TODO: Modal promocional
    private dialogPromo = {
        available: false, //Cambiar esto si se necesita mostrar el popUp de Dcto Mini y Mini S.
        timeOut: 5000,
    };

    private dialogPromoPopUp = {
        available: false,
        timeOut: 5000,
    };
    private eventListeners: Function[] = [];

    constructor(
        public _router: Router,
        private _el: ElementRef,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        @Inject(PLATFORM_ID) private platformId: any,
        private renderer: Renderer2
    ) {
        super(_el);
        this.eventListeners.push(
            this.renderer.listen('window', 'click', () => this.handleUserInteraction()),
            this.renderer.listen('window', 'scroll', () => this.handleUserInteraction()),
            this.renderer.listen('window', 'mousemove', () => this.handleUserInteraction())
          );
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const urlTree = this._router.parseUrl(this._router.url);
                const primarySegment = urlTree.root.children['primary'];
                const urlSegment = primarySegment?.segments[0]?.path || 'home';
                this.url = urlMapping[urlSegment] || urlSegment;

                if (this.url === 'directa') {
                    this.isDirect = true;
                } else {
                    if (this.newsFeed) {
                        this.renderer.addClass(document.body, 'has-news-feed');
                    }
                }

            }});
    }

    handleUserInteraction() {
        this.showSnackBar();
        this.removeEventListeners(); // Detener los escuchadores después de la interacción
    }

    removeEventListeners() {
        this.eventListeners.forEach(listener => listener());
        this.eventListeners = []; // Limpiar la lista
    }

    showSnackBar(): void {
        setTimeout(() => {
            this.openDialogReservaIfNeeded();
        }, 20000); // 20 segundos después de iniciada la página.

        if (isPlatformBrowser(this.platformId) && this.dialogPromo.available) {
            setTimeout(() => {
                if (this.useDialogPromo()) {
                    this.openDialogPromo();
                }
            }, this.dialogPromo.timeOut);
        }

        if (isPlatformBrowser(this.platformId) && this.dialogPromoPopUp.available) {
            setTimeout(() => {
                if (this.useDialogPromo()) {
                this.openDialogPromoPopUp();
                }
            }, this.dialogPromoPopUp.timeOut);
        }
    }
    public handleEmiterLeaving(event: any){
        this.leavingDialogOpened = event;
    }

    public isErrorPage(): boolean {
        if (this._router.url.startsWith('/codigo-amigo/.')) {
            return true;
        } else if (
            this._router.url === '/' ||
            this._router.url === '/boleta-electronica/.' ||
            this._router.url.startsWith('/contactar-con-ventas/.') ||
            this._router.url === '/solitar-cotizacion/.' ||
            this._router.url === '/contactar-con-partners/.' ||
            this._router.url === '/contactar-atencion-cliente/.' ||
            this._router.url === '/precios/.' ||
            this._router.url === '/rollos-e-insumos/.' ||
            this._router.url === '/pago/.' ||
            this._router.url === '/catalogo/.' ||
            this._router.url === '/inventario/.' ||
            this._router.url === '/punto-de-venta/.' ||
            this._router.url === '/partner/.' ||
            this._router.url.startsWith('/comprar-pro/.') ||
            this._router.url.startsWith('/comprar-se/.') ||
            this._router.url.startsWith('/comprar-mini/.') ||
            this._router.url.startsWith('/comprar-mini/.?comision=true') ||
            this._router.url.startsWith('/comprar-mini-s/.') ||
            this._router.url === '/comprar-pro-semi/.' ||
            this._router.url === '/comprar-se-semi/.' ||
            this._router.url === '/comprar-mini-semi/.' ||
            this._router.url === '/comprar-mini-s-semi/.' ||
            this._router.url === '/revendedores/.' ||
            this._router.url === '/cuotas-tuu/.' ||
            this._router.url.startsWith('/seguimiento/') ||
            this._router.url.startsWith('/seleccion-plan/.') ||
            this._router.url.startsWith('/seleccion-producto/.') ||
            this._router.url === '/parking/.' ||
            this._router.url === '/tuu-reserva/.' ||
            this._router.url === '/tuu-reserva/planes/.' ||
            this._router.url.includes('/sim/estado/') ||
            this._router.url.startsWith('/directa/') ||
            this._router.url.startsWith('/credito/') ||
            this._router.url.startsWith('/re-direccion') ||
            this.validParams()
        ) {
            return false;
        }
        return true;
    }

    public isShowProgress(): boolean {
        if (this._router.url.startsWith('/codigo-amigo/.')) {
            return false;
        }
        return true;
    }

    private useDialogPromo(): boolean {
        if (
            this._router.url === '/' ||
            this._router.url === '/pago/.' ||
            this._router.url === '/boleta-electronica/.' ||
            this._router.url === '/catalogo/.' ||
            this._router.url === '/inventario/.' ||
            this._router.url === '/punto-de-venta/.' ||
            this._router.url === '/comprar-pro/.' ||
            this._router.url === '/comprar-se/.' ||
            this._router.url === '/comprar-mini/.' ||
            this._router.url === '/comprar-mini/.?comision=true' ||
            this._router.url === '/comprar-mini-s/.' ||
            this.validParams()
        ) {
            return true;
        }
        return false;
    }

    private validParams(): boolean {
        const re_vi = new RegExp('/?videoask=.*');
        const re_op = new RegExp('/?opciones=.*');
        const re_mr = new RegExp('/?maquina_r=.*');
        const re_gt = new RegExp('/?gclid=.*');
        const re_fb = new RegExp('/?fbclid=.*');
        const re_ca = new RegExp('/?utm_source=.*');
        const re_va = new RegExp('/#campaign=');
        const re_pc = new RegExp('/?pr_code=.*');
        const params = new RegExp('/?subproduct=.*');
        if (
            re_vi.test(this._router.url) ||
            re_op.test(this._router.url) ||
            re_mr.test(this._router.url) ||
            re_ca.test(this._router.url) ||
            re_fb.test(this._router.url) ||
            re_gt.test(this._router.url) ||
            re_va.test(this._router.url) ||
            re_pc.test(this._router.url) ||
            params.test(this._router.url)
        ) {
            return true;
        }
        return false;
    }

    private CookieService = {
        getCookieStatus() {
            const cookies = document.cookie.split(';');
            let returnValue = {
                popUpReserva: null,
            };
            for (const cookie of cookies) {
                if (cookie.indexOf('popUpReserva' + '=') > -1) {
                    returnValue.popUpReserva = cookie.split('=')[1];
                }
            }
            return returnValue;
        },
        setReservaCookie() {
            const expirationSeconds = 60 * 60 * 24; // 1 día de expiración
            let date = new Date();
            date = new Date(date.getTime() + 1000 * expirationSeconds);
            let newCookie2 =
                'popUpReserva={"expiration":"' +
                date.toUTCString() +
                '"}; expires=' +
                date.toUTCString() +
                '; path=/;';
            document.cookie = newCookie2;
        },
    };

    private shouldShowReservaDialog(cookieValue: string): boolean {
        if (!cookieValue) {
            return true;
        }

        const lastShownDate = new Date(cookieValue);
        const currentDate = new Date();
        const daysSinceLastShow = Math.floor(
            (currentDate.getTime() - lastShownDate.getTime()) /
                (1000 * 60 * 60 * 24)
        );

        // Verificar si ha pasado al menos 1 día desde la última vez que se mostró
        if (daysSinceLastShow >= 1) {
            // Verificar si se ha mostrado menos de 3 veces en los últimos 7 días
            const last7Days = new Date(
                currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
            );
            let count = 0;
            const cookies = document.cookie.split(';');
            for (const cookie of cookies) {
                const cookieTrimmed = cookie.trim();
                if (
                    cookieTrimmed.startsWith('popUpReserva=') &&
                    new Date(cookieTrimmed.substring(13)) >= last7Days
                ) {
                    count++;
                }
            }
            return count < 3;
        }

        return false;
    }

    public openDialogPromo() {
        if (!this.reservaDialogOpened && !this.isDirect && !this.leavingDialogOpened) {
            const dialogRef = this.dialog.open(DialogPromoWrapperComponent, {
                hasBackdrop: true,
                disableClose: true,
                id: 'promocionalModal',
                maxWidth: '',
                width: '652px',
                data: { status: false },
                ariaLabel: 'Mensaje de promoción por Cyber'
            });

            dialogRef.afterClosed().subscribe((res: IModalData) => {
                this.reservaDialogOpened = false;
                if (res.status && !this._router.url.includes('/comprar-')) {
                    sessionStorage.setItem('prCode', res.prCode);
                    this.scrollToSection('sectionPrice');
                } else if (res.status) {
                    sessionStorage.setItem('prCode', res.prCode);
                }
            });
            this.reservaDialogOpened = true;
        }
    }

    public openDialogPromoPopUp() {
        if (!this.reservaDialogOpened && !this.isDirect && !this.leavingDialogOpened) {
            const notification = this.snackBar.openFromComponent(SnackBar, {
                duration: 15000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: ['custom-snackbar-panel']
            });
            notification.afterDismissed().subscribe(() => {
                this.reservaDialogOpened = false;
            });
            this.reservaDialogOpened = true;
        }
    }

    private openDialogReservaIfNeeded(): void {
        if (
            isPlatformBrowser(this.platformId) &&
            !this.reservaDialogOpened &&
            !this.isDirect &&
            !this.leavingDialogOpened &&
            this.enabledReservaPopup
        ) {
            const cookieStatus = this.CookieService.getCookieStatus();
            if (this.shouldShowReservaDialog(cookieStatus.popUpReserva)) {
                const dialog = this.dialog.open(PopupReservaReleaseComponent, {
                    hasBackdrop: true,
                    disableClose: false,
                    width: '744px',
                    height: 'auto',
                    panelClass: 'dialogWithOffer',
                });
                dialog.afterClosed().subscribe(() => {
                    this.reservaDialogOpened = false;
                });

                this.CookieService.setReservaCookie();
                this.reservaDialogOpened = true;
            }
        }
    }

    /*TODO: encontrar una forma de realizar la redireccion con parametro videoask=exp1 cuando se llegue desde el video de home
    checkReferer(): void {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const refer = document.referrer;
                const currentUrl = this._router.url

                if (refer === "https://www.videoask.com/" && !currentUrl.includes('?videoask=exp1')){
                    //const newUrl = currentUrl.includes('?videoask=exp1') ? currentUrl : `${currentUrl}?videoask=exp1`;
                    this._router.navigateByUrl(`${currentUrl}?videoask=exp1`, { replaceUrl: true });
                }
            }
          });
      }*/
}
