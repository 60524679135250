import { Component, Input } from '@angular/core';
import { DATA_PRICE_ASSOCIATION } from '../../shared/section-price/data-price';
import { ScrollToService } from '../../shared/services/scroll-to';
import { ButtonTraceService } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-section-buy-today',
    templateUrl: './section-buy-today.component.html',
    styleUrls: ['./section-buy-today.component.scss']
})
export class SectionBuyTodayComponent {
    @Input() public loadingImages: boolean;
    public DATA_PRICE_ASSOCIATION = DATA_PRICE_ASSOCIATION;

    constructor(
        private buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) {
    }

    public navigateToSection(className: string, event:Event): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
        this.buttonTraceService.traceButton(event,'scroll');
    }

}
