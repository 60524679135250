import { Component, OnInit, ElementRef } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from '@angular/router';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../../shared/scrollTo.class';
import { urlMapping } from '../route-ids';

@Component({
    selector: 'app-snack-bar',
    templateUrl: './comision/snack-bar-comision.component.html',
    styleUrls: ['./comision/snack-bar-comision.component.scss']
})
export class SnackBar extends ScrollToClass implements OnInit  {

    public url: string;

    constructor(
        private _el: ElementRef,
        private router: Router,
        private snackBar: MatSnackBarRef<SnackBar>
    ) { super(_el); }

    ngOnInit(): void {
        this.updateUrl();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.updateUrl();
            }
        });
    }
    
    private updateUrl(): void {
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
    }

    redireccionarAMaquinas(event: Event) {
        // Verificar si 'sectionPrice' existe en la página actual
        const section = document.getElementsByClassName('sectionPrice');
        if (section.length > 0) {
            // Si existe, desplazarse hacia esa sección
            traceButton2(event, "scroll");
            this.scrollToSection("sectionPrice");
        } else {
            // Si no existe, navegar al Home y luego desplazarse
            traceButton2(event, "navigate");
            this.router.navigate(['/']).then(() => {
                setTimeout(() => {
                    this.scrollToSection("sectionPrice");
                }, 500);
            });
        }
        this.snackBar.dismiss();
    }
    
    close(){
        this.snackBar.dismiss(); 
    }

}
