import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
    
    constructor(private router: Router) { }

    public goToHome(event?: Event): void {
        if (event) {
            event.preventDefault();
        }
        this.router.navigate(['/']);
    }
}
