import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'app-dialog-info',
    templateUrl: './dialog-info.component.html',
    styleUrls: ['./dialog-info.component.scss']
})
export class DialogInfoComponent {

    constructor(
        private router: Router
    ) { }

    public goTo(path: string, event: Event): void {
        event.preventDefault();
        this.router.navigate([path]);
    }
}
