import { Component, Input } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';

@Component({
    selector: 'app-boleta-first-screen',
    templateUrl: './boleta-first-screen.component.html',
    styleUrls: ['./boleta-first-screen.component.scss']
})
export class BoletaFirstScreenComponent{

    @Input() public idTestAB?: string = '';

    constructor(
        private buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) {
    }

    public navigateToSection(className: string, event: Event): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
        this.buttonTraceService.traceButton(event, 'scroll', this.idTestAB);
    }

}
