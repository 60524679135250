import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-credit-instructions',
    templateUrl: './credit-instructions.component.html',
    styleUrls: ['./credit-instructions.component.scss']
})
export class CreditInstructionsComponent {
    @Input() public loadingImages: boolean;

    list: Array<{icon: string; title: string; text: string;}> = [
        {
            icon: 'icon-sprite-trending-down',
            title: 'Pagas el crédito con un porcentaje de tus ventas.',
            text: 'No necesitas preocuparte por guardar dinero o hacer depósitos. El pago de tu crédito se descuenta automáticamente del abono de tus ventas diarias.'
        }, {
            icon: 'icon-sprite-trending-down',
            title: 'Las cuotas que pagas se ajustan a tus ventas.',
            text: 'Los días que ganas menos, pagas menos, y los días que ganas más, aportas más. Si hay un día en el que las ventas fueron bajas, puedes compensar con un día de buenas ventas.'
        }, {
            icon: 'icon-sprite-schedule',
            title: 'Paga el total de tu crédito cuando quieras.',
            text: 'Si por algún motivo tus ventas no te permitieron cubrir el pago del crédito, podrás realizar un pago directo para saldarlo antes de la fecha límite de pago.'
        }
    ];

}
