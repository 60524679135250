import { Component, ElementRef, HostListener, OnInit, Inject, ViewChild, Renderer2, PLATFORM_ID, ViewContainerRef, Input } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd, UrlTree } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalMenuService } from '../services/modal-menu.service';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { traceButton, traceButton2 } from '../../../../../common/services/button-trace';
import { urlMapping } from '../route-ids';
export interface IMatMenuList {
    id: string;
    icon: string;
    text: string;
    route?: string;
    href?: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    @ViewChild('header', { static: true }) header: ElementRef;
    @ViewChild('mobileMenu', { read: ViewContainerRef }) containerMobileMenu!: ViewContainerRef;

    styleButton = 'outlineFilled';

    subscriptionMobileMenuOpen!: Subscription;
    subscriptionMobileMenuClose!: Subscription;

    // isOpenNavOnMobile;
    isOpenMatMenu: boolean;
    isScrollHeader: boolean;
    clase: string;
    isDirect: boolean = false;

    scale = 1;

    voices: SpeechSynthesisVoice[];
    selectedVoice: SpeechSynthesisVoice | null;
    text: string;
    public url: string;
    public logoUrl: string;

    matMenuList: Array<IMatMenuList> = [
        {
            id: '',
            icon: 'icon-sprite-calendar-dots',
            text: 'Agendar Capacitación',
            href: 'https://calendly.com/agenda-haulmer/'
        },
        {
            id: '',
            icon: 'icon-sprite-settings',
            text: 'Contactar soporte',
            route: 'contactar-con-soporte'
        },
        {
            id: '',
            icon: 'icon-sprite-phone-call',
            text: 'Contactar con ventas',
            route: 'contactar-con-ventas'
        },
        // {
        //     id: 'TUU-devolucion-garantias',
        //     icon: 'icon-sprite-lock',
        //     text: 'Devolución y garantía',
        //     href: 'https://survey.typeform.com/to/UVuTgl4M'
        // },
        {
            id: '',
            icon: 'icon-sprite-file',
            text: 'Documentación',
            href: 'https://help.tuu.cl'
        },
        {
            id: '',
            icon: 'icons-fi-rr-dashboard',
            text: 'Seguimiento de Solicitud',
            route: 'seguimiento'
        },
        {
            id: '',
            icon: 'icon-fi-rr-comments',
            text: 'Sugerencias o reclamos',
            href: 'https://survey.typeform.com/to/zQ7I32J9#empresa=TUU&issuetype=11071'
        },
    ];

    headerBtnData;

    dataBtnLogin = {
        id: '',
        text: 'Iniciar sesión',
        href: 'https://espacio.haulmer.com',
        route: null
    }

    dataBtnContact = {
        id: '',
        text: 'Contactar con ventas',
        href: null,
        route: 'contactar-con-ventas'
    }

    @Input() promotionStrip: boolean;

    @HostListener('window:scroll')
    onScroll(): void {
        this.checkHeaderPosition();
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router,
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) private platformId: any,
        private modalMenuService: ModalMenuService
    ) {
        this.voices = [];
        this.selectedVoice = null;
        this.text = 'MÁQUINA PARA RECIBIR PAGOS CON TARJETAS';
    }

    ngOnInit(): void {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const urlTree = this._router.parseUrl(this._router.url);
                const primarySegment = urlTree.root.children['primary'];
                const urlSegment = primarySegment?.segments[0]?.path || 'home';;
                this.url = urlMapping[urlSegment] || urlSegment;

                if(this.url === 'directa'){
                    this.isDirect = true;
                    this.logoUrl = '/directa/';
                }
                else{
                    this.logoUrl='/'
                }

                const queryParams = urlTree.queryParams;
                this.url = (queryParams?.['purchaseOrigin'] === 'reserva')
                    ? (queryParams['subproduct'] === 'TUU' ? 'reserva' : 'reserva-plus')
                    : this.url;
                this.dataBtnLogin.id='t-'+this.url+'-nv-sesion';
                this.dataBtnContact.id='t-'+this.url+'-nv-ventas';
                this.matMenuList[0].id='t-'+this.url+'-nv-ayuda-capacitación';
                this.matMenuList[1].id='t-'+this.url+'-nv-ayuda-soporte';
                this.matMenuList[2].id='t-'+this.url+'-nv-ayuda-ventas';
                this.matMenuList[3].id='t-'+this.url+'-nv-ayuda-documentacion';
                this.matMenuList[4].id='t-'+this.url+'-nv-ayuda-seguimiento';
                this.matMenuList[5].id='t-'+this.url+'-nv-ayuda-reclamos';
            }
          });
        if (isPlatformBrowser(this.platformId)) {
            const params = new URLSearchParams(document.location.search.substring(1));
            this.clase = params.get('class');

            speechSynthesis.onvoiceschanged = () => {
                this.voices = speechSynthesis.getVoices();
                this.selectedVoice = (this.voices[52] || null);
            };

            // set contrast from localStorage
            if (localStorage.getItem('contrast') !== null) {
                this.renderer.addClass(document.body, 'contrast');
            }

            // set scale from localStorage
            if (localStorage.getItem('scale') !== null) {
                this.renderer.addClass(document.body.parentElement, 'scale-' + localStorage.getItem('scale'));
                this.scale = Number(localStorage.getItem('scale'));
            } else {
                this.renderer.addClass(document.body.parentElement, 'scale-1');
            }
        }

        this.headerBtnData = this.dataBtnLogin;
    }

    checkHeaderPosition(): void {
        const startScroll = window.innerWidth > 670 ? 56 : 48;
        const offsetY = window.scrollY;
        const headerEl = this.header.nativeElement;

        if (offsetY >= startScroll ||
            this._document.getElementsByTagName('html')[0].classList.contains('cdk-global-scrollblock')
        ) {
            headerEl.classList.add('fixed');
            this.isScrollHeader = true;
            this.headerBtnData = this.dataBtnContact;
        } else {
            headerEl.classList.remove('fixed');
            this.isScrollHeader = false;
            this.headerBtnData = this.dataBtnLogin;
        }
    }

    openDropdownMenu(): void {
        setTimeout(() => {
            this.renderer.addClass(document.body, 'open-dropdown');
        }, 0);
    }

    closeDropdownMenu(): void {
        this.renderer.removeClass(document.body, 'open-dropdown');
    }

    // mobile menu
    openMobileMenu(): void {
        setTimeout(() => {
            this.renderer.addClass(document.body, 'open-menu');
        }, 0);

        this.subscriptionMobileMenuOpen = this.modalMenuService.openModal(
            this.containerMobileMenu, MobileMenuComponent
        ).subscribe();
        this.subscriptionMobileMenuClose = this.modalMenuService.componentRef.instance.closeEventParent
            .subscribe(() => {
                this.closeMobileMenu();
            });
    }

    closeMobileMenu() {
        this.renderer.removeClass(document.body, 'open-menu');

        setTimeout(() => {
            this.modalMenuService.closeModal(this.containerMobileMenu);
            this.subscriptionMobileMenuOpen.unsubscribe();
            this.subscriptionMobileMenuClose.unsubscribe();
        }, 400);
    }

    closeMenu(): void {
        if (window.innerWidth > 992) {
            this.closeDropdownMenu();
        } else {
            this.closeMobileMenu();
        }
    }

    // navigation
    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    navigateTo(item: any): void {
        if (item.route) {
            this._router.navigate(['/' + item.route]);
        } else if (item.href) {
            this.goToUrl(item.href);
        }
    }

    getClassHeader(): string {
        let ngClass = '';
        if (this.promotionStrip) {
            ngClass = 'wrapHeader_strip';
        }
        if (this._router.url.startsWith('/contactar-con-ventas/.') ||
            this._router.url.startsWith('/contactar-con-soporte/.')) {
            ngClass = ngClass + ' non-sticky';
        } else if (
            this._router.url === '/' ||
            this._router.url.startsWith('/punto-de-venta/.') ||
            this._router.url.startsWith('/pago/.') ||
            this._router.url.startsWith('/tarifas/.') ||
            this._router.url.startsWith('/comprar-pro/.') ||
            this._router.url.startsWith('/comprar-se/.') ||
            this._router.url.startsWith('/comprar-mini/.') ||
            this._router.url.startsWith('/comprar-mini/.?comision=true') ||
            this._router.url.startsWith('/comprar-mini-s/.') ||
            this._router.url.startsWith('/comprar-pro-semi/.') ||
            this._router.url.startsWith('/comprar-se-semi/.') ||
            this._router.url.startsWith('/comprar-mini-semi/.') ||
            this._router.url.startsWith('/comprar-mini-s-semi/.') ||
            this._router.url.startsWith('/revendedores/.') ||
            this._router.url.startsWith('/cuotas-tuu/.') ||
            this._router.url.startsWith('/seleccion-plan/.') ||
            this._router.url.startsWith('/seleccion-producto/.') ||
            this._router.url.startsWith('/tuu-reserva/.') ||
            this._router.url.startsWith('/tuu-reserva/planes/.') ||
            this._router.url.startsWith('/inventario') ||
            this._router.url.startsWith('/directa/') ||
            this._router.url.startsWith('/re-direccion') ||
            this.validParams()
        ) {
            ngClass = ngClass + ' dark-color';
        }
        return ngClass;
    }

    getStyleButton(): string {
        if (this._router.url.startsWith('/inventario')){
            return '';
        }
        if (this.isScrollHeader) {
            this.styleButton = 'outlineTransparent'
            return this.styleButton;
        } else {
            return this.getClassHeader().includes('dark-color') ? 'outlinePrimaryFilled' : 'outlineFilled';
        }
    }

    // accessibility
    contrast(): void {
        if (localStorage.getItem('contrast') === null) {
            this.renderer.addClass(document.body, 'contrast');
            localStorage.setItem('contrast', 'true');
        } else {
            this.renderer.removeClass(document.body, 'contrast');
            localStorage.removeItem('contrast');
        }
    }

    toScale(type): void {
        if (type === 1) {
            if (this.scale < 3) {
                this.scale++;
                this.renderer.removeClass(document.body.parentElement, 'scale-' + (this.scale - 1));
                localStorage.removeItem('scale');
            }
        } else {
            if (this.scale > 1) {
                this.scale--;
                this.renderer.removeClass(document.body.parentElement, 'scale-' + (this.scale + 1));
                localStorage.removeItem('scale');
            }
        }

        this.renderer.addClass(document.body.parentElement, 'scale-' + this.scale);
        localStorage.setItem('scale', this.scale + '');
    }

    speak(): void {
        if (!this.selectedVoice || !this.text) {
            return;
        }

        if (speechSynthesis.speaking) {
            speechSynthesis.cancel();
        } else {
            const utterance = new SpeechSynthesisUtterance(this.text);
            utterance.voice = this.selectedVoice;
            utterance.rate = 1;
            speechSynthesis.speak(utterance);
        }
    }
    traceButtonCall(event: any, type: string) {
        if (event.target.id == 'TUU-seguimiento-btn') traceButton(event, type);
    }

    private validParams(): boolean {
        const re_gt = new RegExp('/?gclid=.*');
        const re_fb = new RegExp('/?fbclid=.*');
        const re_ca = new RegExp('/?utm_source=.*');
        const re_va = new RegExp('/#campaign=')
        const re_pc = new RegExp('/?pr_code=*');
        const params = new RegExp('/?subproduct=.*');
        if (re_ca.test(this._router.url) ||
            re_fb.test(this._router.url) ||
            re_gt.test(this._router.url) ||
            re_va.test(this._router.url) ||
            re_pc.test(this._router.url) ||
            params.test(this._router.url)) {
            return true;
        }
        return false;
    }

    public track(event: Event): void {
        traceButton2(event, 'navigate');
    }
}
