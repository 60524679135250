import { Component } from '@angular/core';

@Component({
    selector: 'app-credit-faq',
    templateUrl: './credit-FAQ.component.html',
    styleUrls: ['./credit-FAQ.component.scss']
})
export class CreditFAQComponent {
    public activePanel = -1;

    public items: Array<{title: string; text?: string; list?: Array<string>}> = [
        {
            title: '¿Si soy cliente nuevo puedo acceder a un crédito?',
            text: 'Para poder solicitar un crédito con TUU, debes tener al menos 3 meses aceptando pagos con TUU.'
        }, {
            title: '¿Cómo solicitar TUU crédito?',
            list: [
                `Elige la oferta que más te interesa, dando clic en 'Solicitar $ monto'.`,
                'Valida y completa tu formulario de solicitud confirmando tu cuenta de depósito, datos de tu comercio, dirección comercial y datos personales. Te tomará apenas unos minutos.',
                'Recibe tu contrato. Una vez verificados tus datos, te enviaremos el contrato de TUU crédito, el cual deberás firmar digitalmente.',
                'Listo, firmado tu contrato, se depositará TUU Crédito a tu cuenta bancaria registrada en TUU en menos de 1 día hábil.'
            ]
        }, {
            title: '¿Cómo puedo pagar mi crédito?',
            text: `<div>Pagarás TUU Crédito con un porcentaje de las ventas diarias realizadas en TUU máquina de pagos. Así, el pago de tu financiamiento se adapta a tus ventas.<div><br>
            <div>Adicionalmente si lo deseas podrás pagar TUU Crédito con pagos directos a través de transferencia bancaria.</div>
            <div/>Contacta a <a href="http://tuu.cl/contactar-con-soporte/" target="_blank" class="color-accent-primary non-underline"><b class="font-weight-semibold">Soporte</b></a> para conocer las modalidades de transferencia.</div>`
        }, {
            title: 'Si pago de manera anticipada ¿El costo del crédito sigue igual?',
            text: 'Sí, ya que tu crédito consiste de una comisión fija, el pagar por adelantado una parte o el total no genera cambios en el costo de este.'
        }, {
            title: '¿Que pasa si no pago mi crédito a tiempo?',
            text: `<div>Pagarás intereses moratorios sobre el monto vencido, calculados a partir del día de su vencimiento hasta el día del pago total de dicha cantidad. El valor de la tasa de intereses moratorios la podrás encontrar en el contrato de crédito, en la Hoja Resumen, bajo el rubro '<b class="font-weight-semibold">Tasa de Intereses Moratorios</b>'.</div><br>
            <div>Para evitar llegar a esta situación, podrás realizar un pago directo a la cuenta indicada en tu contrato antes de que superes el plazo límite para el pago del crédito.</div>`
        }, {
            title: '¿Puedo recibir más ofertas?',
            text: `Sí, podrás recibir nuevas ofertas una vez que termines de pagar TUU crédito vigente o puedes recibir una nueva oferta mientras tienes un crédito vigente, para efectos de amortizar el crédito vigente y contratar uno nuevo.`
        }
    ];

    public openPanel(index): void {
        setTimeout(() => {
            this.activePanel = index;
        }, 0);

    }
}
