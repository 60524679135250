import { Component, Input } from '@angular/core';
import { ScrollToService } from '../../shared/services/scroll-to';

@Component({
    selector: 'app-section-tuu-scanner',
    templateUrl: './section-tuu-scanner.component.html',
    styleUrls: ['./section-tuu-scanner.component.scss']
})
export class SectionTuuScannerComponent{
    @Input() public loadingImages: boolean;

    list = [
        {
            icon: 'icon-sprite-scanner',
            text: '<b>Velocidad de lectura:</b> Escanea rápidamente códigos 1D y 2D en tus productos.'
        },
        {
            icon: 'icon-sprite-bluetooth-searching',
            text: '<b>Conexión Bluetooth:</b> Vende cómodo y rápido, sin amarrarte a cables.'
        },
        {
            icon: 'icon-sprite-hand2',
            text: '<b>Diseño ergonómico:</b> Intuitiva, sencilla, y fácil de usar, con soporte para lector incluido.'
        }
    ];

    constructor(
        private scrollToService: ScrollToService
    ) {
    }

    navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
    }

}
