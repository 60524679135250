import { IDataStrip } from './../../../../../../common/interfaces/promotions.interface';
import { AppType } from './../../../../../../common/assets/website-data';
import { Component, Input } from "@angular/core";
import { ButtonTraceService } from '../../../../../../common/services/button-trace';
import { Router } from '@angular/router';
import { ScrollToService } from '../../../shared/services/scroll-to';
import { urlMapping } from '../../../shared/route-ids';

@Component({
    selector: 'app-strip-tuu',
    templateUrl: './strip.component.html',
    styleUrls: ['./strip.component.scss']
})

export class StripComponentTUU {
    @Input() data: IDataStrip;
    @Input() appType: AppType;
    public svgSize = { width: '70px', height: '35px' };
    public url: string;

    constructor(
        private router: Router,
        private buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) { 
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
    }


    redireccionarAMaquinas(event: Event) {
        this.buttonTraceService.traceButton(event, "scroll");
        // Navega a la ruta 'home' con el fragmento 'sectionPrice'
        this.router.navigate(['/']).then(() => {
            setTimeout(() => {
                this.scrollToService.scrollToSection("sectionPrice");
            }, 500); // Espera antes de realizar el scroll
        });
        this.scrollToService.scrollToSection("sectionPrice");
    }
}