import { Component, Input } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { Router } from '@angular/router';

@Component({
    selector: 'app-section-certification',
    templateUrl: './section-certification.component.html',
    styleUrls: ['./section-certification.component.scss']
})
export class SectionCertificationComponent {
    @Input() public loadingImages: boolean;

    constructor(
        private buttonTraceService: ButtonTraceService,
        private router: Router
    ) { }

    goToUrl(url: string, event:Event): void {
        window.open(url, '_blank');
        this.buttonTraceService.traceButton(event, 'navigate');
    }

    public goTo(): void {
        this.router.navigate(['/cuotas-tuu']);
    }

}
