import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonTraceService } from '../../../../../../common/services/button-trace';
import { IDataModalTemplate, IModalData } from '../../../../../../common/interfaces/promotions.interface';
import { AppType } from '../../../../../../common/assets/website-data';
import { ScrollToService } from '../../../shared/services/scroll-to';
import { Router } from '@angular/router';
import { urlMapping } from '../../../shared/route-ids';
@Component({
    selector: 'app-dialog-promo-tuu',
    templateUrl: './dialog-promo-tuu.component.html',
    styleUrls: ['./dialog-promo-tuu.component.scss']
})
export class DialogPromoTUUComponent implements OnInit {
    @Input() dataModal: IDataModalTemplate;
    @Input() appType: AppType;
    public logoWidth = '45px';
    public url:string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IModalData,
        private dialog: MatDialogRef<DialogPromoTUUComponent>,
        private router: Router,
        private buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) {
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.logoWidth = window.innerWidth < 768 ? '40px' : '45px';
    }

    ngOnInit(): void {
        this.logoWidth = window.innerWidth < 768 ? '40px' : '45px';
    }

    public useButton(event: Event): void {
        this.buttonTraceService.traceButton(event, 'scroll');
        const closingData = { status: false, prCode: null };
        if (this.dataModal.promoCode?.code) {
            closingData.prCode = this.dataModal.promoCode.code;
        }
        if (this.dataModal.button.scrollTo) {
            this.scrollToService.scrollToSection(this.dataModal.button.scrollTo);
        }
        this.dialog.close(closingData);
    }
}
