import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { IPricingCard } from '../shared/section-price/pricing-card/pricing-card.component';
import { DATA_CARD_PRICE } from '../shared/section-price/data-price';
import { Router } from '@angular/router';
import { UrlService } from '../shared/services/url.service';
import { traceButton2 } from '../../../../common/services/button-trace';

@Component({
    selector: 'app-product-page',
    templateUrl: './product-page.component.html',
    styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {
    @ViewChild('slider') public slider: ElementRef;

    public urlEnv = urlEnv;

    public cards: Array<IPricingCard> = DATA_CARD_PRICE;
    public currentCard: number = 0;
    public url:string;

    public plan = {
        "TUU": {
            question: '¿Cuál equipo llevarás con tu compra?'
        },
        "TUUP": {
            question: '¿Quieres agregar un equipo a tu compra?'
        }
    }

    public urlPararm: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: unknown,
        public location: Location,
        private readonly _meta: MetaService,
        private urlService: UrlService,
        private router: Router
    ) { 
        const urlTree = this.router.parseUrl(this.router.url);
        const queryParams = urlTree.queryParams;
        const subproduct = this.urlService.getValue('purchaseOrigin');
        if(this.isPlanPlus()){
            this.url = subproduct + "-plus"
        }
        else{
            this.url = subproduct
        }
    }

    ngOnInit(): void {
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 200);
        this.urlPararm = this.urlService.getValue('subproduct');
        if (!this.urlPararm) {
            this.router.navigate(['/']);
        }
    }

    public prev(): void {
        if (this.currentCard > 0) {
            this.currentCard--;
            this.moveSlide('prev');
        }
    }

    public next(): void {
        if (this.currentCard < this.cards.length - 1) {
            this.currentCard++;
            this.moveSlide('next');
        }
    }

    public moveSlide(direction: string): void {
        const sliderContainer = this.slider.nativeElement;

        if (direction === 'prev' && this.currentCard === 0) {
            sliderContainer.style.transform = `translateX(0px)`;
        } else {
            sliderContainer.style.transform = `translateX(-${((sliderContainer.offsetWidth + 20) * this.currentCard)}px)`;
        }
    }

    private _setMeta() {
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-products.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
    }

    public isPlanPlus(): boolean {
        const subproduct = this.urlService.getValue('subproduct');
        if (subproduct === 'TUUP') {
            return true;
        }
        return false;
    }

    public onSubmit(): void {
        const subproduct = this.urlService.getValue('subproduct');
        const purchaseOrigin = this.urlService.getValue('purchaseOrigin');
        const newUrl: URL = this.urlService.addParams(new URL(this.urlEnv.TUU_CART_URL), [
            { name: 'subproduct', value: subproduct },
            { name: 'qtyproduct', value: '1' },
            { name: 'period_opt', value: '1' },
            { name: 'purchaseOrigin', value: purchaseOrigin }
        ]);
        window.open(newUrl.href, '_self');
    }

    public goBack() {
        this.location.back();
    }

    public track(event: Event){
        traceButton2(event, 'navigate');
    }
}