import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-partners-benefits',
    templateUrl: './partners-benefits.component.html',
    styleUrls: ['./partners-benefits.component.scss']
})
export class PartnersBenefitsComponent {
    @Input() public loadingImages: boolean;

    public list = [
        {
            icon: 'icon-sprite-cash',
            text: '<b>Nueva fuente de ingresos</b> recurrente para tu negocio.'
        }, {
            icon: 'icon-sprite-gift',
            text: '<b>Ofrece una solución todo en uno</b> a tus clientes.'
        }, {
            icon: 'icon-sprite-call-incoming',
            text: '<b>Atención al Cliente preferencial</b> para resolver todas tus dudas.'
        }, {
            icon: 'icon-sprite-headset',
            text: '<b>Capacitaciones personalizadas</b> para evolucionar como Partner.'
        }, {
            icon: 'icon-sprite-picture',
            text: '<b>Material exclusivo de la marca</b> ¡Eres de los nuestros!'
        }
    ]
}
