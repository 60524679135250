export interface UrlList {
    project: string;
    link_sales: string;
    link_support: string;
    link_payment: string;
}

export const ArrUrl: UrlList[] = [
    {
        project: 'tuu',
        link_sales: 'https://www.tuu.cl/contactar-con-ventas/',
        link_support: 'https://my.haulmer.com/submitticket.php?step=2&deptid=1',
        link_payment: 'https://my.haulmer.com/submitticket.php?step=2&deptid=5',
    }
];

export class ProjectsUrl {

    getProjectList(project) {
        const activeProject = ArrUrl.filter(item => item.project === project)[0];
        return activeProject;
    }
}
