import { FormControl } from '@angular/forms';
import { Component, OnInit } from "@angular/core";
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-credit-simulator',
    templateUrl: './credit-simulator.component.html',
    styleUrls: ['./credit-simulator.component.scss'],
})

export class CreditSimulatorComponent implements OnInit {

    public slider = new FormControl('100000');
    public selectedValue: number = 100000;
    public calculatedValue: number = 0;

    public showResult: boolean = false;

    constructor(
        public buttonTraceService: ButtonTraceService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.slider.valueChanges.pipe(
            map(x => {
                if (x === 0) {
                    return 100000;
                }
                return x;
            }),
        )
            .subscribe(x => {
                this.selectedValue = x;
            });
    }

    public changeView(): void {
        const calculatedValues = {
            100000: 1470200,
            500000: 1834700,
            1000000: 1912000,
            1500000: 3056100,
            2000000: 4755200,
            2500000: 4755200,
            3000000: 5294100,
            3500000: 5983100,
            4000000: 8905500,
            4500000: 8905500,
            5000000: 8365500,
            5500000: 9773400,
            6000000: 9773400,
            6500000: 9272300,
            7000000: 9272300,
            7500000: 9592200,
            8000000: 12088300,
            8500000: 12088300,
            9000000: 12088300,
            9500000: 11961800,
            10000000: 12088300,
            10500000: 12088300,
            11000000: 12201300,
            11500000: 12313000,
        };

        this.calculatedValue = calculatedValues[this.selectedValue];
        this.showResult = !this.showResult;
    }

    navigateTo(route: string) {
        this.router.navigate([route]);
    }
}
