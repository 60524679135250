import { Component, OnInit, Inject, PLATFORM_ID, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-dialog-see-product2',
    templateUrl: './dialog-see-product2.component.html',
    styleUrls: ['./dialog-see-product2.component.scss']
})
export class DialogSeeProduct2Component implements OnInit {

    public isMobile: boolean = false;

    optionsList = [
        {
            text: '<b>Diseño ergonómico</b> para uso prolongado.',
            icon: 'icon-sprite-hand'
        }, {
            text: 'Conexión por cable USB con <b>500ms de velocidad.</b>',
            icon: 'icon-sprite-share'
        }, {
            text: 'Permite escanear <b>códigos 1D y 2D.</b>',
            icon: 'icon-sprite-scan-new'
        }, {
            text: 'Incluye cable <b>USB y soporte para lector.</b>',
            icon: 'icon-sprite-cube'
        }, {
            text: 'Garantía de <b>2 años.</b>',
            icon: 'icon-sprite-premium'
        }
    ];

    special_features = [
        {
            icon: 'icon-sprite-scan-new',
            text: 'Vende tus productos<br> de forma automática.',
        },
        {
            icon: 'icon-sprite-plug',
            text: 'No necesitas <br>corriente eléctrica.'
        }
    ]

    constructor(
        public dialogRef: MatDialogRef<DialogSeeProduct2Component>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    @HostListener('window:resize')
        onResize() {
            this.isMobile = window.innerWidth < 993;
        }
    
        ngOnInit() {
            if (isPlatformBrowser(this.platformId)) {
                this.isMobile = window.innerWidth < 993;
            }
        }

    public closeDialog(action: string) {
        this.dialogRef.close(action);
    }
}
