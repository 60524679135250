import { FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';
import { CONTACTS } from './../contacts.constants';
import { Component, OnInit, Inject, Input, ViewChild, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { MetaService } from '@ngx-meta/core';
import { Contact } from '../../../../common/Contact';
import { Meta } from '../../../../common/Meta';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ApiService } from '../shared/services/api.service';
import { COUNTRY_REGION, getEmailErrorMessage, sanitizePhone, getPhonePlaceholder, getPhoneErrorMessage, divideNames, changeValidation, getContact, buildBasicForm, buildFromPartners, buildFromRequestQuote, buildFormContactTUU, changeCountry, EMPTY_PHONE_PLACEHOLDER, EMPTY_PHONE_VALUE } from '../../../../common/contact-shared/contact-shared.component';
import { traceButton2 } from '../../../../common/services/button-trace';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactsComponent implements OnInit {
    contact: Contact;
    activeCaptcha = false;
    captcha: any;
    disableButton = false;
    isReadOnly = false;
    currentCountryCode: string;
    form: FormGroup;
    firstClick = false;
    isSendForm = false;
    siteKey = '6LeFGJMUAAAAAEDLJLme8uOEGMOTwsjYB6v-gZe7';
    hidden: boolean;
    allContactList;
    phoneSelected: string;
    phonehrefSelected: string;
    citySelected: string;
    countrySelected: string;
    flagSelected: string;
    chile;
    region;
    comuna;
    public emailErrorMessage: string ;
    public phoneErrorMessage: string ;

    @Input() TLD: string;
    @Input() Meta: Meta;
    @Input() MessageFrom: string;
    @Input() isPromoForm = false;
    @Input() TitleForm = 'Te contactaremos';
    @Input() Title = 'Contactar con <b><i>ventas</i></b>';
    @Input() contactList: Contact[] = CONTACTS;
    @Input() isRent?: boolean;
    @Input() isRequestQuote?: boolean;
    @Input() isContactTUU?: boolean;

    @ViewChild(FormGroupDirective, { static: true }) formDirective: FormGroupDirective;
    // @ViewChild(ReCaptchaComponent) recaptcha: ReCaptchaComponent;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private apiService: ApiService,
        private _http: HttpClient,
        private _meta: MetaService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private reCaptchaV3Service: ReCaptchaV3Service,
    ) {
        this.allContactList = CONTACTS;
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 100);

        this.region = COUNTRY_REGION;
        this._meta.setTitle(this.Meta.title);
        this._meta.setTag('description', this.Meta.description);
        let keywords = '';
        this.Meta.keywords.forEach(keyword => {
            if (keywords[keywords.length - 1] === keyword) {
                keywords = keywords + keyword;
            } else {
                keywords = keywords + keyword + ', ';
            }
        });

        this._meta.setTag('keywords', keywords);
        this._meta.setTag('author', this.Meta.author);
        this._meta.setTag('og:image:type', 'image/' + this.Meta.image_type);
        this._meta.setTag('og:image', this.Meta.image_path);
        this._meta.setTag('og:image:width', this.Meta.image_width);
        this._meta.setTag('og:image:height', this.Meta.image_height);
        this._meta.setTag('og:image:alt', 'Contactar con ventas');
        this._meta.setTag('og:locale', this.Meta.locale);
        this.contact = getContact(this.TLD, this.contactList);

        this.isContactTUU=true;

        this.form = this.isRent 
            ? buildFromPartners(this.formBuilder, this.contact.country_code) 
            : (this.isRequestQuote 
                ? buildFromRequestQuote(this.formBuilder, this.contact.country_code) 
                : (this.isContactTUU 
                    ? buildFormContactTUU(this.formBuilder, this.contact.country_code) 
                    : buildBasicForm(this.formBuilder, this.contact.country_code)));


        this.changeValidation(this.contact);

        this.phonehrefSelected = this.contact.main_phone_href;
        this.phoneSelected = this.contact.main_phone;
        this.citySelected = this.contact.city;
        this.countrySelected = this.contact.country;
        this.flagSelected = this.contact.flag_url;

        if (isPlatformBrowser(this.platformId)) {
            this.StartCaptcha();
        }
    }

    

    // captchaExpired() {
    //     this.activeCaptcha = false;
    // }

    // handleCorrectCaptcha(ev) {
    //     this.activeCaptcha = true;
    //     this.captcha = ev;
    // }

    btnMsg(): string {
        if (this.disableButton === false) {
            return 'Enviar';
        } else {
            return 'Enviando Solicitud...';
        }
    }

    track(event: Event,type:string){
        traceButton2(event,type);
    }

    onSubmit(): void {
        if (this.form.invalid) {
            this.showMessage('¡Ups! Ocurrió un error al enviar sus datos de contacto.', 'REINTENTAR', 0, true);
            return;
        }

        this.disableButton = true;
        this.isReadOnly = true;
        const values = this.form.value;
        values['g-recaptcha-response'] = this.captcha;

        const fullName = divideNames(values.name);
        const gSheetsBody = {
            name: fullName[0],
            lastname: fullName[1],
            email: values.email,
            phone: this.contact.phone_code + sanitizePhone(values.phone),
            schedule: values.schedule,
            triggeredBy: "tuu_contactar_ventas"
        };

        this.apiService.sendContactFormTUU(gSheetsBody).subscribe({
            next: () => {
                this.showMessage(
                    '¡Solicitud creada! Será contactado en breve por nuestro departamento de ventas',
                    'CERRAR',
                    10000
                );
                this.formDirective.resetForm();
                this.form.get('phone').setValue(EMPTY_PHONE_PLACEHOLDER);
                this.activeCaptcha = false;
                this.disableButton = false;
                this.isReadOnly = false;
            },
            error: (err) => {
                this.showMessage(
                    '¡Ups! Ocurrió un error al enviar sus datos de contacto',
                    'REINTENTAR',
                    0,
                    true
                );
                this.isReadOnly = false;
                this.disableButton = false;
            },
            complete: () => {
                this.StartCaptcha();
            }
        });            
            
    }

    changeValidation(contact: Contact): void {
        this.form = changeValidation(this.form, contact.country_code);
        this.hidden = changeCountry(contact.country_code);
    }

    // show spinner of the button
    sendForm(): void {
        this.isSendForm = true;

        setTimeout(() => {
            this.isSendForm = false;
        }, 3000);
    }

    showMessage(bodyText, btnText, duration, reload?): void {
        const bar = this.snackBar.open(bodyText, btnText,
        {
            duration: duration,
            panelClass: 'snackBar-btn'
        });

        if (reload) {
            bar.afterDismissed().subscribe(() => location.reload());
        }
    }

    // selectedCountry(contrySelected) {
    //     const selectedItem = this.allContactList.filter(item => item.country === contrySelected)[0];
    //     this.citySelected = selectedItem.city;
    //     this.countrySelected = selectedItem.country;
    //     this.flagSelected = selectedItem.flag_url;
    //     this.phoneSelected = selectedItem.main_phone;
    //     this.contact = selectedItem;
    // }


    StartCaptcha(): void {
        this.reCaptchaV3Service.execute(this.siteKey,
            'contactar_ventas',
            (token) => {
                this.activeCaptcha = true;
                this.captcha = token;
                let element = document.getElementsByClassName("grecaptcha-badge")[0];
                element.setAttribute('style', element.getAttribute('style') + 'visibility: hidden;');
        });
    }

    changeComuna(comuna: string): void {
        this.comuna = comuna;
    }

    changeRegion(region: string): void {
        this.region = region;
    }

    onPhoneBlur(event: any): void {
        if (this.form.get('phone').value == EMPTY_PHONE_VALUE) {
            this.form.get('phone').setValue(EMPTY_PHONE_PLACEHOLDER);
        }
    }
    
    onPhoneFocus(event: any): void {
        if (this.form.get('phone').value == EMPTY_PHONE_PLACEHOLDER) {
            this.form.get('phone').setValue(EMPTY_PHONE_VALUE);
        }
    }
    getCurrentCountry() {
        const country = this.contactList.filter((c) => c.country_code === this.currentCountryCode);
        return country[0];
    }
    setPhoneErrorMessage(): void {
        this.phoneErrorMessage = getPhoneErrorMessage(this.form, this.contact.country_code);
    }
    phonePlaceholder(): string {
        return getPhonePlaceholder(this.contact.country_code);
    }

    setEmailError(): void {
        this.emailErrorMessage = getEmailErrorMessage(this.form.get('email'));
    }
}
