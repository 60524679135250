import {
    AfterViewInit,
    Component,
    Input,
    // Inject,
    // OnInit,
    // PLATFORM_ID,
    // ViewChild,
} from '@angular/core';
// import { Contact } from '../../../../../common/Contact';
// import { CONTACTS } from '../../../app/contacts.constants';
// import { ApiService } from '../../shared/services/api.service';
// import {
//     FormControl,
//     FormGroup,
//     FormGroupDirective,
//     Validators,
// } from '@angular/forms';
// import { ReCaptchaV3Service } from 'ngx-captcha';
// import { isPlatformBrowser } from '@angular/common';
// import { HttpClient } from '@angular/common/http';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { MAX_EMAIL_CHARACTERS, customEmailValidator, getEmailErrorMessage, sanitizePhone, getPhonePlaceholder, getPhoneErrorMessage, divideNames, changeValidation, changeCountry, EMPTY_PHONE_VALUE, EMPTY_PHONE_PLACEHOLDER, COUNTRY_REGION, COUNTRY_STRING } from '../../../../../common/contact-shared/contact-shared.component';
declare var hbspt: any;
@Component({
    selector: 'app-section-support-partners',
    templateUrl: './section-support-partners.component.html',
    styleUrls: ['./section-support-partners.component.scss']
})
export class SectionSupportPartnersComponent implements /*OnInit,*/ AfterViewInit {
    // @ViewChild(FormGroupDirective, { static: true })
    // formDirective: FormGroupDirective;
    // public contact: Contact;
    @Input() public loadingImages: boolean;
    // public allContactList: any;
    // private region: any;
    // private activeCaptcha = false;
    // public disableButton = false;
    // public isReadOnly = false;
    // public hidden: boolean;
    // public emailErrorMessage: string;
    // public phoneErrorMessage: string;
    // private captcha: any;
    // private TLD: string = 'cl';
    // private contactList: Contact[] = CONTACTS.filter(c => c.country_code === 'cl');
    // siteKey = '6LeFGJMUAAAAAEDLJLme8uOEGMOTwsjYB6v-gZe7';

    // public phone: FormControl = new FormControl(EMPTY_PHONE_VALUE, [
    //     Validators.required
    // ]);
    // public email: FormControl = new FormControl('', [
    //     Validators.required,
    //     customEmailValidator(),
    //     Validators.maxLength(MAX_EMAIL_CHARACTERS),
    // ]);
    // public name: FormControl = new FormControl('', [
    //     Validators.required,
    //     Validators.minLength(3),
    //     Validators.maxLength(100),
    // ]);
    // public form: FormGroup = new FormGroup({
    //     phone: this.phone,
    //     email: this.email,
    //     name: this.name,
    // });

    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    constructor(
        // @Inject(PLATFORM_ID) private platformId: any,
        // private reCaptchaV3Service: ReCaptchaV3Service,
        // private _http: HttpClient,
        // private snackBar: MatSnackBar,
        // private apiService: ApiService,
    ) {
        // this.allContactList = CONTACTS;
    }

    // ngOnInit(): void {
    //     this.contact = this.getContact(this.TLD);
    //     if (isPlatformBrowser(this.platformId)) {
    //         this.StartCaptcha();
    //     }
    //     //Default Properties
    //     this.region = COUNTRY_REGION;

    //     this.changeValidation(this.contact)
    // }

    ngAfterViewInit(): void {
        if (hbspt !== undefined){
            hbspt.forms.create({
                portalId: "47115837",
                formId: "3907a3a8-31de-4311-89a7-284eb75d8562",
                target: '#hubspotForm'
            });
        }
    }

    // private StartCaptcha(): void {
    //     this.reCaptchaV3Service.execute(
    //         this.siteKey,
    //         'contactar_ventas',
    //         (token) => {
    //             this.activeCaptcha = true;
    //             this.captcha = token;
    //             let element =
    //                 document.getElementsByClassName('grecaptcha-badge')[0];
    //             element.setAttribute(
    //                 'style',
    //                 element.getAttribute('style') + 'visibility: hidden;'
    //             );
    //         }
    //     );
    // }

    // changeValidation(contact: Contact): void {
    //     this.form = changeValidation(this.form, contact.country_code);
    //     this.hidden = changeCountry(contact.country_code);
    // }

    // public getContact(TLD: string): Contact {
    //     const aux = TLD === 'mx' ? 'me' : TLD;
    //     return this.contactList.filter((c) => c.country_code === aux)[0];
    // }

    // private showMessage(bodyText, btnText, duration, reload?): void {
    //     const bar = this.snackBar.open(bodyText, btnText, {
    //         duration: duration,
    //         panelClass: 'snackBar-btn',
    //     });

    //     if (reload) {
    //         bar.afterDismissed().subscribe(() => location.reload());
    //     }
    // }

    // btnMsg(): string {
    //     if (this.disableButton === false) {
    //         return 'Enviar';
    //     } else {
    //         return 'Enviando Solicitud...';
    //     }
    // }

    // public sendForm(): void {
    //     this.disableButton = true;
    //     this.isReadOnly = true;
    //     const values = this.form.value;
    //     const body = {
    //         title: 'TUU',
    //         nombreCompleto: values.name,
    //         telefono: sanitizePhone(values.phone),
    //         email: values.email,
    //         captcha: this.captcha,
    //         codigoPais: this.contact.phone_code.substr(1),
    //         pais: COUNTRY_STRING,
    //     };

    //     const fullName = divideNames(values.name);
    //     const gSheetsBody = {
    //         name: fullName[0],
    //         lastname: fullName[1],
    //         email: values.email,
    //         phone: this.contact.phone_code + sanitizePhone(values.phone),
    //         triggeredBy: 'tuu_partners_website'
    //     };

    //     this.apiService.sendContactFormTUU_Partners(gSheetsBody).subscribe({
    //         next: () => {
    //             this.showMessage(
    //                 '¡Solicitud creada! Será contactado en breve por un ejecutivo',
    //                 'CERRAR',
    //                 10000
    //             );
    //             this.formDirective.resetForm();
    //             this.form.get('phone').setValue(EMPTY_PHONE_PLACEHOLDER);
    //             this.activeCaptcha = false;
    //             this.disableButton = false;
    //             this.isReadOnly = false;
    //         },
    //         error: (err) => {
    //             this.showMessage(
    //                 '¡Ups! Ocurrió un error al enviar sus datos de contacto',
    //                 'REINTENTAR',
    //                 0,
    //                 true
    //             );
    //             this.isReadOnly = false;
    //             this.disableButton = false;
    //         },
    //         complete: () => {
    //             this.StartCaptcha();
    //         }
    //     });
    // }

    // setPhoneErrorMessage(): void {
    //     this.phoneErrorMessage = getPhoneErrorMessage(this.form, this.contact.country_code);
    // }
    // phonePlaceholder(): string {
    //     return getPhonePlaceholder(this.contact.country_code);
    // }

    // setEmailError(): void {
    //     this.emailErrorMessage = getEmailErrorMessage(this.form.get('email'));
    // }

}
