import { Component, ElementRef } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../../../../../common/scrollTo.class';
import { Router } from '@angular/router';

@Component({
    selector: 'app-start-accepting-payments',
    templateUrl: './start-accepting-payments.component.html',
    styleUrls: ['./start-accepting-payments.component.scss'],
})
export class StartAcceptingPaymentsComponent extends ScrollToClass {

    constructor(
        private _el: ElementRef,
        private router: Router,
    ) {
        super(_el);
    }

    track(event: Event) {
        traceButton2(event, 'contact');
    }

    public showPosHome(): void {
        this.router.navigate(['/']).then(() => {
            window.scroll(0, 0);
            setTimeout(() => {
                this.scrollToSection("sectionPrice");
            }, 500);
        });
        this.scrollToSection("sectionPrice");
    }
}
