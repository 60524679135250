import { Component, Input } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { urlEnv } from '../../../environments/environment';

@Component({
    selector: 'app-section-costs-and-restrictions',
    templateUrl: './section-costs-and-restrictions.component.html',
    styleUrls: ['./section-costs-and-restrictions.component.scss']
})
export class SectionCostAndRestrictionsComponent {
    private urlEnv = urlEnv;
    @Input() public loadingImages: boolean;

    cards: Array<{ icon: string; title: string; text: string }> = [
        {
            icon: 'coins-stacked-01',
            title: 'Recargo de comisión',
            text: 'Solo aplica si tienes configurado abono en más de 1 día hábil. El monto del abono que solicites se le aplicará la tarifa estándar de abono de 1 día hábil.'
        },
        {
            icon: 'icon-sprite-clock',
            title: 'Horario disponible',
            text: 'Abono Inmediato está disponible de Lunes a Domingo en horario:'
        }
    ];

    constructor(
        public buttonTraceService: ButtonTraceService,
    ) {
    }

    public goToUrl(): void {
        window.open(this.urlEnv.HAULMER_LEGAL+"clausula-abono-inmediato/", '_blank');
    }
}