import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {API} from '../../environments/environment'


@Component({
    selector: 'app-redirect-page',
    templateUrl: './redirect-page.component.html',
    styleUrls: ['./redirect-page.component.scss']
})
export class RedirectPageComponent {

    private cookie1 = 'tuupA';
    private cookie2 = 'tuupB';
    public isDisabled = false;
    public API = API;

    constructor(
        private _router: Router,
        private _http: HttpClient,
        private snackBar: MatSnackBar,
    ){
        const urlTree  = this._router.parseUrl(this._router.url);
        const queryString = urlTree.queryParams;
        try{
            const invoice = queryString['a'];
            const mail = queryString['b'];
            if(invoice && mail){
                this.main(invoice,mail)
            }
        }
        catch(e){
            console.log(e)
        }
    }

    private main(invoice, mail){
        const cleanUrl = this._router.url.split('?')[0]; // Eliminar los parámetros de la URL

        // Redirigir a la misma URL pero sin los parámetros
        this._router.navigate([cleanUrl]);
        const tuupA = this.getCookie(this.cookie1);
        const tuupB = this.getCookie(this.cookie2);

        if (!tuupA || !tuupB) {
            this.setCookie(this.cookie1,invoice);
            this.setCookie(this.cookie2, mail);
        }
        else if(tuupA!=invoice || tuupB!=mail){
            this.setCookie(this.cookie1,invoice);
            this.setCookie(this.cookie2, mail);
        }

        
    }

    private getCookie(name: string): string | null {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    }

    // Método para establecer una cookie
    private setCookie(name: string, value: string): void {
        const date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30 días de expiración
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/`;
    }

    public sendPostRequest() {
        const param1 = atob(this.getCookie(this.cookie1));
        const param2 = atob(this.getCookie(this.cookie2));
        this.isDisabled = true;
    
        const urlReq = API.API_REDIRECT;
    
        // Crear los parámetros para la solicitud GET
        const params = new HttpParams()
        .set('invoiceid', param1)
        .set('emailid', param2);

        this._http.get(urlReq, { params })
        .subscribe({
            next: () => {
                this.showMessage(
                    '¡Solicitud creada! Será contactado en breve por nuestros ejecutivos',
                    'CERRAR',
                    10000
                );
            },
            error: (error) => {
                this.showMessage(
                    '¡Ups! Ocurrió un error al enviar sus datos, por favor intente mas tarde',
                    'REINTENTAR',
                    5000
                );
            }
        });
    }

    showMessage(bodyText, btnText, duration, reload?): void {
        const bar = this.snackBar.open(bodyText, btnText,
        {
            duration: duration,
            panelClass: 'snackBar-btn'
        });

        if (reload) {
            bar.afterDismissed().subscribe(() => location.reload());
        }
    }
}