import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { CONTACTS, IContact } from '../contacts.constants';
import { urlEnv } from '../../environments/environment';
import { ProjectsUrl } from '../../../../common/projects-url';
import { isPlatformBrowser } from '@angular/common';
import { SEOService } from '../shared/services/seo.service';
import { traceButton2 } from '../../../../common/services/button-trace';

declare var MessageBirdChatWidget: any;

@Component({
    selector: 'app-contact-support',
    templateUrl: './contact-support.component.html',
    styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent extends ProjectsUrl implements OnInit {
    @ViewChild('ticket', { static: true }) public ticket: ElementRef;
    @ViewChild('contacts', { static: true }) public contacts: ElementRef;

    listUrl;
    linkSales;
    linkSupport;
    linkPayment;

    contactList: Array<IContact>;
    public urlEnv = urlEnv;

    constructor(
        private _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any,
        private seoService: SEOService
    ) {
        super();
        this.contactList = CONTACTS.filter(item => item.country === 'Chile');
    }

    ngOnInit() {
        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 100);

        this._setMeta();
        this.seoService.updateCanonicalUrl('contactar-atencion-cliente/');

        this.listUrl = this.getProjectList('tuu');
        this.linkSales = this.listUrl.link_sales;
        this.linkSupport = this.listUrl.link_support;
        this.linkPayment = this.listUrl.link_payment;
    }

    scrollTo(target: string) {
        if (target === 'ticket') {
            this.ticket.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        } else if (target === 'contacts') {
            this.contacts.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    track(event:Event){
        traceButton2(event,'contact');
    }

    private _setMeta() {
        this._meta.setTitle('Contactar Atención al Cliente | Haulmer');
        this._meta.setTag('description', 'Envíanos tu ticket al área de Atención al Cliente. Para resolver tus dudas e inconvenientes acude al centro de soporte técnico de Haulmer; disponible 24/7 todo el año.');
        this._meta.setTag('keywords', 'contactar con soporte, servicio técnico, soporte, servicios de soporte, soporte técnico, atención al cliente');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-support.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Contactar Atención al Cliente, Haulmer');
    }

    public openChat() {
        MessageBirdChatWidget.toggleChat(true);
    }

    public openWhatsapp() {
        window.open('https://wa.me/56930834861?text=Quiero+conocer+más+información+de+planes+y+servicios', '_blank');
    }

    public openMail() {
        window.open('mailto:soporte@haulmer.net', '_blank');
    }
}
