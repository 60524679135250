import { Component, Input } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { Router } from '@angular/router';

@Component({
    selector: 'app-section-sales',
    templateUrl: './section-sales.component.html',
    styleUrls: ['./section-sales.component.scss']
})
export class SectionSalesComponent {
    @Input() public loadingImages: boolean;

    constructor(
        public buttonTraceService: ButtonTraceService,
        private router: Router
    ) {}

    public goTo(): void {
        this.router.navigate(['/contactar-con-ventas']);
    }
}
