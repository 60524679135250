import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CONTACTS } from '../../contacts.constants';
import { DATA_FOOTER } from '../../footer-data';
import { urlEnv } from '../../../environments/environment';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { urlMapping } from '../route-ids';
import { ScrollToService } from '../services/scroll-to';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public urlEnv = urlEnv;
    contact = CONTACTS[0];
    countrySelected: string;
    flagSelected: string;

    dataFooterTuu = DATA_FOOTER.dataFooterTuu;
    // dataFooterService = DATA_FOOTER.dataFooterService;
    dataFooterProgram = DATA_FOOTER.dataFooterProgram;
    dataFooterSupport = DATA_FOOTER.dataFooterSupport;

    currentRoute;
    @Input() isDirect: boolean;

    public url:string;

    constructor(
        private _router: Router,
        public buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) {
        this.currentRoute = this._router.url;
    }

    ngOnInit() {
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
        this.dataFooterTuu.forEach(item => item.id1 = 't-'+this.url+'-');
        this.dataFooterProgram.forEach(item => item.id1 = 't-'+this.url+'-');
        this.dataFooterSupport.forEach(item => item.id1 = 't-'+this.url+'-');
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    goToUrlWrapper(event: Event, url: string): void {
        event.preventDefault(); // Prevent the default behavior of the link
        this.goToUrl(url); // Call the original function
    }

    goTo(event: Event, url: string): void {
        event.preventDefault();
        this._router.navigate([url]);
    }

    navigateToSection(obj): void {
        if (!!obj.anchor_link) {
            if (location.pathname === '/contactar-con-ventas') {
                this.goToUrl(location.origin + '?class=' + obj.anchor_link);
            }
            setTimeout(() => {
                this.scrollToService.scrollToSection(obj.anchor_link);
            }, 200);
        }
    }
}
