import { Component, HostListener, Inject, OnInit, PLATFORM_ID, } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MetaService } from '@ngx-meta/core';
import { urlEnv } from '../../environments/environment';
import { DialogPlansComponent } from '../shared/dialogs/dialog-plans/dialog-plans.component';
import { DialogSeeProduct2Component } from '../shared/dialogs/dialog-see-product2/dialog-see-product2.component';
import { DialogSinatureComponent } from '../shared/dialogs/dialog-sinature/dialog-sinature.component';
import { isPlatformBrowser } from '@angular/common';
import { Card, IDeviceFeature, SelectOption, Tariff, TariffOption, dataOptions, dataType, templateOPtionsGeneral, templateOPtionsSemi } from './data-installment-payment';
import { CurrencyLocalePipe } from '../currency.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from '../shared/services/url.service';
import { traceButton2 } from '../../../../common/services/button-trace';
import { urlMapping } from '../shared/route-ids';

@Component({
    selector: 'app-installment-payment',
    templateUrl: './installment-payment.component.html',
    styleUrls: ['./installment-payment.component.scss'],
})
export class InstallmentPaymentComponent implements OnInit {
    private urlEnv = urlEnv;
    public showFire: boolean = false;
    public fireText: string = '';
    public fireTextRegion: string;
    public textRM: string = '';
    public textRegion: string = '';
    public total = 0;
    public isTablet: boolean;
    public counterPayment = 1;
    public counterCodeReader = 1;
    public counterSiliconCase: number = 1;
    public counterGlassPlate: number = 1;
    public url: string;
    private promoCode: string = '';
    private urlCart = urlEnv.TUU_CART_URL;

    public isBtnDisabled: boolean = true;
    private pickChip: boolean = false;
    private pickPrice: boolean = false;

    public isDirect: boolean = false;
    public urlPlan: string = '';

    public data: Array<Tariff>;
    public deviceFeature: IDeviceFeature = {
        name: '',
        device: '',
        options: [],
        features: [],
        delivery: {
            fireText: undefined,
            showFire: false,
            textRM: '',
            textRegion: ''
        }
    };;
    public dataType: string;

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.isTablet = window.innerWidth <= 992;
    }

    constructor(
        private readonly _meta: MetaService,
        public dialog: MatDialog,
        @Inject(PLATFORM_ID) private platformId: Object,
        private _currencyPipe: CurrencyLocalePipe,
        private activatedRoute: ActivatedRoute,
        private urlService: UrlService,
        private router: Router
    ) { }

    public ngOnInit(): void {
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.url = urlMapping[urlSegment] || urlSegment;
        if(this.url === 'directa'){
            this.isDirect = true;
            this.urlPlan = 'directa/';
        }


        this.activatedRoute.data.subscribe(data => {
            if (data?.name) this.dataType = data?.name;
        });
        if (isPlatformBrowser(this.platformId)) {
            this._setMeta();
            this.isTablet = window.innerWidth <= 992;
        }
        this.getDataDevice();
        // Checking for isSelected inside dataOptions
        this.data.forEach((x, indexX) => {
            x.options.forEach((y, indexY) => {
                if (y.cards) {
                    y.cards.forEach((z, indexZ) => {
                        z.count = 1;
                        if (z.isSelected) {
                            this.selectCard(indexX, indexY, indexZ);
                        }
                    });
                }
            });
        });
    }

    public getDataDevice(): void {
        switch (true) {
            case (this.dataType === 'mini' && this.urlService.getValue('comision') === 'true'):
                this.data = templateOPtionsGeneral;
                this.data[0].options[0].cards[0].id = dataOptions[this.dataType].commission.id;
                this.data[0].options[0].cards[0].price = dataOptions[this.dataType].commission.price;
                this.data[0].options[1].cards[0].id = dataOptions[this.dataType].commission.installmentId;
                this.data[0].options[1].cards[0].title = dataOptions[this.dataType].commission.installmentText;
                this.data[0].options[1].cards[0].price = dataOptions[this.dataType].commission.installmentPrice;
                break
            case (
                this.dataType === 'pro-semi-nueva' ||
                this.dataType === 'mini-semi-nueva' ||
                this.dataType === 'mini-s-semi-nueva' ||
                this.dataType === 'se-semi-nueva'
            ):
                this.data = templateOPtionsSemi;
                this.data[0].options[0].cards[0].id = dataOptions[this.dataType].id;
                this.data[0].options[0].cards[0].price = dataOptions[this.dataType].price;
                break
            default:
                this.data = templateOPtionsGeneral;
                this.data[0].options[0].cards[0].id = dataOptions[this.dataType].id;
                this.data[0].options[0].cards[0].price = dataOptions[this.dataType].price;
                this.data[0].options[1].cards[0].id = dataOptions[this.dataType].installmentId;
                this.data[0].options[1].cards[0].title = dataOptions[this.dataType].installmentText;
                this.data[0].options[1].cards[0].price = dataOptions[this.dataType].installmentPrice;
                break;
        }
        this.data = dataOptions[this.dataType].options ? this.data.concat(dataOptions[this.dataType].options) : this.data;
        this.deviceFeature.name = dataType[this.dataType].name;
        this.deviceFeature.device = dataType[this.dataType].device;
        this.deviceFeature.options = dataType[this.dataType].options;
        this.deviceFeature.features = dataType[this.dataType].features;

        this.fireText = dataType[this.dataType].delivery.fireText;
        if (dataType[this.dataType].delivery.fireTextRegion) {
            this.fireTextRegion = dataType[this.dataType].delivery.fireTextRegion;
        }
        this.textRM = dataType[this.dataType].delivery.textRM;
        this.textRegion = dataType[this.dataType].delivery.textRegion;
        this.showFire = dataType[this.dataType].delivery.showFire;
    }

    public selectCard(
        indexOptions: number,
        indexCategory: number,
        indexCard: number
    ): void {
        const currentOptions = this.data[indexOptions];
        const currentCategory = currentOptions.options[indexCategory];
        if (
            currentCategory.title === 'Plan' ||
            currentCategory.title === 'Firma electrónica' ||
            currentCategory.title === 'Lector de código' ||
            currentCategory.title === 'Lámina protectora de pantalla' ||
            currentCategory.title === 'Funda de silicona'
        ) {
            currentCategory.cards.forEach((card) => (card.isSelected = false));
        } else {
            const newAarr = currentOptions.options.filter(
                (option) => option.title !== 'Plan'
            );
            newAarr.forEach((option) => {
                option.cards.forEach((card) => (card.isSelected = false));
            });
        }
        currentCategory.cards[indexCard].isSelected = true;
        this.checkTotal();
    }

    public selectColumnsCard(arr: Array<{ isSelected: unknown }>, index: number): void {
        arr.forEach((item, i) => item.isSelected = i === index);
        this.checkTotal();
    }

    public changeCounter(action: string, type: string): void {
        let installment = "";
        let checkQuota = false;
        if (this.data[0].options.length > 1) {
            installment = this.data[0].options[1].cards[0].title.split('+')[1];
            checkQuota = true;
        }
        if (type === 'payment') {
            if (action === 'inc') {
                this.counterPayment = this.counterPayment + 1;
                // al contado
                this.data[0].options[0].cards[0].count = this.counterPayment;
                // seleccionado pago en cuotas
                if (checkQuota) {
                    this.data[0].options[1].cards[0].count = this.counterPayment;
                    this.data[0].options[1].cards[0].title = `Pie de $${this._currencyPipe.transform(
                        this.data[0].options[1].cards[0].price * this.counterPayment
                    )} + ${installment}`;
                }
                this.checkTotal();
            } else {
                this.counterPayment = this.counterPayment - 1;
                this.data[0].options[0].cards[0].count = this.counterPayment;
                if (checkQuota) {
                    this.data[0].options[1].cards[0].count = this.counterPayment;
                    this.data[0].options[1].cards[0].title = `Pie de $${this._currencyPipe.transform(
                        String(
                            this.data[0].options[1].cards[0].price *
                            this.counterPayment
                        )
                    )} + ${installment}`;
                }
                this.checkTotal();
            }
        } else if (type === 'codeReader') {
            if (action === 'inc') {
                this.counterCodeReader = this.counterCodeReader + 1;
            } else {
                this.counterCodeReader = this.counterCodeReader - 1;
            }
        } else if (type === 'siliconCase') {
            if (action === 'inc') {
                this.counterSiliconCase += 1;
                this.data[2].options[0].cards[1].count = this.counterSiliconCase;
                this.checkTotal()
            } else {
                this.counterSiliconCase -= 1;
                this.data[2].options[0].cards[1].count = this.counterSiliconCase;
                this.checkTotal();
            }
        } else if (type === 'glassPlate') {
            if (action === 'inc') {
                this.counterGlassPlate += 1;
                this.data[2].options[1].cards[1].count = this.counterGlassPlate;
                this.checkTotal();
            } else {
                this.counterGlassPlate -= 1;
                this.data[2].options[1].cards[1].count = this.counterGlassPlate;
                this.checkTotal();
            }
        }
    }

    public getCurrentCounter(option: TariffOption): number {
        if (option.title === 'Al contado' || option.title === 'En cuotas') {
            return this.counterPayment;
        } else if (option.title === 'Funda de silicona') {
            return this.counterSiliconCase;
        } else if (option.title === 'Lámina protectora de pantalla') {
            return this.counterGlassPlate;
        }
        return this.counterCodeReader;
    }

    public openModal(text: string, _card?: Card): void {
        switch (text) {
            case 'Plan':
                this.openModalPlans();
                break;
            case 'Firma electrónica':
                this.openModalSinature();
                break;
            case 'Lector de código':
                this.openDialogSeeProduct();
                break;
        }
    }

    public openModalPlans(): void {
        this.dialog.open(DialogPlansComponent, {
            panelClass: 'dialogPlans',
            width: '816px',
        });
    }

    public openModalSinature(): void {
        this.dialog.open(DialogSinatureComponent, {
            panelClass: 'dialogSignature',
            width: '816px',
        });
    }

    public openDialogSeeProduct(): void {
        this.dialog.open(DialogSeeProduct2Component, {
            panelClass: 'dialogWrapper',
            width: '680px',
        });
    }

    public isShowMore(title: string): boolean {
        if (
            title === 'Plan' ||
            title === 'Firma electrónica' ||
            title === 'Lector de código'
        ) {
            return true;
        }
        return false;
    }

    public selectOption(card: Card, option: SelectOption): void {
        card.id = option.productId;
        card.title = option.title;
        card.price = option.price;
        card.sup_value = option.sup_value;
        this.checkTotal();
    }

    private _setMeta(): void {
        switch (this.dataType) {
            case "mini":
                if (this.urlService.getValue('comision') === 'true') {
                    this._meta.setTitle(
                        'TUU Mini, un POS portátil para recibir pagos donde sea'
                    );
                    this._meta.setTag(
                        'description',
                        'La máquina POS económica y de bolsillo que funciona de forma autónoma, sin necesidad de conectarla a un celular. Recibe pagos con tarjetas desde 1,89%.'
                    );
                }
                else {
                    this._meta.setTitle(
                        'TUU Mini, un POS portátil para recibir pagos donde sea'
                    );
                    this._meta.setTag(
                        'description',
                        'La máquina POS económica y de bolsillo que funciona de forma autónoma, sin necesidad de conectarla a un celular. Recibe pagos con tarjetas desde 0,98%.'
                    );
                }
                break;
            case "mini-s":
                this._meta.setTitle(
                    'TUU Mini S, POS para recibir pagos con escáner integrado'
                );
                this._meta.setTag(
                    'description',
                    'El terminal POS con escáner profesional integrado que funciona de forma autónoma, sin necesidad de conectar a un celular. Recibe pagos con tarjetas desde 0,98%'
                );
                break;
            case "pro":
                this._meta.setTitle(
                    'TUU Pro, el POS más completo para recibir pagos con tarjetas'
                );
                this._meta.setTag(
                    'description',
                    'La máquina POS todo-en-uno para recibir pagos con tarjetas con la comisión más baja desde 0,98%. Integra emisión de boleta electrónica e impresora térmica.'
                );
                break;
            case "se":
                this._meta.setTitle(
                    'TUU SE, el POS más completo para recibir pagos con tarjetas'
                );
                this._meta.setTag(
                    'description',
                    'La máquina POS todo-en-uno para recibir pagos con tarjetas con la comisión más baja desde 0,98%. Integra emisión de boleta electrónica e impresora térmica.'
                );
                break;
            //case "pro-semi-nueva":
            //break;
            default:
                this._meta.setTitle(
                    'Máquina para pagar con tarjeta y emitir boleta electrónica'
                );
                this._meta.setTag(
                    'description',
                    'Compra en cuotas TUU máquina para recibir pagos con tarjetas. Olvídate de arriendos y aprovecha comisiones flexibles con abono desde un día hábil'
                );
                break;
        }
        this._meta.setTag(
            'keywords',
            'Máquina para pagar con tarjeta, máquina para cobrar con tarjeta, máquina para recibir pagos con tarjeta, dispositivo para pagar con tarjeta, POS, terminal POS, máquina POS, sistema de pago con tarjeta, dispositivo para pagar con tarjeta, Boleta electrónica, máquina boleta electrónica, emitir boleta, máquina para imprimir boleta, sistema boleta electrónica, Punto de venta, sistema de venta, software punto de venta,POS, control de inventario, gestión de inventario, control de stock.'
        );
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag(
            'og:image',
            this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-home.png'
        );
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag(
            'og:image:alt',
            'Máquina para pagar con tarjeta y emitir boleta electrónica'
        );
    }

    private checkTotal(): void {
        this.total = 0;
        this.data.forEach((x) => {
            x.options.forEach((y) => {
                if (y.cards) {
                    y.cards.forEach((z) => {
                        if (z.isSelected && !!z.price) {
                            if (y.title === 'En cuotas' || y.title === 'Al contado') {
                                this.pickPrice = true;
                            }
                            this.total += z.price * (z.count ?? 1);
                        }
                    });
                }
                if (y.columnCards)
                    y.columnCards.forEach((z) => {
                        if (z.isSelected) {
                            if (z.title == 'Entel' || z.title == 'Movistar') this.pickChip = true;
                        }
                    })
            });
        });
        if (this.pickPrice && this.pickChip) this.isBtnDisabled = false;
        if (this.deviceFeature.name == "Mini") this.isBtnDisabled = true;
    }

    public onSubmit(): void {
        let subproduct = '';
        let qty = '';
        let cartPrices = {};
        let productQuantity = 1;
        this.data.forEach((x) => {
            x.options.forEach((y) => {
                if (y.cards !== undefined) {
                    y.cards.forEach((z) => {
                        if (z.isSelected) {
                            switch (y.title) {
                                case 'En cuotas':
                                case 'Al contado':
                                    cartPrices[z.id.split(",")[0]] = z.price
                                    subproduct = subproduct + z.id;
                                    qty = qty + z.count + ',';
                                    productQuantity = z.count;
                                    break;
                                case 'Rollos de papel térmico':
                                    if (z.title != 'No gracias') {
                                        if (z.id === undefined) {
                                            cartPrices[z["productId"].slice(0, -1)] = z.price
                                            subproduct = subproduct + z["productId"]
                                        }
                                        else {
                                            cartPrices[z.id.slice(0, -1)] = z.price
                                            subproduct = subproduct + z.id;
                                        }
                                        qty = qty + '1,';
                                    }
                                    break;
                                case 'Firma electrónica':
                                    if (z.title != 'No gracias') {
                                        if (z.id === undefined) {
                                            cartPrices[z["productId"].slice(0, -1)] = z.price
                                            subproduct = subproduct + z["productId"];
                                        }
                                        else {
                                            cartPrices[z.id.slice(0, -1)] = z.price
                                            subproduct = subproduct + z.id;
                                        }
                                        qty = qty + '1,';
                                    }
                                    break;
                                default:
                                    break;
                            }
                            if (z.title == 'Funda de silicona') {
                                cartPrices[z.id.slice(0, -1)] = z.price
                                subproduct = subproduct + z.id;
                                qty = `${qty}${this.counterSiliconCase},`;
                            }
                        }
                    });
                }
                else {
                    y.columnCards.forEach((z) => {
                        if (z.isSelected) {
                            cartPrices[z.id.split(",")[0]] = 0
                            subproduct = subproduct + z.id;
                            qty = qty + productQuantity + ',';
                        }
                    })
                }
            });
        });
        subproduct = subproduct.replace(/\,$/, '');
        qty = qty.replace(/\,$/, '');
        if (isPlatformBrowser(this.platformId)) {
            this.usePromoCode();
            if (this.promoCode !== '' && this.promoCode !== null && this.promoCode !== undefined) {
                this.reInterpretPromoCode();
            }
        }

        // const url = `${this.urlCart}subproduct=${subproduct}&qtyproduct=${qty}`
        // this.goToUrl(url);

        const urlProduct = this.urlService.getValue('subproduct');
        const purchaseOrigin = this.urlService.getValue('purchaseOrigin');
        const videoask = this.urlService.getValue('videoask');
        const opciones = this.urlService.getValue('opciones');
        const maquina_r = this.urlService.getValue('maquina_r');
        if (!!urlProduct) {
            var url: URL = this.urlService.addParams(new URL(this.urlEnv.TUU_CART_URL), [
                { name: 'subproduct', value: `${urlProduct},${subproduct}` },
                { name: 'qtyproduct', value: `1,${qty}` }
            ])
            if (urlProduct === 'TUUP') {
                url = this.urlService.addParams(url, [{ name: 'period_opt', value: '1' }])
            }
            if (!!purchaseOrigin) {
                url = this.urlService.addParams(url, [{ name: 'purchaseOrigin', value: purchaseOrigin }])
            }
            if (!!this.promoCode) {
                url = this.urlService.addParams(url, [{ name: 'pr_code', value: this.promoCode }])
            }
            if (!!videoask) {
                url = this.urlService.addParams(url, [{ name: 'videoask', value: videoask }])
            }
            if (!!opciones) {
                url = this.urlService.addParams(url, [{ name: 'opciones', value: opciones }])
            }
            if (!!maquina_r) {
                url = this.urlService.addParams(url, [{ name: 'maquina_r', value: maquina_r }])
            }
            this.goToUrl(decodeURIComponent(url.href));
        } else {
            if (!!this.promoCode) {
                this.router.navigate([this.urlPlan+'seleccion-plan'], {
                    queryParams: {
                        subproduct: subproduct,
                        qtyproduct: qty,
                        pr_code: this.promoCode
                    },
                    queryParamsHandling: 'merge'
                });
            } else {
                this.router.navigate([this.urlPlan+'seleccion-plan'], {
                    queryParams: {
                        subproduct: subproduct,
                        qtyproduct: qty
                    },
                    queryParamsHandling: 'merge'
                });
            }
        }
    }

    private usePromoCode(): void {
        this.promoCode = sessionStorage.getItem('prCode');
        sessionStorage.removeItem('prCode');
    }

    private reInterpretPromoCode(): void {
        let promoArray;
        if (this.promoCode.includes(',')) {
            promoArray = this.promoCode.split(',');
            this.promoCode = '';
            switch (this.dataType) {
                case 'mini':
                    for (let code of promoArray) {
                        if (code.toUpperCase().includes('MINI')) {
                            this.promoCode = code;
                            break;
                        }
                    }
                    break;
                case 'mini-s':
                    for (let code of promoArray) {
                        if (code.toUpperCase().includes('MINIS') || code.toUpperCase().includes('MINI-S')) {
                            this.promoCode = code;
                            break;
                        }
                    }
                    break;
                case 'pro':
                    for (let code of promoArray) {
                        if (code.toUpperCase().includes('PRO')) {
                            this.promoCode = code;
                            break;
                        }
                    }
                    break;
                case 'se':
                    for (let code of promoArray) {
                        if (code.toUpperCase().includes('SE')) {
                            this.promoCode = code;
                            break;
                        }
                    }
                    break;
                case 'pro-semi-nueva':
                    for (let code of promoArray) {
                        if (code.toUpperCase().includes('PRO-SEMI-NUEV') ||
                            code.toUpperCase().includes('PRO SEMI NUEV')) {
                            this.promoCode = code;
                            break;
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }

    private goToUrl(url: string): void {
        window.open(url, '_self');
    }

    track(event: Event){
        traceButton2(event,'navigate');
    }
}
