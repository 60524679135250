import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { PopUpExterno } from '../dialog/pop-up.component.TUU';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { IPopUpData } from '../../../../../common/interfaces/pop-up.interface';
import { ApiService } from '../../shared/services/api.service';
@Component({
    selector: 'app-pop-up-externo',
    template: ''
})
export class PopUpGeneralWrapper implements OnInit {
    @Input() enabledLeavingPopUp: boolean;
    @Output() emmiterEnabledLeavingPopUp: EventEmitter<boolean> = new EventEmitter<boolean>();
    private enableCookies: boolean = true;
    private data: IPopUpData;
    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private apiService: ApiService,
        public dialog: MatDialog,
    ) {
    }
    public singleTrigger: boolean = true;
    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            document.addEventListener('disable-leaving-popup', (event) => {
                this.singleTrigger = false;
            });
            this.dialogHandler();
        }
    }

    private awaitTrigger() {
        const awaitTime = 40 * 1000;
        this.apiService
            .getPromoActivation()
            .subscribe((response) => {
                this.data = response;
            },
                (err) => {
                    console.warn(err);
                },
                () => {
                    if (this.data) {
                        if (this.data.showPromo) {
                            this.waitMs(awaitTime).then(() => {
                                this.emmiterEnabledLeavingPopUp.emit(false);
                                this.openDialog();
                            });
                        }
                    }
                });
    }

    private waitMs(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    private dialogHandler() {
        this.singleTrigger = false;
        if (this.enableCookies) {
            let gotCookie = this.CookieService.getCookieStatus();
            if (gotCookie.popUpPromoExternal === null) {
                this.CookieService.setCookie();
                this.awaitTrigger();
            }
        }
        else {
            this.awaitTrigger();
        }
    }

    private CookieService = {
        setCookie() {
            const expirationSeconds = 60 * 60 * 24 * 7;
            let date = new Date();
            date = new Date(date.getTime() + 1000 * expirationSeconds);
            let newCookie2 = 'popUpPromoExternal={"expiration":"' + date.toUTCString() + '"}; expires=' + date.toUTCString() + '; path=/;';
            document.cookie = newCookie2;
        },
        getCookieStatus() {
            const cookies = document.cookie.split(';');
            let returnValue = {
                popUpPromoExternal: null
            };
            for (const cookie of cookies) {
                if (cookie.indexOf('popUpPromoExternal' + '=') > -1) {
                    returnValue.popUpPromoExternal = cookie.split('=')[1];
                }
            }
            return returnValue;
        }
    };

    private openDialog(): void {
        const dialog = this.dialog.open(PopUpExterno, {
            hasBackdrop: true,
            disableClose: false,
            width: '640px',
            height: 'auto',
            data: this.data,
            panelClass: 'dialogWithOffer'
        });
        dialog.afterClosed().subscribe(() => {
            this.emmiterEnabledLeavingPopUp.emit(true);
        });
    }
}
