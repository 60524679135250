import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { urlEnv, environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TestABService {
  private checkTestAB: string;
  private expirationDate = new Date(Date.UTC(2025, 2, 29, 2, 59, 0)); // Viernes 28 de Febrero de 2025 23:59:00 CLST
  private cookieName = 'testAB';
  private testName = 'default';
  private validTestVariants: string[] = ['a', 'b'];
  private urlEnv = urlEnv;
  private environment = environment;
  constructor(private _http: HttpClient) {}

  public getTestAB(): Observable<string> {
    return new Observable<string>(observer => {
      this.checkTestAB = this.getCookie(this.cookieName);
      const now = new Date();

      // Caso cuando el Test haya expirado
      if (now >= this.expirationDate) {
        this.resetTestAB();
        observer.next('');
        observer.complete();
        return;
      }
      
      // Se valida que el Test sea válido
      this.checkIfValidTestVariant();
      
      if (this.checkTestAB) {
        // Caso cuando exista un Test válido ya en Cookie
        observer.next(this.checkTestAB);
        observer.complete();
      } else {
        // Si requiere obtener el Test desde la API
        const subscription = this.test().subscribe({
          next: value => {
            observer.next(value);
            observer.complete();
          },
          error: err => observer.error(err)
        });
        
        return () => subscription.unsubscribe();
      }
    });
  }

  private checkIfValidTestVariant(): void {
    if (this.checkTestAB && !this.validTestVariants.includes(this.checkTestAB)) {
      this.resetTestAB();
    }
  }

  private resetTestAB(): void {
    this.checkTestAB = null;
    document.cookie = `${this.cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }

  public checkIfDef(){
    const now = new Date();
    if (now < this.expirationDate){
        const name = this.getCookie(this.cookieName);
        return name === this.testName
    }
    else{
        return true;
    }
  }

  private test(): Observable<string> {
    return new Observable<string>(observer => {
      if (this.environment.production) {
        const urlReq = this.urlEnv.TUU_TEST_AB;
        const subscription = this._http.get<string>(urlReq)
          .pipe(timeout(4000))
          .subscribe({
            next: (data) => {
              document.cookie = `${this.cookieName}=${data}; expires=${this.expirationDate.toUTCString()}; path=/`;
              this.checkTestAB = data;
              observer.next(this.checkTestAB);
              observer.complete();
            },
            error: () => {
              observer.next('');
              observer.complete();
            }
          });
        
        return () => subscription.unsubscribe();
      } else {
        // En caso que sea Develop no se consultará la API, se hará un Random local con las opciones válidas
        const randomVariant = this.validTestVariants[Math.floor(Math.random() * this.validTestVariants.length)];
        document.cookie = `${this.cookieName}=${randomVariant}; expires=${this.expirationDate.toUTCString()}; path=/`;
        this.checkTestAB = randomVariant;
        
        const timeoutId = setTimeout(() => {
          observer.next(this.checkTestAB);
          observer.complete();
        }, 600);
        
        return () => clearTimeout(timeoutId);
      }
    });
  }

  private getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    return parts.length === 2 ? parts.pop()?.split(';').shift() || null : null;
  }
}