import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { urlEnv } from '../../../environments/environment';

@Component({
    selector: 'app-section-warranty',
    templateUrl: './section-warranty.component.html',
    styleUrls: ['./section-warranty.component.scss']
})
export class SectionWarrantyComponent implements OnInit {
    @Input() public loadingImages: boolean;
    @Input() public isDirect: boolean;
    public urlEnv = urlEnv;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private buttonTraceService: ButtonTraceService
    ) { }

    ngOnInit(): void {
    }

    goToUrl(urlName: string, event: Event): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(this.urlEnv[urlName], '_blank');
        }
        this.buttonTraceService.traceButton(event, 'navigate');
    }

}
