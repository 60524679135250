import { Component, Input} from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';

@Component({
    selector: 'app-inventory-call-to-action',
    templateUrl: './inventory-call-to-action.component.html',
    styleUrls: ['./inventory-call-to-action.component.scss']
})
export class InventoryCallToActionComponent {
    @Input() public loadingImages: boolean;

    constructor(
        public buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService,
    ) {
    }

    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
    }
}
