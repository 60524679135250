import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { urlMapping } from '../../../shared/route-ids';
import { ButtonTraceService } from '../../../../../../common/services/button-trace';
declare var hbspt: any;

@Component({
    selector: 'app-commission-form',
    templateUrl: './commission-form.component.html',
    styleUrls: ['./commission-form.component.scss']
})
export class CommissionFormComponent implements OnInit, AfterViewInit {
  public url: string;
  public popupVisible = false;

  constructor(
    private router: Router,
    private buttonTraceService: ButtonTraceService
) {}

  ngOnInit() {
    const urlTree = this.router.parseUrl(this.router.url);
    const primarySegment = urlTree.root.children['primary'];
    const urlSegment = primarySegment?.segments[0]?.path || 'home';
    this.url = urlMapping[urlSegment] || urlSegment;
  }

    ngAfterViewInit(): void {
        if (hbspt !== undefined){
            hbspt.forms.create({
                portalId: "47115837",
                formId: "56559823-0468-4c72-978e-2e7bb07b4a49",
                target: '#hubspotForm'
            });
        }
    }

  public openPopup(event: Event): void {
    this.popupVisible = true;
    this.buttonTraceService.traceButton(event, 'popup');
  }

  public closePopup(): void {
    this.popupVisible = false;
  }
}