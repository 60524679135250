import { Component, Input } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';

@Component({
    selector: 'app-section-about-partnership',
    templateUrl: './section-about-partnership.component.html',
    styleUrls: ['./section-about-partnership.component.scss']
})
export class SectionAboutPartnershipComponent {

    constructor(
        public buttonTraceService: ButtonTraceService,
        public scrollToService: ScrollToService
    ) {
    }
    @Input() public loadingImages: boolean;

    public isShowVideo: boolean;

}
