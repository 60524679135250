import { Component, ElementRef, Input } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../../shared/scrollTo.class';
import { Router } from '@angular/router';

@Component({
    selector: 'app-credit-benefits',
    templateUrl: './credit-benefits.component.html',
    styleUrls: ['./credit-benefits.component.scss']
})
export class CreditBenefitsComponent extends ScrollToClass {
    @Input() public loadingImages: boolean;

    cards: Array<{ icon: string; title: string; text: string }> = [
        {
            icon: 'icon-sprite-file',
            title: 'Sin papeleos',
            text: 'Tendrás acceso a préstamos pre aprobados que podrás solicitar en 5 minutos 100% en línea, sin perder tiempo en reuniones presenciales.'
        },
        {
            icon: 'icon-sprite-refresh',
            title: 'Financiamiento continuo',
            text: 'Mientras sigas teniendo ventas, recibirás nuevas ofertas para acompañar el crecimiento de tu negocio.'
        },
        {
            icon: 'icon-sprite-schedule',
            title: 'Depósito en 24 horas',
            text: 'Dinero disponible en tu cuenta bancaria en 1 día hábil.'
        }
    ];

    constructor(
        private _el: ElementRef,
        private router: Router
    ) {
        super(_el);
    }

    track(event: Event) {
        traceButton2(event, 'navigate');
    }

    public showPosHome(): void {
        this.router.navigate(['/']).then(() => {
            window.scroll(0, 0);
            setTimeout(() => {
                this.scrollToSection("sectionPrice");
            }, 500);
        });
        this.scrollToSection("sectionPrice");
    }
}
