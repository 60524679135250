import { Component, AfterViewInit, Input ,Inject, PLATFORM_ID } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-section-how-to-request',
    templateUrl: './section-how-to-request.component.html',
    styleUrls: ['./section-how-to-request.component.scss']
})
export class SectionHowToRequestComponent implements AfterViewInit {
    private transformStyleSubject = new BehaviorSubject<string>("");
    public transformStyle$ = this.transformStyleSubject.asObservable();
    @Input() public loadingImages: boolean;

    currentStep: number = 0;
    lineHeight: string = '105px';
    private userActivity: boolean = false;

    steps = [
        {
        title: 'Accede a la sección Abono Inmediato',
        description: 'Dentro de tu POS en la aplicación de Pagos, ingresa al menú lateral y selecciona "Abono Inmediato".',
        height: 795,
        lineHeight:"127px"
        },
        {
        title: 'Solicitar Abono Inmediato',
        description: 'Verás tu saldo disponible para retiro, si aún no lo has solicitado, selecciona "Solicitar Abono".',
        height: 797,
        lineHeight:"103px"
        },
        {
        title: 'Ingresa el monto y verifica los datos',
        description: 'Ingresa el monto que deseas solicitar, verifica los detalles y costos, y presiona solicitar para procesar el abono.',
        height: 794,
        lineHeight:"127px"
        },
        {
        title: '¡Listo! Recibe tu Abono Inmediato',
        description: 'En menos de 15 minutos, los fondos se depositarán en la cuenta bancaria de tu comercio.',
        height: 796,
        lineHeight:"127px"
        },
        {
        title: 'Verifica el estado de tu solicitud',
        description: 'En la sección de Abono Inmediato puedes ver el estado de tus solicitudes incluyendo monto, costos y estado del abono.',
        },
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.autoAdvance();
        }
    }

    public setStep(index: number){
        this.userActivity = true;
        this.currentStep = index;
        this.translateValue();
    }

    private autoAdvance(){
        setTimeout(() => {
            if(!this.userActivity){
                this.currentStep = (this.currentStep + 1) % this.steps.length;
                this.translateValue();
                this.autoAdvance();
            }
        }, 5000);
    }

    public translateValue(): void {
        if (this.currentStep == 0){
            this.transformStyleSubject.next(`translateY(${-(this.currentStep * 0)}px)`);
        }else{
            var translate = 0;
            for (let index = 0; index < this.currentStep; index++) {
                translate = translate + this.steps[index].height + 5;
            }
            this.transformStyleSubject.next(`translateY(${-(translate)}px)`);
        }
    }
}