import { Component, OnInit } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { ButtonTraceService } from '../../../../common/services/button-trace';
import { urlEnv } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'app-error-link-expired',
    templateUrl: './error-link-expired.component.html',
    styleUrls: ['./error-link-expired.component.scss']
})
export class ErrorLinkExpiredComponent implements OnInit {

    public urlEnv = urlEnv;

    constructor(
        private readonly _meta: MetaService,
        private router: Router,
        private buttonTraceService: ButtonTraceService
    ) { }

    ngOnInit(): void {
        this._setMeta();
    }

    private _setMeta() {
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-error.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'El link al que estás ingresando ya caducó.');
    }

    redireccionarAHome(event: Event) {
        event.preventDefault();
        this.buttonTraceService.traceButton(event, "navigate");
        this.router.navigate(['/']);
    }
}
