import { Component, OnInit, Inject, PLATFORM_ID, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Card } from '../../../installment-payment/data-installment-payment';
import { ButtonTraceService } from '../../../../../../common/services/button-trace';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-dialog-machine',
    styleUrls: ['./dialog-machine-card.component.scss'],
    templateUrl: './dialog-machine-card.component.html'
})
export class DialogMachineCard implements OnInit {
    public isMobile: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<DialogMachineCard>,
        @Inject(MAT_DIALOG_DATA) public data: Card,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
        private buttonTraceService: ButtonTraceService
    ) { }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = window.innerWidth < 993;
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.isMobile = window.innerWidth < 993;
        }
    }

    public closeDialog(action: string) {
        this.dialogRef.close(action);
    }

    private scrollTop(): void {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                window.scroll(0, 0);
            }, 200);
        }
    }

    public navigateTo(path: string, event: Event): void {
        this.closeDialog('navigate');
        this.buttonTraceService.traceButton(event, 'navigate');
        this.router.navigate([path]);
        this.scrollTop();
    }
}
