import { DATA_PRICE_ASSOCIATION } from "../shared/section-price/data-price";

const formatterPrice = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0
});

export interface Tariff {
    title: string;
    options: Array<TariffOption>;
}

interface Features {
    icon: string;
    text: string;
    tooltip?: string;
    tooltipHelperClass?: string;
    quotes?:boolean;
    immediate?:boolean;
}

export interface TariffOption {
    title: string;
    tooltip?: { title: string; text: string; size?: number };
    cards?: Array<Card>;
    columnCards?: Array<{ id: string; title: string; subtitle: string; isSelected: boolean; }>;
    counterCards?: Array<Card>;
}

export interface Card {
    id: string;
    title: string;
    image?: string;
    machine?: {
        machine_image: string;
        device_in_pack: string;
        name: string;
        features: Array<Features>
        special_features: Array<Features>
    }
    subtext?: string;
    tooltip?: string;
    suptext?: string;
    sup_value?: number;
    old_price?: number;
    price?: number;
    discount?: number;
    out_of_stock?: boolean;
    percent?: string;
    sub_value?: string;
    value?: string | number;
    count?: number;
    isSelected: boolean;
    type?: string;
    selectOptions?: Array<SelectOption>;
    isEdit?: boolean;
}

export interface SelectOption {
    title: string;
    price: number;
    sup_value?: number;
    productId: string;
    id?: number
}
export interface DataInstallment {
    title: string;
    body: Array<DataInstallmentOption>;
    footer: {
        caption: string;
        total: number;
        value?: string | number;
        label?: string;
        options?: Array<string | number>;
        info?: string;
    };
}

export interface DataInstallmentOption {
    caption: string;
    label: string;
    value: string | number;
    options: Array<string | number>;
}

export interface IDeviceFeature {
    name: string;
    device: string;
    options: Array<{ icon: string; text: string }>;
    features: Array<Features>;
    delivery: { fireText: string, fireTextRegion?: string, showFire: boolean, showPre?: boolean, textRM: string, textRegion: string, textPre?: string };
    previews: Array<{ route: string, showImage: boolean, showIcon: boolean, justifyContent?: string }>;
}

export interface IDiscounts {
    title: string;
    price: number;
    discount?: number;
    discounted_price?: number;
}

export const dataType: {
    'mini': IDeviceFeature,
    'mini-s': IDeviceFeature,
    // 'pro': IDeviceFeature,
    'se': IDeviceFeature,
    "desk": IDeviceFeature,
    'pro-semi-nueva': IDeviceFeature,
    'se-semi-nueva': IDeviceFeature,
    'mini-semi-nueva': IDeviceFeature,
    'mini-s-semi-nueva': IDeviceFeature,
    'desk-semi-nueva': IDeviceFeature,
    'pro-2': IDeviceFeature
} = {
    'mini': {
        name: 'Mini',
        device: 'preview-sunmi-mini',
        options: [{
            icon: 'icon-calendar-first-day-new',
            text: 'Abono del 100% de tus ventas en 1 día hábil.'
        }, {
            icon: 'icon-sprite-percent-new',
            text: 'Comisiones más bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos-new',
            text: 'Punto de venta con catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: 'Emisión ilimitada de boletas y facturas.'
        }],
        features: [{
            icon: 'icon-sprite-credit-card-new',
            text: '<b>Acepta tarjetas</b> con banda magnética, chip y sin contacto.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: '<b>Recibe pagos con tarjetas</b> y emite voucher válido como boleta electrónica.'
        }, {
            icon: 'icon-sprite-attach-money-new',
            text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.',
            //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
        }, {
            icon: "icon-sprite-coins-stacked",
            text: "<b>Abono inmediato</b> para recibir el dinero de tus ventas en 15 minutos."
        }, {
            icon: "icon-sprite-pie-chart-new",
            text: "<b>Acepta pagos en cuotas</b> y paga 0% de comisión.",
        }, {
            icon: "icon-sprite-screen-new",
            text: "<b>Pantalla</b> táctil de 5”",
            tooltip: 'Pantalla IPS Táctil, Capacitiva, multipuntos táctiles de  5" y Resolución HD de 1280x720.'
        }, {
            icon: "icon-sprite-battery-new",
            text: "Batería de<b> larga duración.</b>"
        }, {
            icon: 'icon-sprite-sim-card-new',
            text: '<b>Chip de Internet gratis</b> con gigas libres y Conexión Wifi.',
            tooltip: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
        }, {
            icon: 'icon-sprite-globe',
            text: '<b>Despacho Gratis</b> a todo Chile.'
        }, {
            icon: 'icon-sprite-award-new',
            text: 'Garantía de <b>2 años.</b>'
        }, {
            icon: 'icon-sprite-android-phone-slash-new',
            text: '<b>No necesitas</b> un celular',
        }],
        delivery: {
            fireText: '1 - 2 días hábiles RM',
            fireTextRegion: "2 - 7 días hábiles regiones",
            showFire: true,
            textRM: '1 - 3 días hábiles RM',
            textRegion: '2 - 7 días hábiles regiones'
        },
        previews: [
            {
                route: 'mini/preview-1',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-2',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-3',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-4',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-5',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-6',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-7',
                showImage: true,
                showIcon: false
            }
        ]
    },
    'mini-semi-nueva': {
        name: 'Mini Semi Nueva',
        device: 'preview-sunmi-mini',
        options: [{
            icon: 'icon-calendar-first-day-new',
            text: '100% de tus ventas en solo 1 día hábil de abono.'
        }, {
            icon: 'icon-sprite-percent-new',
            text: 'Comisiones más bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos-new',
            text: 'Punto de venta con catálogo e inventario.'
        }],
        features: [{
            icon: 'icon-sprite-credit-card-new',
            text: '<b>Acepta tarjetas</b> con banda magnética, chip y sin contacto.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: '<b>Recibe pago con tarjetas</b> y emite voucher válido como boleta electrónica.'
        }, {
            icon: 'icon-sprite-attach-money-new',
            text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.',
            //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
        }, {
            icon: "icon-sprite-coins-stacked",
            text: "<b>Abono inmediato</b> para recibir el dinero de tus ventas en 15 minutos."
        }, {
            icon: "icon-sprite-pie-chart-new",
            text: "<b>Acepta pagos en cuotas</b> y paga 0% de comisión.",
        }, {
            icon: "icon-sprite-screen-new",
            text: "<b>Pantalla</b> táctil de 5”",
            tooltip: 'Pantalla IPS Táctil, Capacitiva, multipuntos táctiles de  5" y Resolución HD de 1280x720.'
        }, {
            icon: "icon-sprite-battery-new",
            text: "Batería de<b> larga duración.</b>"
        }, {
            icon: 'icon-sprite-sim-card-new',
            text: '<b>Chip de Internet gratis</b> con gigas libres y Conexión Wifi.',
            tooltip: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
        }, {
            icon: 'icon-sprite-globe',
            text: '<b>Despacho Gratis</b> a todo Chile.'
        }, {
            icon: 'icon-sprite-award-new',
            text: 'Garantía de <b>6 meses.</b>'
        }, {
            icon: 'icon-sprite-android-phone-slash-new',
            text: '<b>No necesitas</b> un celular',
        }],
        delivery: {
            fireText: '1 - 2 días hábiles RM',
            fireTextRegion: "2 - 7 días hábiles regiones",
            showFire: true,
            textRM: '1 - 3 días hábiles RM',
            textRegion: '2 - 7 días hábiles regiones'
        },
        previews: [
            {
                route: 'mini/preview-1',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-2',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-3',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-4',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-5',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-6',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini/preview-7',
                showImage: true,
                showIcon: false
            }
        ]
    },
    'mini-s': {
        name: 'Mini S',
        device: 'preview-sunmi-mini-s',
        options: [{
            icon: 'icon-calendar-first-day-new',
            text: 'Abono del 100% de tus ventas en 1 día hábil.'
        }, {
            icon: 'icon-sprite-percent-new',
            text: 'Comisiones más bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos-new',
            text: 'Punto de venta con catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: 'Emisión ilimitada de boletas y facturas.'
        }],
        features: [{
            icon: 'icon-sprite-credit-card-new',
            text: '<b>Acepta tarjetas</b> con banda magnética, chip y sin contacto.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: '<b>Recibe pagos con tarjetas</b> y emite voucher válido como boleta electrónica.'
        }, {
            icon: 'icon-sprite-attach-money-new',
            text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.',
            //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
        }, {
            icon: "icon-sprite-coins-stacked",
            text: "<b>Abono inmediato</b> para recibir el dinero de tus ventas en 15 minutos."
        }, {
            icon: "icon-sprite-pie-chart-new",
            text: "<b>Acepta pagos en cuotas</b> y paga 0% de comisión.",
        }, {
            icon: 'icon-sprite-scan-new',
            text: '<b>Escáner profesional</b> integrado.'
        },
        {
            icon: "icon-sprite-screen-new",
            text: "<b>Pantalla</b> táctil de 5”",
            tooltip: 'Pantalla IPS Táctil, Capacitiva, multipuntos táctiles de  5" y Resolución HD de 1280x720.',
            tooltipHelperClass: 'l-0'
        },
        {
            icon: "icon-sprite-battery-new",
            text: "Batería de<b> larga duración.</b>"
        },
        {
            icon: 'icon-sprite-sim-card-new',
            text: '<b>Chip de Internet gratis</b> con gigas libres y Conexión Wifi.',
            tooltip: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
        },
        {
            icon: 'icon-sprite-globe',
            text: '<b>Despacho Gratis</b> a todo Chile.'
        }, {
            icon: 'icon-sprite-award-new',
            text: 'Garantía de <b>2 años.</b>'
        }, {
            icon: 'icon-sprite-android-phone-slash-new',
            text: '<b>No necesitas</b> un celular',
        }],
        delivery: {
            fireText: '1 - 5 días hábiles RM',
            fireTextRegion: "2 - 7 días hábiles regiones",
            showFire: true,
            textRM: '1 - 3 días hábiles RM',
            textRegion: '2 - 7 días hábiles regiones'
        },
        previews: [
            {
                route: 'mini-s/preview-1',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini-s/preview-2',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini-s/preview-3',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini-s/preview-4',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini-s/preview-5',
                showImage: true,
                showIcon: true,
                justifyContent: "left"
            },
            {
                route: 'mini-s/preview-6',
                showImage: true,
                showIcon: true,
                justifyContent: "right"
            },
            {
                route: 'mini-s/preview-7',
                showImage: true,
                showIcon: false
            }
        ]
    },
    'mini-s-semi-nueva': {
        name: 'Mini S Semi Nueva',
        device: 'preview-sunmi-mini-s',
        options: [{
            icon: 'icon-calendar-first-day-new',
            text: '100% de tus ventas en solo 1 día hábil de abono.'
        }, {
            icon: 'icon-sprite-percent-new',
            text: 'Comisiones más bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos-new',
            text: 'Punto de venta con catálogo e inventario.'
        }],
        features: [{
            icon: 'icon-sprite-credit-card-new',
            text: '<b>Acepta tarjetas</b> con banda magnética, chip y sin contacto.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: '<b>Recibe pago con tarjetas</b> y emite voucher válido como boleta electrónica.'
        }, {
            icon: 'icon-sprite-attach-money-new',
            text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.',
            //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
        }, {
            icon: "icon-sprite-coins-stacked",
            text: "<b>Abono inmediato</b> para recibir el dinero de tus ventas en 15 minutos."
        }, {
            icon: "icon-sprite-pie-chart-new",
            text: "<b>Acepta pagos en cuotas</b> y paga 0% de comisión.",
        }, {
            icon: 'icon-sprite-scan-new',
            text: '<b>Escáner profesional</b> integrado.'
        },
        {
            icon: "icon-sprite-screen-new",
            text: "<b>Pantalla</b> táctil de 5”",
            tooltip: 'Pantalla IPS Táctil, Capacitiva, multipuntos táctiles de  5" y Resolución HD de 1280x720.',
            tooltipHelperClass: 'l-0'
        },
        {
            icon: "icon-sprite-battery-new",
            text: "Batería de<b> larga duración.</b>"
        },
        {
            icon: 'icon-sprite-sim-card-new',
            text: '<b>Chip de Internet gratis</b> con gigas libres y Conexión Wifi.',
            tooltip: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
        },
        {
            icon: 'icon-sprite-globe',
            text: '<b>Despacho Gratis</b> a todo Chile.'
        }, {
            icon: 'icon-sprite-award-new',
            text: 'Garantía de <b>6 meses.</b>'
        }, {
            icon: 'icon-sprite-android-phone-slash-new',
            text: '<b>No necesitas</b> un celular',
        }],
        delivery: {
            fireText: '1 - 5 días hábiles RM',
            fireTextRegion: "2 - 7 días hábiles regiones",
            showFire: true,
            textRM: '1 - 3 días hábiles RM',
            textRegion: '2 - 7 días hábiles regiones'
        },
        previews: [
            {
                route: 'mini-s/preview-1',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini-s/preview-2',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini-s/preview-3',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini-s/preview-4',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini-s/preview-5',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini-s/preview-6',
                showImage: true,
                showIcon: true
            },
            {
                route: 'mini-s/preview-7',
                showImage: true,
                showIcon: false
            }
        ]
    },
    // 'pro': {
    //     name: 'Pro',
    //     device: 'terminal-demonstartion',
    //     options: [{
    //         icon: 'icon-sprite-calendar-today',
    //         text: '100% de tus ventas</br> en solo 1 día hábil de </br> abono.'
    //     }, {
    //         icon: 'icon-sprite-percent',
    //         text: 'Comisiones más</br> bajas garantizadas.'
    //     }, {
    //         icon: 'icon-sprite-pos',
    //         text: 'Punto de venta con</br> catálogo e inventario.'
    //     }, {
    //         icon: 'icon-sprite-doc',
    //         text: 'Emisión ilimitada de</br> boletas y facturas por</br> correo electrónico.'
    //     }],
    //     features: [
    //         {
    //             icon: 'icon-sprite-credit-card2',
    //             text: '<b>Acepta tarjetas</b> con banda <br/>magnética, chip y sin contacto.'
    //         }, {
    //             icon: 'icon-sprite-file',
    //             text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
    //         }, {
    //             icon: 'icon-sprite-attach-money',
    //             text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica.',
    //             tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
    //         }, {
    //             icon: 'icon-sprite-printer',
    //             text: '<b>Impresión física de comprobante</b> de<br/> alta velocidad.',
    //         }, {
    //             icon: 'icon-sprite-sim-card',
    //             text: '<b>Chip de Internet gratis</b> con gigas<br/> libres y Conexión Wifi.',
    //             tooltip: 'EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
    //         }, {
    //             icon: 'icon-sprite-track',
    //             text: '<b>Despacho Gratis</b> a todo Chile.'
    //         }, {
    //             icon: 'icon-sprite-award',
    //             text: 'Garantía de <b>2 años.</b>'
    //         }, {
    //             icon: 'icon-sprite-rolls',
    //             text: '5 rollos de papel térmico <b>gratis.</b>'
    //         }, {
    //             icon: 'icon-sprite-battery',
    //             text: 'Batería de<b> larga duración.</b>'
    //         }, {
    //             icon: 'icon-sprite-camera',
    //             text: 'Cámara de <b>5 megapixeles</b>'
    //         }, {
    //             icon: 'icon-sprite-screen',
    //             text: '<b>Pantalla táctil</b> de 5.5”',
    //             tooltip: 'Pantalla Táctil 5.5" HD, Resolución: 1440x720px',
    //             tooltipHelperClass: 'w-196'
    //         }
    //     ],
    //     delivery: {
    //         fireText: '25 días hábiles',
    //         showFire: false,
    //         textRM: '1 - 4 días hábiles RM',
    //         textRegion: '2 - 8 días hábiles regiones'
    //     }
    // },
    'se': {
        name: 'SE',
        device: 'terminal-demonstartion-se',
        options: [{
            icon: 'icon-calendar-first-day-new',
            text: 'Abono del 100% de tus ventas en 1 día hábil.'
        }, {
            icon: 'icon-sprite-percent-new',
            text: 'Comisiones más bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos-new',
            text: 'Punto de venta con catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: 'Emisión ilimitada de boletas y facturas.'
        }],
        features: [
            {
                icon: 'icon-sprite-credit-card-new',
                text: '<b>Acepta tarjetas</b> con banda magnética, chip y sin contacto.'
            }, {
                icon: 'icon-sprite-doc-new',
                text: '<b>Recibe pago con tarjetas</b> y emite voucher válido como boleta electrónica.'
            }, {
                icon: 'icon-sprite-attach-money-new',
                text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.',
                //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
            }, {
                icon: "icon-sprite-coins-stacked",
                text: "<b>Abono inmediato</b> para recibir el dinero de tus ventas en 15 minutos."
            }, {
                icon: "icon-sprite-pie-chart-new",
                text: "<b>Acepta pagos en cuotas</b> y paga 0% de comisión.",
            }, {
                icon: 'icon-sprite-printer-new',
                text: '<b>Impresión física de comprobante</b> a alta velocidad.',
            }, {
                icon: 'icon-sprite-sim-card-new',
                text: '<b>Chip de Internet gratis</b> con gigas libres y Conexión Wifi.',
                tooltip: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
            }, {
                icon: 'icon-sprite-globe',
                text: '<b>Despacho Gratis</b> a todo Chile.'
            }, {
                icon: 'icon-sprite-award-new',
                text: 'Garantía de <b>2 años.</b>'
            }, {
                icon: 'icon-sprite-rolls-new',
                text: '5 rollos de papel térmico <b>gratis.</b>'
            }, {
                icon: 'icon-sprite-battery-new',
                text: 'Batería de<b> larga duración.</b>'
            }, {
                icon: 'icon-sprite-camera-new',
                text: '<b>Cámara de 0,3</b> megapixeles'
            }, {
                icon: 'icon-sprite-screen-new',
                text: '<b>Pantalla</b> táctil de 5”',
                tooltip: 'Pantalla Táctil 5" FW+, Resolución: 960x480px',
                tooltipHelperClass: 'w-196'
            }
        ],
        delivery: {
            fireText: '1 - 5 días hábiles RM',
            fireTextRegion: "2 - 7 días hábiles regiones",
            showFire: true,
            textRM: '1 - 3 días hábiles RM',
            textRegion: '2 - 7 días hábiles regiones'
        },
        previews: [
            {
                route: 'se/preview-1',
                showImage: true,
                showIcon: true
            },
            {
                route: 'se/preview-2',
                showImage: true,
                showIcon: true
            },
            {
                route: 'se/preview-3',
                showImage: true,
                showIcon: true
            },
            {
                route: 'se/preview-4',
                showImage: true,
                showIcon: true
            },
            {
                route: 'se/preview-5',
                showImage: true,
                showIcon: true,
                justifyContent: "left"
            },
            {
                route: 'se/preview-6',
                showImage: true,
                showIcon: true,
                justifyContent: "right"
            },
            {
                route: 'se/preview-7',
                showImage: true,
                showIcon: false
            }
        ]
    },
    "desk": {
        name: "Desk",
        device: "preview-demonstartion-desk",
        options: [{
            icon: 'icon-calendar-first-day-new',
            text: '100% de tus ventas en solo 1 día hábil de abono.'
        }, {
            icon: 'icon-sprite-percent-new',
            text: 'Comisiones más bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos-new',
            text: 'Punto de venta con catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: 'Emisión ilimitada de boletas y facturas.'
        }],
        features: [
            {
                icon: 'icon-sprite-tv',
                text: '<b>Tamaño más grande y cómodo</b> para agilizar tus ventas.',
            }, {
                icon: 'icon-sprite-scan-new',
                text: '<b>Facilita el uso del catálogo</b> de productos gracias a su amplia pantalla.',
            }, {
                icon: 'icon-sprite-scanner',
                text: '<b>Escanea productos y vende con catálogo</b> usando lector de códigos de barras TUU.',
                tooltip:'Debes comprar el lector de código de barras TUU como complemento a tu Desk. No viene incluido por defecto',
                tooltipHelperClass: 'l-0'
            }, {
                icon: "icon-sprite-pos-new",
                text: "Integración rápida con POS SE, PRO 2 o Mini-s para <b>aceptar pagos con tarjeta.</b>",
                tooltip: 'Las máquinas Desk no reciben pagos con tarjeta por sí solas, requieren de un POS complementario para aceptar pagos. POS complementario debe comprarse con un Pack Desk o aparte.',
                tooltipHelperClass: 'l-0'
            }, {
                icon: "icon-sprite-sp",
                text: "<b>Doble pantalla HD:</b> Principal táctil de 10,1” y secundaria para resumen de venta de 4,3”",
            }, {
                icon: "icon-sprite-doc-new",
                text: "<b>Emite boletas y facturas</b> electrónicas ilimitadas."
            }, {
                icon: 'icon-sprite-printer-new',
                text: '<b>Impresión física de comprobante</b> en alta velocidad.',
            }, {
                icon: "icon-sprite-plug",
                text: 'Conexión <b>220 voltios.</b>',
                tooltip: "El terminal TUU Desk trabaja conectado a la corriente.",
                tooltipHelperClass: 'l-0'
            },{
                icon: 'icon-sprite-globe',
                text: '<b>Despacho Gratis</b> a todo Chile.'
            }, {
                icon: 'icon-sprite-award-new',
                text: 'Garantía de <b>2 años.</b>'
            }, {
                icon: 'icon-sprite-rolls-new',
                text: '5 rollos de <b>papel térmico gratis.</b>'
            }
        ],
        delivery: {
            fireText: '1 - 2 días hábiles RM',
            fireTextRegion: "2 - 7 días hábiles regiones",
            showFire: false,
            showPre: true,
            textRM: '',
            textRegion: '',
            textPre:'Los despachos inician el 28/04 según fecha de compra. Recibirás un correo al momento que tu máquina se empiece a preparar.'
        },
        previews: [
            {
                route: 'desk/preview-1',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-2',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-3',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-4',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-5',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-6',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-7',
                showImage: true,
                showIcon: false
            },
            {
                route:'desk/preview-8',
                showImage: true,
                showIcon: false
            }
        ]
    },
    'pro-2': {
        name: 'PRO 2',
        device: 'device-pro-2',
        options: [{
            icon: 'icon-calendar-first-day-new',
            text: 'Abono del 100% de tus ventas en 1 día hábil.'
        }, {
            icon: 'icon-sprite-percent-new',
            text: 'Comisiones más bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos-new',
            text: 'Punto de venta con catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: 'Emisión ilimitada de boletas y facturas.'
        }],
        features: [
            {
                icon: 'icon-sprite-credit-card-new',
                text: '<b>Acepta tarjetas</b> sin contacto y con chip.'
            }, {
                icon: 'icon-sprite-doc-new',
                text: '<b>Recibe pago con tarjetas</b> y emite voucher válido como boleta electrónica.'
            }, {
                icon: 'icon-sprite-attach-money-new',
                text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.',
                //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
            }, {
                icon: "icon-sprite-coins-stacked",
                text: "<b>Abono inmediato</b> para recibir el dinero de tus ventas en 15 minutos."
            }, {
                icon: "icon-sprite-pie-chart-new",
                text: "<b>Acepta pagos en cuotas</b> y paga 0% de comisión.",
            }, {
                icon: 'icon-sprite-printer-new',
                text: '<b>Impresión física de comprobante</b> a alta velocidad.',
            }, {
                icon: 'icon-sprite-sim-card-new',
                text: '<b>Chip de Internet gratis</b> con gigas libres y Conexión Wifi.',
                tooltip: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
            }, {
                icon: 'icon-sprite-globe',
                text: '<b>Despacho Gratis</b> a todo Chile.'
            }, {
                icon: 'icon-sprite-award-new',
                text: 'Garantía de <b>2 años.</b>'
            }, {
                icon: 'icon-sprite-rolls-new',
                text: '5 rollos de papel térmico <b>gratis.</b>'
            }, {
                icon: 'icon-sprite-battery-new',
                text: 'Batería de<b> larga duración.</b>'
            }, {
                icon: 'icon-sprite-camera-new',
                text: '<b>Cámara de 2</b> megapixeles'
            }, {
                icon: 'icon-sprite-screen-new',
                text: '<b>Pantalla</b> táctil de 6,52”',
            }
        ],
        delivery: {
            fireText: '1 - 2 días hábiles RM',
            fireTextRegion: "2 - 7 días hábiles regiones",
            showFire: false,
            showPre:true,
            textRM: '',
            textRegion: '',
            textPre:'Los despachos inician el 28/04 según fecha de compra. Recibirás un correo al momento que tu máquina se empiece a preparar.'
        },
        previews: [
            {
                route: 'pro-2/preview-1',
                showImage: true,
                showIcon: true
            },
            {
                route: 'pro-2/preview-2',
                showImage: true,
                showIcon: true
            },
            {
                route: 'pro-2/preview-3',
                showImage: true,
                showIcon: true
            },
            {
                route: 'pro-2/preview-4',
                showImage: true,
                showIcon: true
            },
            {
                route: 'pro-2/preview-5',
                showImage: true,
                showIcon: true,
                justifyContent: "left"
            },
            {
                route: 'pro-2/preview-6',
                showImage: true,
                showIcon: true,
                justifyContent: "right"
            }
        ]
    },
    'se-semi-nueva': {
        name: 'SE Semi Nueva',
        device: 'terminal-demonstartion-se',
        options: [{
            icon: 'icon-calendar-first-day-new',
            text: 'Abono del 100% de tus ventas en 1 día hábil.'
        }, {
            icon: 'icon-sprite-percent-new',
            text: 'Comisiones más bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos-new',
            text: 'Punto de venta con catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: 'Emisión ilimitada de boletas y facturas.'
        }],
        features: [
            {
                icon: 'icon-sprite-credit-card-new',
                text: '<b>Acepta tarjetas</b> con banda magnética, chip y sin contacto.'
            }, {
                icon: 'icon-sprite-doc-new',
                text: '<b>Recibe pago con tarjetas</b> y emite voucher válido como boleta electrónica.'
            }, {
                icon: 'icon-sprite-attach-money-new',
                text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.',
                //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
            }, {
                icon: "icon-sprite-coins-stacked",
                text: "<b>Abono inmediato</b> para recibir el dinero de tus ventas en 15 minutos."
            }, {
                icon: "icon-sprite-pie-chart-new",
                text: "<b>Acepta pagos en cuotas</b> y paga 0% de comisión.",
            }, {
                icon: 'icon-sprite-printer-new',
                text: '<b>Impresión física de comprobante</b> a alta velocidad.',
            }, {
                icon: 'icon-sprite-sim-card-new',
                text: '<b>Chip de Internet gratis</b> con gigas libres y Conexión Wifi.',
                tooltip: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
            }, {
                icon: 'icon-sprite-globe',
                text: '<b>Despacho Gratis</b> a todo Chile.'
            }, {
                icon: 'icon-sprite-award-new',
                text: 'Garantía de <b>2 años.</b>'
            }, {
                icon: 'icon-sprite-rolls-new',
                text: '5 rollos de papel térmico <b>gratis.</b>'
            }, {
                icon: 'icon-sprite-battery-new',
                text: 'Batería de<b> larga duración.</b>'
            }, {
                icon: 'icon-sprite-camera-new',
                text: '<b>Cámara de 0,3</b> megapixeles'
            }, {
                icon: 'icon-sprite-screen-new',
                text: '<b>Pantalla</b> táctil de 5”',
                tooltip: 'Pantalla Táctil 5" FW+, Resolución: 960x480px',
                tooltipHelperClass: 'w-196'
            }
        ],
        delivery: {
            fireText: '1 - 5 días hábiles RM',
            fireTextRegion: "2 - 7 días hábiles regiones",
            showFire: true,
            textRM: '1 - 3 días hábiles RM',
            textRegion: '2 - 7 días hábiles regiones'
        },
        previews: [
            {
                route: 'se/preview-1',
                showImage: true,
                showIcon: true
            },
            {
                route: 'se/preview-2',
                showImage: true,
                showIcon: true
            },
            {
                route: 'se/preview-3',
                showImage: true,
                showIcon: true
            },
            {
                route: 'se/preview-4',
                showImage: true,
                showIcon: true
            },
            {
                route: 'se/preview-5',
                showImage: true,
                showIcon: true
            },
            {
                route: 'se/preview-6',
                showImage: true,
                showIcon: true
            },
            {
                route: 'se/preview-7',
                showImage: true,
                showIcon: false
            }
        ]
    },
    'pro-semi-nueva': {
        name: 'Pro Semi Nueva',
        device: 'terminal-demonstartion',
        options: [{
            icon: 'icon-calendar-first-day-new',
            text: '100% de tus ventas en solo 1 día hábil de abono.'
        }, {
            icon: 'icon-sprite-percent',
            text: 'Comisiones más bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos',
            text: 'Punto de venta con catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc',
            text: 'Emisión ilimitada de boletas y facturas por correo electrónico.'
        }],
        features: [
            {
                icon: 'icon-sprite-credit-card2',
                text: '<b>Acepta tarjetas</b> con banda magnética, chip y sin contacto.'
            }, {
                icon: 'icon-sprite-file',
                text: '<b>Recibe pago con tarjeta</b> y emite voucher válido como boleta electrónica.'
            }, {
                icon: 'icon-sprite-attach-money',
                text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.',
                //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
            }, {
                icon: 'icon-sprite-printer',
                text: '<b>Impresión física de comprobante</b> de alta velocidad.',
            }, {
                icon: 'icon-sprite-sim-card',
                text: '<b>Chip de Internet gratis</b> con gigas libres y Conexión Wifi.',
                tooltip: 'EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
            }, {
                icon: 'icon-sprite-track',
                text: '<b>Despacho Gratis</b> a todo Chile.'
            }, {
                icon: 'icon-sprite-award',
                text: 'Garantía de <b>6 meses.</b>'
            }, {
                icon: 'icon-sprite-rolls',
                text: '5 rollos de papel térmico <b>gratis.</b>'
            }, {
                icon: 'icon-sprite-battery',
                text: 'Batería de<b> larga duración.</b>'
            }
        ],
        delivery: {
            fireText: '1 - 2 días hábiles RM',
            fireTextRegion: "2 - 7 días hábiles regiones",
            showFire: true,
            textRM: '1 - 3 días hábiles RM',
            textRegion: '2 - 7 días hábiles regiones'
        },
        previews: [
            {
                route: 'pro/preview-1',
                showImage: false,
                showIcon: false
            }
        ]
    },
    "desk-semi-nueva": {
        name: "Desk Semi Nueva",
        device: "preview-demonstartion-desk",
        options: [{
            icon: 'icon-calendar-first-day-new',
            text: '100% de tus ventas en solo 1 día hábil de abono.'
        }, {
            icon: 'icon-sprite-percent-new',
            text: 'Comisiones más bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos-new',
            text: 'Punto de venta con catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc-new',
            text: 'Emisión ilimitada de boletas y facturas.'
        }],
        features: [
            {
                icon: 'icon-sprite-tv',
                text: '<b>Tamaño más grande y cómodo</b> para agilizar tus ventas.',
            }, {
                icon: 'icon-sprite-scan-new',
                text: '<b>Facilita el uso del catálogo</b> de productos gracias a su amplia pantalla.',
            }, {
                icon: 'icon-sprite-scanner',
                text: '<b>Escanea productos y vende con catálogo</b> usando lector de códigos de barras TUU.',
                tooltip:'Debes comprar el lector de código de barras TUU como complemento a tu Desk. No viene incluido por defecto'
            }, {
                icon: "icon-sprite-pos-new",
                text: "Integración rápida con POS SE, PRO 2 o Mini-s para <b>aceptar pagos con tarjeta.</b>",
                tooltip: 'Las máquinas Desk no reciben pagos con tarjeta por sí solas, requieren de un POS complementario para aceptar pagos. POS complementario debe comprarse con un Pack Desk o aparte.'
            }, {
                icon: "icon-sprite-sp",
                text: "<b>Doble pantalla HD:</b> Principal táctil de 10,1” y secundaria para resumen de venta de 4,3”",
            }, {
                icon: "icon-sprite-doc-new",
                text: "<b>Emite boletas y facturas</b> electrónicas ilimitadas."
            }, {
                icon: 'icon-sprite-printer-new',
                text: '<b>Impresión física de comprobante</b> en alta velocidad.',
            }, {
                icon: "icon-sprite-plug",
                text: 'Conexión <b>220 voltios.</b>',
                tooltip: "El terminal TUU Desk trabaja conectado a la corriente."
            },{
                icon: 'icon-sprite-globe',
                text: '<b>Despacho Gratis</b> a todo Chile.'
            }, {
                icon: 'icon-sprite-award-new',
                text: 'Garantía de <b>2 años.</b>'
            }, {
                icon: 'icon-sprite-rolls-new',
                text: '5 rollos de papel térmico <b>gratis.</b>'
            }
        ],
        delivery: {
            fireText: '1 - 2 días hábiles RM',
            fireTextRegion: "2 - 7 días hábiles regiones",
            showFire: true,
            textRM: '1 - 3 días hábiles RM',
            textRegion: '2 - 7 días hábiles regiones'
        },
        previews: [
            {
                route: 'desk/preview-1',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-2',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-3',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-4',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-5',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-6',
                showImage: true,
                showIcon: true
            },
            {
                route: 'desk/preview-7',
                showImage: true,
                showIcon: false
            },
            {
                route:'desk/preview-8',
                showImage: true,
                showIcon: false
            }
        ]
    },

}

export const templateOPtionsGeneral: Array<Tariff> = [{
    title: 'OPCIONES DE PAGO',
    options: [{
        title: 'Al contado',
        cards: [{
            id: 'Terminal,',
            title: 'Pago único',
            subtext: 'Hasta 12 cuotas sin interés con tarjetas de crédito.',
            price: 0,
            sub_value: '+ IVA',
            count: 1,
            isSelected: true,
        }]
    }, {
        title: 'En cuotas',
        tooltip: {
            title: 'Sujeto a evaluación financiera.',
            text: 'Tu compra será sujeta a <br> evaluación en un máximo de 2 <br> horas hábiles y serás notificado a <br> través de Whatsapp y <br> correo electrónico.'
        },
        cards: [{
            id: 'T1PRO3,',
            title: ``,
            subtext: 'Descontado automáticamente de los abonos.',
            price: 0,
            sub_value: '+ IVA mensuales',
            count: 1,
            isSelected: false,
        }]
    }],
    // }, {
    //     title: 'PLANES',
    //     options: [{
    //         title: 'Plan',
    //         cards: [{
    //             id: 'TUU',
    //             title: 'Gratis',
    //             isSelected: true,
    //         }]
    //     }]
}];

export const templateOPtionsSemi: Array<Tariff> = [{
    title: 'OPCIONES DE PAGO',
    options: [{
        title: 'Al contado',
        cards: [{
            id: 'P2SEMI',
            title: 'Pago único',
            subtext: 'Hasta 12 cuotas sin interés con tarjetas de crédito.',
            price: 0,
            sub_value: '+ IVA',
            count: 1,
            isSelected: true,
        }]
    }],
},
    // {
    //     title: 'PLANES',
    //     options: [{
    //         title: 'Plan',
    //         cards: [{
    //             id: 'TUU',
    //             title: 'Gratis',
    //             isSelected: true,
    //         }]
    //     }]
    // }
]

export const OPtionsDesk: Array<Tariff> =
    [
        {
            title: 'EQUIPOS',
            options: [{
                title: 'Packs',
                tooltip: {
                    title: '',
                    text: 'Lleva tu Desk con otra máquina de pagos y recibe un 15% de descuento.'
                },
                counterCards: [{
                    id: 'DESK,PRO2,',
                    title: `Desk + PRO 2`,
                    subtext: "Ver detalles PRO 2",
                    image: "assets/images/preview-pack/pack_desk_pro-2@1x",
                    machine: {
                        name: 'PRO 2',
                        machine_image: 'preview-pro',
                        device_in_pack: 'pro2',
                        features: [
                            {
                                icon: 'smartphone-vertical',
                                text: '<b>Nueva máquina de pagos</b> con moderno diseño y pantalla más grande.'
                            },
                            {
                                icon: 'icon-sprite-credit',
                                text: '<b>Acepta pagos con tarjetas</b> de todo tipo y efectivo.',
                            },
                            {
                                icon: "icon-sprite-file-blank",
                                text: "<b>Emite boletas o facturas electrónicas</b> para todas tus ventas. Imprime o envía por correo los comprobantes.",
                            },
                            {
                                icon: "icon-sprite-pie-chart-analysis",
                                text: '',
                                quotes:true
                            },
                            {
                                icon: 'icon-sprite-circle-dollar',
                                text: '',
                                immediate:true
                            }
                        ],
                        special_features: [
                            {
                                icon: 'smartphone-vertical',
                                text: 'Diseño curvo de nueva generación'
                            },
                            {
                                icon: 'printerV2_sanitized',
                                text: 'Impresora térmica integrada',
                            }
                        ]
                    },
                    old_price: DATA_PRICE_ASSOCIATION.desk.price + DATA_PRICE_ASSOCIATION['pro2'].price,
                    price: (DATA_PRICE_ASSOCIATION.desk.price + DATA_PRICE_ASSOCIATION['pro2'].price) - (DATA_PRICE_ASSOCIATION.desk.price + DATA_PRICE_ASSOCIATION['pro2'].price) * 0.15,
                    discount: 15,
                    sub_value: '+ IVA',
                    count: 0,
                    isSelected: false,
                },
                {
                    id: 'DESK,P2SE,',
                    title: `Desk + SE`,
                    subtext: "Ver detalles SE",
                    image: 'assets/images/preview-pack/pack_desk_se@1x',
                    machine: {
                        name: 'SE',
                        machine_image: 'preview-machine-se',
                        device_in_pack: 'se',
                        features: [
                            {
                                icon: 'icon-sprite-credit-card2',
                                text: '<b>Acepta tarjetas</b> con banda magnética, chip y sin contacto.'
                            },
                            {
                                icon: 'icon-sprite-file',
                                text: '<b>Recibe pago con tarjetas</b> y emite voucher válido como boleta electrónica.'
                            },
                            {
                                icon: 'icon-sprite-attach-money',
                                text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.'
                            },
                            {
                                icon: 'icon-sprite-screen-new',
                                text: '<b>Pantalla HD</b> táctil de 5”',
                                tooltip: 'Pantalla Táctil 5" FW+, Resolución: 960x480px',
                            },
                            {
                                icon: 'icon-sprite-camera',
                                text: '<b>Cámara de 0,3</b> megapixeles'
                            },
                            {
                                icon: 'icon-sprite-battery',
                                text: '<b>Batería</b> de larga duración.'
                            },
                            {
                                icon: 'icon-sprite-award',
                                text: 'Garantía de <b>2 años.</b>'
                            }
                        ],
                        special_features: [
                            {
                                icon: 'icon-sprite-printer',//[ ] CAMBIAR ICONOS A LOS NUEVOS!!!
                                text: 'Impresora térmica<br/>integrada',
                            },
                            {
                                icon: 'icon-sprite-android-phone-slash',
                                text: 'No necesitas <br/>un Teléfono celular'
                            }
                        ]
                    },
                    old_price: DATA_PRICE_ASSOCIATION.desk.price + DATA_PRICE_ASSOCIATION['se'].price,
                    price: (DATA_PRICE_ASSOCIATION.desk.price + DATA_PRICE_ASSOCIATION['se'].price) - (DATA_PRICE_ASSOCIATION.desk.price + DATA_PRICE_ASSOCIATION['se'].price) * 0.15,
                    discount: 15,
                    sub_value: '+ IVA',
                    count: 0,
                    isSelected: false,
                },
                {
                    id: 'DESK,T1MINIS,',
                    title: `Desk + Mini S`,
                    subtext: "Ver detalles Mini S",
                    image: "assets/images/preview-pack/pack_desk_mini-s@1x",
                    machine: {
                        name: 'Mini S',
                        machine_image: 'preview-machine-mini-s',
                        device_in_pack: 'mini-s',
                        features: [
                            {
                                icon: 'icon-sprite-credit-card2',
                                text: '<b>Acepta tarjetas</b> con banda magnética, chip y sin contacto.'
                            },
                            {
                                icon: 'icon-sprite-file',
                                text: '<b>Recibe pago con tarjetas</b> y emite voucher válido como boleta electrónica.'
                            },
                            {
                                icon: 'icon-sprite-attach-money',
                                text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica.'
                            },
                            {
                                icon: 'icon-sprite-screen-new',
                                text: '<b>Pantalla táctil</b> de 5”',
                                tooltip: 'Pantalla IPS Táctil, Capacitiva, multipuntos táctiles de  5" y Resolución HD de 1280x720.',
                            },
                            {
                                icon: 'icon-sprite-battery',
                                text: '<b>Batería</b> de larga duración.'
                            },
                            {
                                icon: 'icon-sprite-award',
                                text: 'Garantía de <b>2 años.</b>'
                            }
                        ],
                        special_features: [
                            {
                                icon: 'icon-sprite-scan-new',
                                text: 'Escáner profesional integrado',
                            },
                            {
                                icon: 'icon-sprite-android-phone-slash',
                                text: 'No necesitas <br/>un Teléfono celular'
                            }
                        ]
                    },
                    old_price: DATA_PRICE_ASSOCIATION.desk.price + DATA_PRICE_ASSOCIATION['mini-s'].price,
                    price: (DATA_PRICE_ASSOCIATION.desk.price + DATA_PRICE_ASSOCIATION['mini-s'].price) - (DATA_PRICE_ASSOCIATION.desk.price + DATA_PRICE_ASSOCIATION['mini-s'].price) * 0.15,
                    discount: 15,
                    sub_value: '+ IVA',
                    count: 0,
                    isSelected: false,
                }]
            }, {
                title: 'Individual',
                tooltip: {
                    title: '',
                    text: 'Recuerda que necesitarás un POS de complemento para procesar pagos.'
                },
                counterCards: [{
                    id: 'DESK,',
                    title: 'Desk',
                    image: 'assets/images/preview-pack/pack_desk@1x',
                    price: DATA_PRICE_ASSOCIATION['desk'].price,
                    sub_value: '+ IVA',
                    count: 1,
                    isSelected: false,
                }]
            }]
        },
        {
            title: 'OPCIONES DE PAGO',
            options: [{
                title: 'Al contado',
                cards: [{
                    id: 'DESK,',
                    title: 'Pago único',
                    subtext: 'Hasta 12 cuotas sin interés con tarjetas de crédito.',
                    price: DATA_PRICE_ASSOCIATION['desk'].price,
                    sub_value: '+ IVA',
                    count: 1,
                    isSelected: true,
                }]
            },
            {
                title: 'En cuotas',
                tooltip: {
                    title: 'Sujeto a evaluación financiera.',
                    text: 'Tu compra será sujeta a <br> evaluación en un máximo de 2 <br> horas hábiles y serás notificado a <br> través de Whatsapp y <br> correo electrónico.'
                },
                cards: [{
                    id: 'DESK3,',
                    title: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION['desk'].installmentPrice) + ` + 2 Cuotas de`,
                    subtext: 'Descontado automáticamente de los abonos.',
                    price: DATA_PRICE_ASSOCIATION['desk'].installmentPrice,
                    sub_value: '+ IVA mensuales',
                    count: 1,
                    isSelected: false,
                    type: "Installment" 
                }]
            }
            ]
        }
    ];

export const dataOptions = {
    'mini': {
        id: 'T1MINI,',
        price: DATA_PRICE_ASSOCIATION['mini'].price,
        installmentId: 'T1MINI3,',
        installmentPrice: DATA_PRICE_ASSOCIATION['mini'].installmentPrice,
        installmentText: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION['mini'].installmentPrice) + ` + 2 Cuotas de`,
        options: [{
            title: 'COMPLEMENTOS',
            options: [{
                title: 'Firma electrónica',
                cards: [{
                    title: 'No, gracias',
                    isSelected: true
                }, {
                    productId: 'F1,',
                    title: 'Firma electrónica simple por 1 año.',
                    price: 11790,
                    sub_value: '+ IVA',
                    isSelected: false,
                    selectOptions: [{
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año',
                        price: 11790,
                    }, {
                        productId: 'F2,',
                        title: 'Firma electrónica simple por 2 años',
                        price: 16790,
                    }, {
                        productId: 'F3,',
                        title: 'Firma electrónica simple por 3 años',
                        price: 19790,
                    }]
                }]
            }],
        }, {
            options: [{
                title: 'Chip de internet',
                tooltip: {
                    text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                    size: 'lg'
                },
                columnCards: [{
                    id: 'CGE,',
                    title: 'Entel',
                    subtitle: 'Gratis',
                    isSelected: true
                }, {
                    id: 'CGM,',
                    title: 'Movistar',
                    subtitle: 'Gratis',
                    isSelected: false
                }]
            }]
        }
        ],
        commission: {
            id: 'T1MINICOM,',
            price: 39900,
            installmentId: 'T1MINICOM3,',
            installmentPrice: 13300,
            installmentText: `Pie de $13.300 + 2 Cuotas de`,
        }
    },
    'mini-semi-nueva': {
        id: 'MINISEMI,',
        price: DATA_PRICE_ASSOCIATION['mini-semi-nueva'].price,
        options: [{
            title: 'COMPLEMENTOS',
            options: [{
                title: 'Firma electrónica',
                cards: [{
                    title: 'No, gracias',
                    isSelected: true
                }, {
                    productId: 'F1,',
                    title: 'Firma electrónica simple por 1 año.',
                    price: 11790,
                    sub_value: '+ IVA',
                    isSelected: false,
                    selectOptions: [{
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año',
                        price: 11790,
                    }, {
                        productId: 'F2,',
                        title: 'Firma electrónica simple por 2 años',
                        price: 16790,
                    }, {
                        productId: 'F3,',
                        title: 'Firma electrónica simple por 3 años',
                        price: 19790,
                    }]
                }]
            }],
        }, {
            options: [{
                title: 'Chip de internet',
                tooltip: {
                    text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                    size: 'lg'
                },
                columnCards: [{
                    id: 'CGE,',
                    title: 'Entel',
                    subtitle: 'Gratis',
                    isSelected: true
                }, {
                    id: 'CGM,',
                    title: 'Movistar',
                    subtitle: 'Gratis',
                    isSelected: false
                }]
            }]
        }
        ]
    },
    'mini-s': {
        id: 'T1MINIS,',
        price: DATA_PRICE_ASSOCIATION['mini-s'].price,
        installmentId: 'T1MINIS3,',
        installmentPrice: DATA_PRICE_ASSOCIATION['mini-s'].installmentPrice,
        installmentText: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION["mini-s"].installmentPrice) + ` + 2 Cuotas de`,
        options: [{
            title: 'COMPLEMENTOS',
            options: [{
                title: 'Firma electrónica',
                cards: [{
                    title: 'No, gracias',
                    isSelected: true
                }, {
                    productId: 'F1,',
                    title: 'Firma electrónica simple por 1 año.',
                    price: 11790,
                    sub_value: '+ IVA',
                    isSelected: false,
                    selectOptions: [{
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año',
                        price: 11790,
                    }, {
                        productId: 'F2,',
                        title: 'Firma electrónica simple por 2 años',
                        price: 16790,
                    }, {
                        productId: 'F3,',
                        title: 'Firma electrónica simple por 3 años',
                        price: 19790,
                    }]
                }]
            }]
        }, {
            options: [{
                title: 'Chip de internet',
                tooltip: {
                    text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                    size: 'lg'
                },
                columnCards: [{
                    id: 'CGE,',
                    title: 'Entel',
                    subtitle: 'Gratis',
                    isSelected: true
                }, {
                    id: 'CGM,',
                    title: 'Movistar',
                    subtitle: 'Gratis',
                    isSelected: false
                }]
            }]
        }
        ]
    },
    'mini-s-semi-nueva': {
        id: 'MINISSEMI,',
        price: DATA_PRICE_ASSOCIATION['mini-s-semi-nueva'].price,
        options: [{
            title: 'COMPLEMENTOS',
            options: [{
                title: 'Firma electrónica',
                cards: [{
                    title: 'No, gracias',
                    isSelected: true
                }, {
                    productId: 'F1,',
                    title: 'Firma electrónica simple por 1 año.',
                    price: 11790,
                    sub_value: '+ IVA',
                    isSelected: false,
                    selectOptions: [{
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año',
                        price: 11790,
                    }, {
                        productId: 'F2,',
                        title: 'Firma electrónica simple por 2 años',
                        price: 16790,
                    }, {
                        productId: 'F3,',
                        title: 'Firma electrónica simple por 3 años',
                        price: 19790,
                    }]
                }]
            }]
        }, {
            options: [{
                title: 'Chip de internet',
                tooltip: {
                    text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                    size: 'lg'
                },
                columnCards: [{
                    id: 'CGE,',
                    title: 'Entel',
                    subtitle: 'Gratis',
                    isSelected: true
                }, {
                    id: 'CGM,',
                    title: 'Movistar',
                    subtitle: 'Gratis',
                    isSelected: false
                }]
            }]
        }
        ]
    },
    // 'pro': {
    //     id: 'Terminal,',
    //     price: DATA_PRICE_ASSOCIATION['pro'].price,
    //     installmentId: 'T1PRO3,',
    //     installmentPrice: DATA_PRICE_ASSOCIATION['pro'].installmentPrice,
    //     installmentText: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION['pro'].installmentPrice) + ` + 2 Cuotas de`,
    //     options: [
    //         //     {
    //         //     title: 'ACCESORIOS',
    //         //     options: [{
    //         //         title: 'Funda de silicona',
    //         //         cards: [{
    //         //             title: 'No, gracias',
    //         //             isSelected: true
    //         //         }, {
    //         //             id: 'FUNDA,',
    //         //             title: 'Funda de silicona',
    //         //             price: 8000,
    //         //             sub_value: '+IVA',
    //         //             count: 1,
    //         //             isSelected: false,
    //         //         }]
    //         //     }]
    //         // },
    //         {
    //             title: 'COMPLEMENTOS',
    //             options: [{
    //                 title: 'Firma electrónica',
    //                 cards: [{
    //                     title: 'No, gracias',
    //                     isSelected: true
    //                 }, {
    //                     productId: 'F1,',
    //                     title: 'Firma electrónica simple por 1 año.',
    //                     price: 11790,
    //                     sub_value: '+ IVA',
    //                     isSelected: false,
    //                     selectOptions: [{
    //                         productId: 'F1,',
    //                         title: 'Firma electrónica simple por 1 año',
    //                         price: 11790,
    //                     }, {
    //                         productId: 'F2,',
    //                         title: 'Firma electrónica simple por 2 años',
    //                         price: 16790,
    //                     }, {
    //                         productId: 'F3,',
    //                         title: 'Firma electrónica simple por 3 años',
    //                         price: 19790,
    //                     }]
    //                 }]
    //             }]
    //         }, {
    //  options: [{
    //  title: 'Chip de internet',
    //  tooltip: {
    //  text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
    //  size: 'lg'
    //  },
    //  columnCards: [{
    //  id: 'CGE,',
    //  title: 'Entel',
    //  subtitle: 'Gratis',
    //  isSelected: true
    //  }, {
    //  id: 'CGM,',
    //  title: 'Movistar',
    //  subtitle: 'Gratis',
    //  isSelected: false
    //  }]
    //  }]},
    //         {
    //             title: 'INSUMOS',
    //             options: [{
    //                 title: 'Rollos de papel térmico',
    //                 cards: [{
    //                     title: 'No, gracias',
    //                     isSelected: true,
    //                 }, {
    //                     productId: '20RO,',
    //                     title: '+ 20 Rollos de papel térmico',
    //                     price: 8500,
    //                     sub_value: '+ IVA',
    //                     count: 1,
    //                     isSelected: false,
    //                     selectOptions: [{
    //                         productId: '20RO,',
    //                         title: '+ 20 Rollos de papel térmico',
    //                         price: 8500,
    //                     }, {
    //                         productId: '50RO,',
    //                         title: '+ 50 Rollos de papel térmico',
    //                         price: 19900,
    //                     }, {
    //                         productId: '100RO,',
    //                         title: '+ 100 Rollos de papel térmico',
    //                         price: 37900,
    //                     },
    //                         //  {
    //                         //     productId: 'RO,',
    //                         //     title: '+ 200 Rollos de papel térmico',
    //                         //     price: 66000,
    //                         // }
    //                     ]
    //                 }]
    //             }]
    //         }]
    // },
    'se': {
        id: 'P2SE,',
        price: DATA_PRICE_ASSOCIATION['se'].price,
        old_price: DATA_PRICE_ASSOCIATION['se'].old_price,
        installmentId: 'P2SE3,',
        installmentPrice: DATA_PRICE_ASSOCIATION['se'].installmentPrice,
        old_installmentPrice: DATA_PRICE_ASSOCIATION['se'].old_installmentPrice,
        installmentText: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION['se'].installmentPrice) + ` + 2 Cuotas de`,
        options: [
            {
                title: 'COMPLEMENTOS',
                options: [{
                    title: 'Firma electrónica',
                    cards: [{
                        title: 'No, gracias',
                        isSelected: true
                    }, {
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año.',
                        price: 11790,
                        sub_value: '+ IVA',
                        isSelected: false,
                        selectOptions: [{
                            productId: 'F1,',
                            title: 'Firma electrónica simple por 1 año',
                            price: 11790,
                        }, {
                            productId: 'F2,',
                            title: 'Firma electrónica simple por 2 años',
                            price: 16790,
                        }, {
                            productId: 'F3,',
                            title: 'Firma electrónica simple por 3 años',
                            price: 19790,
                        }]
                    }]
                }]
            }, {
                options: [{
                    title: 'Chip de internet',
                    tooltip: {
                        text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                        size: 'lg'
                    },
                    columnCards: [{
                        id: 'CGE,',
                        title: 'Entel',
                        subtitle: 'Gratis',
                        isSelected: true
                    }, {
                        id: 'CGM,',
                        title: 'Movistar',
                        subtitle: 'Gratis',
                        isSelected: false
                    }]
                }]
            },
            {
                title: 'INSUMOS',
                options: [{
                    title: 'Rollos de papel térmico',
                    cards: [{
                        title: 'No, gracias. Sólo quiero los 5 rollos gratis incluidos',
                        isSelected: true,
                    }, {
                        productId: '20RO,',
                        title: '+ 20 Rollos de papel térmico',
                        price: 8500,
                        sub_value: '+ IVA',
                        count: 1,
                        isSelected: false,
                        selectOptions: [{
                            productId: '20RO,',
                            title: '+ 20 Rollos de papel térmico',
                            price: 8500,
                        }, {
                            productId: '50RO,',
                            title: '+ 50 Rollos de papel térmico',
                            price: 19900,
                        }, {
                            productId: '100RO,',
                            title: '+ 100 Rollos de papel térmico',
                            price: 37900,
                        },
                            //  {
                            //     productId: 'RO,',
                            //     title: '+ 200 Rollos de papel térmico',
                            //     price: 66000,
                            // }
                        ]
                    }]
                }]
            },
            {
                title: 'ACCESORIOS',
                options: [{
                    title: 'Funda de silicona',
                    cards: [{
                        title: 'No gracias',
                        isSelected: true
                    }, {
                        id: 'FUNDAP2SE,',
                        title: 'Funda de silicona',
                        old_price: 8000,
                        price: 6000,
                        sub_value: '+IVA',
                        count: 1,
                        isSelected: false,
                        counter:true
                    }]
                }]
            }]
    },
    'se-semi-nueva': {
        id: 'SESEMI,',
        price: DATA_PRICE_ASSOCIATION['se-semi-nueva'].price,
        options: [
            {
                title: 'COMPLEMENTOS',
                options: [{
                    title: 'Firma electrónica',
                    cards: [{
                        title: 'No, gracias',
                        isSelected: true
                    }, {
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año.',
                        price: 11790,
                        sub_value: '+ IVA',
                        isSelected: false,
                        selectOptions: [{
                            productId: 'F1,',
                            title: 'Firma electrónica simple por 1 año',
                            price: 11790,
                        }, {
                            productId: 'F2,',
                            title: 'Firma electrónica simple por 2 años',
                            price: 16790,
                        }, {
                            productId: 'F3,',
                            title: 'Firma electrónica simple por 3 años',
                            price: 19790,
                        }]
                    }]
                }]
            }, {
                options: [{
                    title: 'Chip de internet',
                    tooltip: {
                        text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                        size: 'lg'
                    },
                    columnCards: [{
                        id: 'CGE,',
                        title: 'Entel',
                        subtitle: 'Gratis',
                        isSelected: true
                    }, {
                        id: 'CGM,',
                        title: 'Movistar',
                        subtitle: 'Gratis',
                        isSelected: false
                    }]
                }]
            },
            {
                title: 'INSUMOS',
                options: [{
                    title: 'Rollos de papel térmico',
                    cards: [{
                        title: 'No, gracias. Sólo quiero los 5 rollos gratis incluidos',
                        isSelected: true,
                    }, {
                        productId: '20RO,',
                        title: '+ 20 Rollos de papel térmico',
                        price: 8500,
                        sub_value: '+ IVA',
                        count: 1,
                        isSelected: false,
                        selectOptions: [{
                            productId: '20RO,',
                            title: '+ 20 Rollos de papel térmico',
                            price: 8500,
                        }, {
                            productId: '50RO,',
                            title: '+ 50 Rollos de papel térmico',
                            price: 19900,
                        }, {
                            productId: '100RO,',
                            title: '+ 100 Rollos de papel térmico',
                            price: 37900,
                        },
                            //  {
                            //     productId: 'RO,',
                            //     title: '+ 200 Rollos de papel térmico',
                            //     price: 66000,
                            // }
                        ]
                    }]
                }]
            },
            {
                title: 'ACCESORIOS',
                options: [{
                    title: 'Funda de silicona',
                    cards: [{
                        title: 'No gracias',
                        isSelected: true
                    }, {
                        id: 'FUNDAP2SE,',
                        title: 'Funda de silicona',
                        old_price: 8000,
                        price: 6000,
                        sub_value: '+IVA',
                        count: 1,
                        isSelected: false,
                        counter:true
                    }]
                }]
            }]
    },
    'pro-2': {
        id: 'PRO2,',
        price: DATA_PRICE_ASSOCIATION['pro2'].price,
        old_price:DATA_PRICE_ASSOCIATION['pro2'].old_price,
        installmentId: 'PRO2_3,',
        installmentPrice: DATA_PRICE_ASSOCIATION['pro2'].installmentPrice,
        old_installmentPrice: DATA_PRICE_ASSOCIATION['pro2'].old_installmentPrice,
        installmentText: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION['pro2'].installmentPrice) + ` + 2 Cuotas de`,
        options: [
            {
                title: 'COMPLEMENTOS',
                options: [{
                    title: 'Firma electrónica',
                    cards: [{
                        title: 'No, gracias',
                        isSelected: true
                    }, {
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año.',
                        price: 11790,
                        sub_value: '+ IVA',
                        isSelected: false,
                        selectOptions: [{
                            productId: 'F1,',
                            title: 'Firma electrónica simple por 1 año',
                            price: 11790,
                        }, {
                            productId: 'F2,',
                            title: 'Firma electrónica simple por 2 años',
                            price: 16790,
                        }, {
                            productId: 'F3,',
                            title: 'Firma electrónica simple por 3 años',
                            price: 19790,
                        }]
                    }]
                }]
            }, {
                options: [{
                    title: 'Chip de internet',
                    tooltip: {
                        text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                        size: 'lg'
                    },
                    columnCards: [{
                        id: 'CGE,',
                        title: 'Entel',
                        subtitle: 'Gratis',
                        isSelected: true
                    }, {
                        id: 'CGM,',
                        title: 'Movistar',
                        subtitle: 'Gratis',
                        isSelected: false
                    }]
                }]
            },
            {
                title: 'INSUMOS',
                options: [{
                    title: 'Rollos de papel térmico',
                    cards: [{
                        title: 'No, gracias. Sólo quiero los 5 rollos gratis incluidos',
                        isSelected: true,
                    }, {
                        productId: '20RO,',
                        title: '+ 20 Rollos de papel térmico',
                        price: 8500,
                        sub_value: '+ IVA',
                        count: 1,
                        isSelected: false,
                        selectOptions: [{
                            productId: '20RO,',
                            title: '+ 20 Rollos de papel térmico',
                            price: 8500,
                        }, {
                            productId: '50RO,',
                            title: '+ 50 Rollos de papel térmico',
                            price: 19900,
                        }, {
                            productId: '100RO,',
                            title: '+ 100 Rollos de papel térmico',
                            price: 37900,
                        },
                            //  {
                            //     productId: 'RO,',
                            //     title: '+ 200 Rollos de papel térmico',
                            //     price: 66000,
                            // }
                        ]
                    }]
                }]
            },
            {
                title: 'ACCESORIOS',
                options: [{
                    title: 'Funda de silicona',
                    cards: [{
                        title: 'No gracias',
                        isSelected: true
                    }, {
                        id: 'FUNDAPRO2,',
                        title: 'Funda de silicona',
                        price: 10000,
                        sub_value: '+IVA',
                        count: 1,
                        isSelected: false,
                        counter:true
                    }]
                }]
            }]
    },
    'pro-semi-nueva': {
        id: 'P2SEMI,',
        price: DATA_PRICE_ASSOCIATION['pro-semi-nueva'].price,
        options: [{
            title: 'COMPLEMENTOS',
            options: [{
                title: 'Firma electrónica',
                cards: [{
                    title: 'No, gracias',
                    isSelected: true
                }, {
                    productId: 'F1,',
                    title: 'Firma electrónica simple por 1 año.',
                    price: 11790,
                    sub_value: '+ IVA',
                    isSelected: false,
                    selectOptions: [{
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año',
                        price: 11790,
                    }, {
                        productId: 'F2,',
                        title: 'Firma electrónica simple por 2 años',
                        price: 16790,
                    }, {
                        productId: 'F3,',
                        title: 'Firma electrónica simple por 3 años',
                        price: 19790,
                    }]
                }]
            }]
        }, {
            options: [{
                title: 'Chip de internet',
                tooltip: {
                    text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                    size: 'lg'
                },
                columnCards: [{
                    id: 'CGE,',
                    title: 'Entel',
                    subtitle: 'Gratis',
                    isSelected: true
                }, {
                    id: 'CGM,',
                    title: 'Movistar',
                    subtitle: 'Gratis',
                    isSelected: false
                }]
            }]
        },
        {
            title: 'INSUMOS',
            options: [{
                title: 'Rollos de papel térmico',
                cards: [{
                    title: 'No, gracias. Sólo quiero los 5 rollos gratis incluidos',
                    isSelected: true,
                }, {
                    productId: '20RO,',
                    title: '+ 20 Rollos de papel térmico',
                    price: 8500,
                    sub_value: '+ IVA',
                    count: 1,
                    isSelected: false,
                    selectOptions: [{
                        productId: '20RO,',
                        title: '+ 20 Rollos de papel térmico',
                        price: 8500,
                    }, {
                        productId: '50RO,',
                        title: '+ 50 Rollos de papel térmico',
                        price: 19900,
                    }, {
                        productId: '100RO,',
                        title: '+ 100 Rollos de papel térmico',
                        price: 37900,
                    },
                        //  {
                        //     productId: 'RO,',
                        //     title: '+ 200 Rollos de papel térmico',
                        //     price: 66000,
                        // }
                    ]
                }]
            }]
        }]
    },
    'desk': {
        id: 'DESK,',
        price: DATA_PRICE_ASSOCIATION['desk'].price,
        old_price: DATA_PRICE_ASSOCIATION['desk'].old_price,
        installmentId: 'DESK3,',
        installmentPrice: DATA_PRICE_ASSOCIATION['desk'].installmentPrice,
        installmentText: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION['desk'].installmentPrice) + ` + 2 Cuotas de`,
        optionsPriority: [
            {
                title: 'EQUIPOS',
                options: [{
                    title: 'POS Principal',
                    counterCard: [{
                        id: 'DESK,',
                        title: 'TUU Desk',
                        subtext: "Ver detalles",
                        price: DATA_PRICE_ASSOCIATION['desk'].price,
                        sub_value: '+ IVA',
                        count: 1,
                        isSelected: false,
                    }]
                }]
            }, {
                title: '',
                options: [{
                    title: 'POS Complementario',
                    tooltip: {
                        title: '',
                        text: 'Es necesario tener una máquina POS de complemento para aceptar los pagos.'
                    },
                    counterCard: [{
                        id: 'P2SE,',
                        title: `TUU SE`,
                        subtext: "Ver detalles",
                        price: DATA_PRICE_ASSOCIATION['se'].price,
                        sub_value: '+ IVA',
                        count: 0,
                        isSelected: false,
                    },
                    {
                        id: 'T1MINIS,',
                        title: `TUU Mini S`,
                        price: DATA_PRICE_ASSOCIATION['mini-s'].price,
                        sub_value: '+ IVA',
                        count: 0,
                        isSelected: false,
                    },
                    {
                        id: 'T1MINI,',
                        title: `TUU Mini`,
                        subtext: "Ver detalles",
                        price: DATA_PRICE_ASSOCIATION['mini'].price,
                        sub_value: '+ IVA',
                        count: 0,
                        isSelected: false,
                    },
                    {
                        id: '',
                        title: `Ya tengo un POS TUU para complementar`,
                        isSelected: false
                    }
                    ]
                }]
            }
        ],
        options: [
            {
                title: 'COMPLEMENTOS',
                options: [{
                    title: 'Firma electrónica',
                    cards: [{
                        title: 'No, gracias',
                        isSelected: true
                    }, {
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año.',
                        price: 11790,
                        sub_value: '+ IVA',
                        isSelected: false,
                        selectOptions: [{
                            productId: 'F1,',
                            title: 'Firma electrónica simple por 1 año',
                            price: 11790,
                        }, {
                            productId: 'F2,',
                            title: 'Firma electrónica simple por 2 años',
                            price: 16790,
                        }, {
                            productId: 'F3,',
                            title: 'Firma electrónica simple por 3 años',
                            price: 19790,
                        }]
                    }]
                },
                {
                    title: 'Lector de código de barras',
                    tooltip: {
                        text: 'El uso del lector de código de barras es exclusivo del terminal Desk.',
                        size: 'lg'
                    },
                    cards: [{
                        title: 'No, gracias',
                        isSelected: true
                    }, {
                        productId: 'LEC,',
                        title: 'Pistola Lector de código de barras 1D/2D',
                        image: 'assets/images/preview-complements/preview-lec@1x',
                        subtext:'Ver detalles Lector',
                        price: 15900,
                        sub_value: '+ IVA',
                        isSelected: false,
                        count:1,
                        type:"LEC"
                    }]
                }
            ]
            },
            {
                options: [{
                    title: 'Chip de internet',
                    tooltip: {
                        text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                        size: 'lg'
                    },
                    columnCards: [{
                        id: 'CGE,',
                        title: 'Entel',
                        subtitle: 'Gratis',
                        isSelected: true
                    }, {
                        id: 'CGM,',
                        title: 'Movistar',
                        subtitle: 'Gratis',
                        isSelected: false
                    }]
                }]
            },
            {
                title: 'INSUMOS',
                options: [{
                    title: 'Rollos de papel térmico',
                    cards: [{
                        title: 'No, gracias. Sólo quiero los 5 rollos gratis incluidos',
                        isSelected: true,
                    }, {
                        productId: '20RO,',
                        title: '+ 20 Rollos de papel térmico',
                        price: 8500,
                        sub_value: '+ IVA',
                        count: 1,
                        isSelected: false,
                        selectOptions: [{
                            productId: '20RO,',
                            title: '+ 20 Rollos de papel térmico',
                            price: 8500,
                        }, {
                            productId: '50RO,',
                            title: '+ 50 Rollos de papel térmico',
                            price: 19900,
                        }, {
                            productId: '100RO,',
                            title: '+ 100 Rollos de papel térmico',
                            price: 37900,
                        }
                        ]
                    }]
                }]
            }
        ]
    },
    'desk-semi-nueva': {
        id: 'DESKSEMI,',
        price: DATA_PRICE_ASSOCIATION['desk-semi-nueva'].price,
        options: [
            {
                title: 'COMPLEMENTOS',
                options: [{
                    title: 'Firma electrónica',
                    cards: [{
                        title: 'No, gracias',
                        isSelected: true
                    }, {
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año.',
                        price: 11790,
                        sub_value: '+ IVA',
                        isSelected: false,
                        selectOptions: [{
                            productId: 'F1,',
                            title: 'Firma electrónica simple por 1 año',
                            price: 11790,
                        }, {
                            productId: 'F2,',
                            title: 'Firma electrónica simple por 2 años',
                            price: 16790,
                        }, {
                            productId: 'F3,',
                            title: 'Firma electrónica simple por 3 años',
                            price: 19790,
                        }]
                    }]
                },
                {
                    title: 'Lector de código de barras',
                    tooltip: {
                        text: 'El uso del lector de código de barras es exclusivo del terminal Desk.',
                        size: 'lg'
                    },
                    cards: [{
                        title: 'No, gracias',
                        isSelected: true
                    }, {
                        productId: 'LEC,',
                        title: 'Pistola Lector de código de barras 1D/2D',
                        image: 'assets/images/preview-complements/preview-lec@1x',
                        subtext:'Ver detalles Lector',
                        price: 15900,
                        sub_value: '+ IVA',
                        isSelected: false,
                        count:1,
                        type:"LEC"
                    }]
                }]
            }, {
                options: [{
                    title: 'Chip de internet',
                    tooltip: {
                        text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado para otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                        size: 'lg'
                    },
                    columnCards: [{
                        id: 'CGE,',
                        title: 'Entel',
                        subtitle: 'Gratis',
                        isSelected: true
                    }, {
                        id: 'CGM,',
                        title: 'Movistar',
                        subtitle: 'Gratis',
                        isSelected: false
                    }]
                }]
            },
            {
                title: 'INSUMOS',
                options: [{
                    title: 'Rollos de papel térmico',
                    cards: [{
                        title: 'No, gracias. Sólo quiero los 5 rollos gratis incluidos',
                        isSelected: true,
                    }, {
                        productId: '20RO,',
                        title: '+ 20 Rollos de papel térmico',
                        price: 8500,
                        sub_value: '+ IVA',
                        count: 1,
                        isSelected: false,
                        selectOptions: [{
                            productId: '20RO,',
                            title: '+ 20 Rollos de papel térmico',
                            price: 8500,
                        }, {
                            productId: '50RO,',
                            title: '+ 50 Rollos de papel térmico',
                            price: 19900,
                        }, {
                            productId: '100RO,',
                            title: '+ 100 Rollos de papel térmico',
                            price: 37900,
                        },
                            //  {
                            //     productId: 'RO,',
                            //     title: '+ 200 Rollos de papel térmico',
                            //     price: 66000,
                            // }
                        ]
                    }]
                }]
            }]
    }
};