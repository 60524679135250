import { Component, Input} from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';

@Component({
    selector: 'app-boleta-call-to-action',
    templateUrl: './boleta-call-to-action.component.html',
    styleUrls: ['./boleta-call-to-action.component.scss']
})
export class BoletaCallToActionComponent{
    @Input() public loadingImages: boolean;

    constructor(
        private buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) {
    }

    public navigateToSection(className: string, event: Event): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
        this.buttonTraceService.traceButton(event, 'scroll');
    }

}
