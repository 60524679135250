import { Injectable } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';

declare var Storylane: any;

@Injectable({
  providedIn: 'root'
})
export class TourService {
  private baseWidth = 336;
  private baseHeight = 630;
  private baseDemoUrl = 'https://app.storylane.io/demo/';

  constructor(private buttonTraceService: ButtonTraceService) {}

  public openDialog(event: Event, tourID: string): void {
    const finalDemoUrl = this.baseDemoUrl + tourID;
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const scaleHeight = windowHeight / this.baseHeight;
    const scaleWidth = windowWidth / this.baseWidth;
    const scale = Math.min(scaleHeight, scaleWidth);
    const widthS = this.baseWidth * scale;
    const heightS = this.baseHeight * scale;
    const paddingBottom = heightS;

    this.buttonTraceService.traceButton(event, 'popup');
    Storylane.Play({
      type: 'popup',
      demo_type: 'image',
      width: `${widthS}px`,
      height: `${heightS}px`,
      scale: 1,
      demo_url: finalDemoUrl,
      padding_bottom: `${paddingBottom}px`
    });
  }
}