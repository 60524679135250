import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { urlMapping } from '../../route-ids';
import { TourService } from '../../services/tour.service';
import { TourVirtual } from '../../../../environments/environment'

@Component({
  selector: 'app-section-tour-boleta',
  templateUrl: './section-tour-boleta.component.html',
  styleUrls: ['./section-tour-boleta.component.scss']
})
export class SectionTourBoletaComponent implements OnInit {
  @Input() public loadingImages: boolean;
  public url: string;

  private tourID = TourVirtual.BOLETA;

  constructor(
    private _router: Router,
    private tourService: TourService
  ) {}

  ngOnInit(): void {
    const urlTree = this._router.parseUrl(this._router.url);
    const primarySegment = urlTree.root.children['primary'];
    const urlSegment = primarySegment?.segments[0]?.path || 'home';
    this.url = urlMapping[urlSegment] || urlSegment;
  }

  public openDialog(event: Event): void {
    this.tourService.openDialog(event, this.tourID);
  }
}
