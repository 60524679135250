import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, PLATFORM_ID, HostListener, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from '../shared/services/api.service';
import { UrlService } from '../shared/services/url.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { RESPONSE_TABLE_CERT, RESPONSE_TABLE_BOLE, RESPONSE_TABLE_HES, ITextMapDocs, ITextMapBoleta, ITextMapHES, generateTextMapDocs, generateTextMapBoleta, generateTextMapHES, translateStatus, IResponseFromAPI } from './seguimiento-common/seguimiento-common.component';
import { DomSanitizer } from '@angular/platform-browser';
import { formatRUT, testRUT } from '../../../../common/functions/rut-functions';
import { ButtonTraceService } from '../../../../common/services/button-trace';
@Component({
    selector: 'app-seguimiento-page',
    templateUrl: './seguimiento-page.component.html',
    styleUrls: ['./seguimiento-page.component.scss'],
})
export class SeguimientoPageComponent implements OnInit, AfterViewInit {
    @ViewChild('fakeInputWidth') public fakeInputWidth: ElementRef;
    @ViewChild('TuuCasoInput') public CasoInput: ElementRef;
    public testRUT = testRUT;
    public isLoading: boolean = false;
    public showSection = {
        docs: false,
        boleta: false,
        hes: false
    };
    public displayOthers = false;
    public textMapDocs: ITextMapDocs = generateTextMapDocs();
    public textMapBoleta: ITextMapBoleta = generateTextMapBoleta();
    public textMapHES: ITextMapHES = generateTextMapHES();
    public displayResults: boolean = false;
    public displayBoleta: boolean = false;
    public displayDocs: boolean = false;
    public displayError: boolean = false;
    private isMobile: boolean = false;
    private siteKey: string = '6LeFGJMUAAAAAEDLJLme8uOEGMOTwsjYB6v-gZe7';
    private captcha: string;
    public rutURL;
    public inputTuuCaso: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(30),
    ]);

    @HostListener('window:resize')
    onResize() {
        this.isMobile = window.innerWidth < 990;
    }

    constructor(
        @Inject(DOCUMENT) public document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private apiService: ApiService,
        private urlService: UrlService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private sanitizer: DomSanitizer,
        private detector: ChangeDetectorRef,
        public buttonTraceService: ButtonTraceService
    ) { }
    public ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.isMobile = window.innerWidth < 990;
            this.rutURL = this.urlService.getValue('rut');
            if (this.rutURL) {
                this.inputTuuCaso.setValue(this.rutURL);
                this.onSearch();
            }
        }
    }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.CasoInput.nativeElement.focus();
            setTimeout(() => {
                const keyEvent = new Event('keyup');
                this.CasoInput.nativeElement.dispatchEvent(keyEvent);
            }, 1);
        }
    }

    public onSearch(): void {
        if (this.inputTuuCaso.invalid && testRUT(this.inputTuuCaso.value) === false) return;
        const RUT = this.inputTuuCaso.value.replaceAll('.', '')
        this.startCaptcha(true).then(() => {
            this.cleanAll();
            const body = {
                input: RUT,
                option: 'searchV3',
                captcha: this.captcha
            };
            this.apiService
                .getStatusJira(body)
                .subscribe((response) => {
                    this.processData(response);
                },
                    (err) => {
                        this.isLoading = false;
                        this.showError();
                    },
                    () => { this.isLoading = false; });
        });
    }

    private processData(response: IResponseFromAPI) {
        if (this.displayResults) {
            this.cleanAll();
        }
        let errors = { err1: false, err2: false, err3: false }
        try {
            if (response?.res1 && response?.res2 && response?.res3) {
                if (response.res1 == 200) {
                    errors.err1 = this.processResponse(response.certificacion_inicial, 'certificación', 'certificacion', 'docs', RESPONSE_TABLE_CERT);
                }
                else errors.err1 = true;
                if (response.res2 == 200) {
                    errors.err2 = this.processResponse(response.boletas, 'certificación', 'certificacion', 'boleta', RESPONSE_TABLE_BOLE);
                }
                else errors.err2 = true;
                if (response.res3 == 200) {
                    errors.err3 = this.processResponse(response.hes, 'hes', 'hes', 'hes', RESPONSE_TABLE_HES);
                }
                else errors.err3 = true;
                if (errors.err1 && errors.err2 && errors.err3) {
                    this.showError()
                }
            }
        }
        catch (err) {
            console.warn(err);
            this.showError();
        }
    }

    private processResponse(response: any, string_match: string, type: string, section: string, responseTable: any): boolean {
        const sortedResponse = response.issues.sort((a, b) => {
            const dateA = new Date(a.fields.updated);
            const dateB = new Date(b.fields.updated);
            return dateB.getTime() - dateA.getTime();
        });
        const statusToCheck = sortedResponse[0];
        if (statusToCheck.fields.summary.toLowerCase().includes(string_match)) {
            const stats = responseTable.find((obj) => obj.Caso.toLowerCase().includes(statusToCheck.fields.status.name.toLowerCase()));
            if (stats) {
                let nameStatus = statusToCheck.fields.status.name;
                if (type === 'hes') {
                    nameStatus = translateStatus(nameStatus);
                }
                this.buildText(type, stats, section, {
                    date: statusToCheck.fields.updated,
                    motivo: nameStatus
                })
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    private cleanAll() {
        this.displayResults = false;
        this.displayBoleta = false;
        this.displayDocs = false;
        this.displayError = false;
        this.textMapBoleta = generateTextMapBoleta();
        this.textMapDocs = generateTextMapDocs();
        this.detector.detectChanges();
    }

    private showError() {
        this.cleanAll();
        this.isLoading = false;
        this.displayError = true;
    }

    private buildText(c: string, e: any, type: string, datamap: any): void {
        let labelClass = "label_active"
        let elipse = "elipse";
        switch (type) {
            case "docs":
                if (e.Caso.toLowerCase().includes('error') ||
                    e.Estados.toLowerCase().includes('error') ||
                    e.Caso.toLowerCase().includes('conflicto') ||
                    e.Estados.toLowerCase().includes('conflicto') ||
                    e.Caso.toLowerCase().includes('cancelad') ||
                    e.Estados.toLowerCase().includes('cancelad') ||
                    e.Caso.toLowerCase().includes('detenid') ||
                    e.Estados.toLowerCase().includes('detenid')) {
                    labelClass = "label_error"
                    elipse = "elipse-error"
                }
                this.insertText(c, e.Avance, 'titulo', '<span><h1 class="' + elipse + '">' + Number(Number(e.Avance) + 1) + '</h1></span><h1 class="title-state">' + e.Estados + '</h1>', type);
                if (e.MensajeSecundario != undefined) this.insertText(c, e.Avance, 'descripcion', '<div class="description"><p>' + e.Mensaje + '</p><div class="recommended"><h1>Acciones recomendadas</h1>' + e.MensajeSecundario + '</div></div>', type);
                else this.insertText(c, e.Avance, 'descripcion', '<div class="description"><p>' + e.Mensaje + '</p></div>', type);
                this.showSection.docs = true;
                this.textMapDocs.certificacion.activeIndex = Number(e.Avance);
                for (let index = 0; index < Number(e.Avance); index++) {
                    this.textMapDocs.certificacion.cardList[index].className = "label_active";
                    this.textMapDocs.certificacion.cardList[index].elipse = "elipse";
                }
                this.textMapDocs.certificacion.cardList[Number(e.Avance)].className = labelClass;
                this.textMapDocs.certificacion.cardList[Number(e.Avance)].elipse = elipse;
                this.textMapDocs.certificacion.cardList[Number(e.Avance)].motivo = datamap.motivo;
                this.textMapDocs.certificacion.cardList[Number(e.Avance)].lastUpdate = datamap.date;
                this.displayDocs = true;
                break;
            case "boleta":
                if (e.Caso.toLowerCase().includes('error') ||
                    e.Estados.toLowerCase().includes('error') ||
                    e.Caso.toLowerCase().includes('cancelad') ||
                    e.Estados.toLowerCase().includes('cancelad') ||
                    e.Caso.toLowerCase().includes('conflicto') ||
                    e.Estados.toLowerCase().includes('conflicto')) {
                    labelClass = "label_error"
                    elipse = "elipse-error"
                }
                this.insertText(c, e.Avance, 'titulo', '<span><h1 class="' + elipse + '">' + Number(Number(e.Avance) + 1) + '</h1></span><h1 class="title-state">' + e.Estados + '</h1>', type);
                if (e.MensajeSecundario != undefined) this.insertText(c, e.Avance, 'descripcion', '<div class="description"><p>' + e.Mensaje + '</p><div class="recommended"><h1>Acciones recomendadas</h1>' + e.MensajeSecundario + '</div></div>', type);
                else this.insertText(c, e.Avance, 'descripcion', '<div class="description"><p>' + e.Mensaje + '</p></div>', type);
                this.showSection.boleta = true;
                this.textMapBoleta.certificacion.activeIndex = Number(e.Avance);
                for (let index = 0; index < Number(e.Avance); index++) {
                    this.textMapBoleta.certificacion.cardList[index].className = "label_active";
                    this.textMapBoleta.certificacion.cardList[index].elipse = "elipse"
                }
                this.textMapBoleta.certificacion.cardList[Number(e.Avance)].className = labelClass;
                this.textMapBoleta.certificacion.cardList[Number(e.Avance)].elipse = elipse;
                this.textMapBoleta.certificacion.cardList[Number(e.Avance)].motivo = datamap.motivo;
                this.textMapBoleta.certificacion.cardList[Number(e.Avance)].lastUpdate = datamap.date;
                this.displayBoleta = true;
                break
            case "hes":
                if (e.Caso.toLowerCase().includes('error') ||
                    e.Estados.toLowerCase().includes('error') ||
                    e.Caso.toLowerCase().includes('conflicto') ||
                    e.Estados.toLowerCase().includes('conflicto') ||
                    e.Caso.toLowerCase().includes('cancelad') ||
                    e.Estados.toLowerCase().includes('cancelad') ||
                    e.Caso.toLowerCase().includes('detenid') ||
                    e.Estados.toLowerCase().includes('detenid')) {
                    labelClass = "label_error"
                    elipse = "elipse-error"
                }
                this.insertText(c, e.Avance, 'titulo', '<span><h1 class="' + elipse + '">' + Number(Number(e.Avance) + 1) + '</h1></span><h1 class="title-state">' + e.Estados + '</h1>', type);
                if (e.MensajeSecundario != undefined) this.insertText(c, e.Avance, 'descripcion', '<div class="description"><p>' + e.Mensaje + '</p><div class="recommended"><h1>Acciones recomendadas</h1>' + e.MensajeSecundario + '</div></div>', type);
                else this.insertText(c, e.Avance, 'descripcion', '<div class="description"><p>' + e.Mensaje + '</p></div>', type);
                this.showSection.hes = true;
                this.textMapHES.activeIndex = Number(e.Avance);
                for (let index = 0; index < Number(e.Avance); index++) {
                    this.textMapHES.cardList[index].className = "label_active";
                    this.textMapHES.cardList[index].elipse = "elipse";
                }
                this.textMapHES.cardList[Number(e.Avance)].className = labelClass;
                this.textMapHES.cardList[Number(e.Avance)].elipse = elipse;
                this.textMapHES.cardList[Number(e.Avance)].motivo = datamap.motivo;
                this.textMapHES.cardList[Number(e.Avance)].lastUpdate = datamap.date;
                this.displayOthers = true;
                break;
            default:
                break;
        }
        if (this.displayBoleta || this.displayDocs || this.displayOthers) this.displayResults = true;
    }

    private insertText(card: string, step: string, part: string, text: any, type: string): void {
        switch (type) {
            case "docs":
                this.textMapDocs[card].cardList[Number(step)][part] = this.sanitizer.bypassSecurityTrustHtml(text);
                break;
            case "boleta":
                this.textMapBoleta[card].cardList[Number(step)][part] = this.sanitizer.bypassSecurityTrustHtml(text);
                break;
            case "hes":
                this.textMapHES.cardList[Number(step)][part] = this.sanitizer.bypassSecurityTrustHtml(text);
                break;
            default:
                break;
        }
    }

    public allowOnlyNumbers(): void {
        this.inputTuuCaso.setValue(this.inputTuuCaso.value.replace(/[^0-9kK.-]/g, '').toUpperCase());
    }

    public moveClearBtn(): void {
        if (this.inputTuuCaso.value.length > 30) { return }
        const fakeWidthEl = this.fakeInputWidth.nativeElement as HTMLSpanElement;
        const leftPosition = fakeWidthEl.clientWidth + 16;
        const clearBtn = this.document.getElementById('t-seguimiento-borrar') as HTMLButtonElement;
        this.inputTuuCaso.setValue(formatRUT(this.inputTuuCaso.value));
        clearBtn.style.left = leftPosition + 'px';
    }

    public clearInputValue(): void {
        this.inputTuuCaso.setValue('');
        this.CasoInput.nativeElement.focus();
    }

    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    private startCaptcha(state: boolean): Promise<string> {
        let bottom = this.isMobile ? '165px;' : '180px;';
        let promise = new Promise<string>((resolve) => {
            this.isLoading = true;
            this.reCaptchaV3Service.execute(
                this.siteKey,
                'Caso_estado',
                (token) => {
                    if (state) {
                        this.captcha = token
                        const element = document.getElementsByClassName('grecaptcha-badge')[0];
                        element.setAttribute(
                            'style',
                            element.getAttribute('style') +
                            'bottom:' +
                            bottom +
                            ' z-index: 1;'
                        );
                        resolve(token);
                    }
                }
            )
        })
        return promise;
    }
}
