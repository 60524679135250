import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';
import { Router } from '@angular/router';
import { urlMapping } from '../shared/route-ids';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

    public urlEnv = urlEnv;

    public showImage1: boolean;
    public showImage2: boolean;
    public showImage3: boolean;
    public showImage4: boolean;
    public showImage5: boolean;
    public isDirect: boolean = false;
    public url: string;

    public dataPriceSection = {
        buttons: [
            {
                id: 'TUU-H-precios-compra-simple'
            },
            {
                id: 'TUU-H-precios-compra-con-complementos'
            }
        ]
    };

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: unknown,
        public dialog: MatDialog,
        private seoService: SEOService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this._setMeta();
        this.seoService.updateCanonicalUrl('');
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
        if(this.router.url.startsWith('/directa/.')){
            this.isDirect = true;
        }

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('TUU - La máquina POS para pagar con tarjeta en tu negocio.');
        this._meta.setTag('description', 'Compra TUU máquina POS para pagar con tarjeta con las comisiones más bajas, abono al día hábil siguiente y emite boleta electrónica.');
        this._meta.setTag('keywords', 'Pagos con tarjetas, dispositivo para pagar con tarjeta, sistema de pagos, sistema de pago con tarjeta, máquina para pago con tarjeta, POS, máquina POS, máquina redcompra, Punto de venta, sistema de venta, software punto de venta,POS, control de inventario, gestión de inventario, control de stock, boleta electrónica, boleta electrónica sii, boleta digital, boleta online, máquina boleta electrónica, sistema boleta electrónica. Factura electrónica, facturación electrónica, factura digital, sistema facturación electrónica.');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'TUU, Recibe pagos con tarjeta y administra tu negocio en un solo lugar.');
    }
}
