// import { isPlatformBrowser } from '@angular/common';
import { Component } from '@angular/core';
// import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';

@Component({
    selector: 'app-first-screen-resellers',
    templateUrl: './first-screen-resellers.component.html',
    styleUrls: ['./first-screen-resellers.component.scss']
})
export class FirstScreenResellersComponent {

    constructor(
        private scrollToService: ScrollToService
    ) {
    }

    // public goToUrl(url: string, event: Event): void {
    //     if (isPlatformBrowser(this.platformId)) {
    //         window.open(url, '_blank');
    //         traceButton2(event, 'typeform');
    //     }
    // }

    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToService.scrollToSection(className);
        }, 500);
    }

}
