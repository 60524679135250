import { Component, Input } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';
import { ScrollToService } from '../../shared/services/scroll-to';
import { Router } from '@angular/router';

@Component({
    selector: 'app-credit-benefits',
    templateUrl: './credit-benefits.component.html',
    styleUrls: ['./credit-benefits.component.scss']
})
export class CreditBenefitsComponent {
    @Input() public loadingImages: boolean;

    cards: Array<{ icon: string; title: string; text: string }> = [
        {
            icon: 'icon-sprite-file',
            title: 'Sin papeleos',
            text: 'Tendrás acceso a préstamos pre aprobados que podrás solicitar en 5 minutos 100% en línea, sin perder tiempo en reuniones presenciales.'
        },
        {
            icon: 'icon-sprite-refresh',
            title: 'Financiamiento continuo',
            text: 'Mientras sigas teniendo ventas, recibirás nuevas ofertas para acompañar el crecimiento de tu negocio.'
        },
        {
            icon: 'icon-sprite-schedule',
            title: 'Depósito en 24 horas',
            text: 'Dinero disponible en tu cuenta bancaria en 1 día hábil.'
        }
    ];

    constructor(
        private router: Router,
        public buttonTraceService: ButtonTraceService,
        private scrollToService: ScrollToService
    ) {
    }

    public showPosHome(): void {
        this.router.navigate(['/']).then(() => {
            window.scroll(0, 0);
            setTimeout(() => {
                this.scrollToService.scrollToSection("sectionPrice");
            }, 500);
        });
        this.scrollToService.scrollToSection("sectionPrice");
    }
}
