import { Injectable } from '@angular/core';
declare var dataLayer;

@Injectable({
    providedIn: 'root',
  })
  export class ButtonTraceService { 
    constructor() {
    }
    traceButton(event: Event, type: string, experience?: string): void {
        const buttonId_source = (event.currentTarget as HTMLElement).id;
        const buttonId = experience ? `${buttonId_source}-${experience}` : buttonId_source;
        dataLayer.push({
            'event': 'traceButton', 'element': {
                'idButton': buttonId,
                'typeButton': type
            }
        });
    }
  }
