import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';

@Component({
    selector: 'app-inventory-page',
    templateUrl: './inventory-page.component.html'
})
export class InventoryPageComponent implements OnInit {

    public urlEnv = urlEnv;

    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    public dataPriceSection = {
        buttons: [
            {
                id: 'TUU-inven-precios-compra-simple'
            },
            {
                id: 'TUU-inven-precios-compra-con-complementos'
            }
        ]
    };

    public itemsFAQ = [
        {
            title: '¿Mi stock de productos disminuye cada vez que vendo?',
            text: '¡Sí, y es automático! Si tus productos se encuentran asignados a un stock en particular y están incluidos en una venta, se descuentan de inmediato del total disponible, sin necesidad de que hagas el trámite manualmente.'
        }, {
            title: '¿El servicio de inventario tiene costo adicional?',
            text: 'No, el servicio de inventario está incluido dentro de tu plan.'
        }, {
            title: '¿Cuántos inventarios puedo administrar con TUU?',
            text: '¡Los que tú quieras! Con TUU puedes agregar tantos inventarios como catálogos desees. Es decir, si cuentas con 3 catálogos independientes, al mismo tiempo tendrás 3 inventarios, y así sucesivamente.'
        }
    ];

    public dataSupport = [
        {
            id: 't-inventario-capacitacion',
            url: 'https://help.haulmer.com/meetings'
        }, {
            id: 't-inventario-at-cliente',
            url: '/contactar-atencion-cliente'
        }
    ];

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any,
        private seoService: SEOService
    ) { }

    ngOnInit(): void {
        this._setMeta();
        this.seoService.updateCanonicalUrl('inventario/');

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('Con TUU mantén un control de inventario actualizado luego de cada venta.');
        this._meta.setTag('description', 'Al utilizar TUU en cada una de tus ventas, tu inventario se actualizará de manera automática y en tiempo real. Con TUU puedes además recibir pagos con tarjetas y emitir boletas.');
        this._meta.setTag('keywords', 'Control de inventario, gestión de inventario, sistema de inventario, control de stock, punto de venta, sistema de venta.');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-inventory.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Con TUU mantén un control de inventario actualizado luego de cada venta.');
    }

}
