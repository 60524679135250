import { Component, Input } from '@angular/core';
import { ButtonTraceService } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-section-send-email',
    templateUrl: './section-send-email.component.html',
    styleUrls: ['./section-send-email.component.scss']
})
export class SectionSendEmailComponent {
    @Input() public loadingImages: boolean;

    constructor(public buttonTraceService: ButtonTraceService) {}

}
